// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'この名前のラベルは既に存在します',
        invitationNotExist: '招待が存在しません。',
        wpApiClientUnreachable: 'このサーバまたはサイトは現在オフラインのようです。サーバまたはサイトにアクセスできるかどうか確認し、再度お試しください。サーバまたはサイトが正常に動作しており、WP Guardian との接続に問題がないようであれば、サポートまでご連絡ください。',
    },
    termsOfUseLink: 'WP Guardian の利用規約',
    NotFoundPage: {
        title: 'ページが見つかりませんでした',
        description: "申し訳ありません。リクエストされたページが見つかりませんでした。正しくないリンクをクリックしたか、ページが削除された可能性があります。",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'ライセンスのアクティブ化のキャンセルを確定する',
            description: '{product} ライセンスのアクティブ化をキャンセルしようとしています。アクティベーションリンクを再びフォローして、後日いつでもこのライセンスをアクティブ化できます。別のアカウント用に購入したライセンスの場合、そのアカウントにログインしてアクティベーションリンクをフォローしてください。',
            cancelButton: 'キャンセル',
            continueButton: 'アクティブ化を続行',
        },
        SecurityDashboardActivateLicense: {
            title: 'WP Guardian ライセンスのアクティブ化を確定する',
            description: 'アカウント <mailto>{userEmail}</mailto> 用の <b>{product}</b> ライセンスのアクティブ化を確認します。このライセンスは 1 つのアカウントに対してのみアクティブ化できます。',
            fixableErrorDescription: 'WP Guardian ライセンスのアクティブ化中に問題が発生しました。やり直すか、サポートリクエストを送信してください。',
            nonFixableErrorDescription: 'WP Guardian ライセンスのアクティブ化中に問題が発生しました。サポートリクエストを送信してください。',
            loaderText: 'WP Guardian アカウントを構成中です',
            redirectText: 'WP Guardian にリダイレクトしています',
            confirmButton: '確認',
            cancelButton: 'キャンセル',
            retryButton: '再試行',
            gotItButton: '了解しました',
            errors: {
                linkError: 'アクティベーションリンクは無効です。',
                unknownError: '内部アクティベーションサービスエラー。',
            },
        },
    },
    Filters: {
        all: 'すべて',
    },
    FiltersForm: {
        title: 'フィルタ',
        clear: 'クリア',
        apply: 'フィルタの適用',
    },
    Invitation: {
        activationFailure: '招待リンクのアクティブ化に失敗しました',
    },
    UnderConstructionPopover: {
        title: '工事中',
        description: "申し訳ありません。まだ作業中です！",
    },
    RiskRankTitle: {
        title: 'リスク',
        description: 'リスクランクとは、脆弱性による影響の集約的な評価です。CVSS 評価、EPSS 評価、Patchstack Patch Priority などの要因に基づいて決まります。',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'この脆弱性は既に活発に悪用されています。早急に対応をとることを強くお勧めします。',
        highRisk: 'これは高リスクの脆弱性で、早急な対応が必要です',
        mediumRisk: 'これは中程度のリスクの脆弱性です。緊急対応する必要はありませんが、いずれ対応する必要があります。',
        lowRisk: 'これは低リスクの脆弱性で、無視しても問題ありません',
        cvss: 'CVSS: {score}',
        emptyCvss: 'なし',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Protection disabled',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: '無視',
        },
        ignoredDueToLowRisk: {
            title: '自動的に無視',
        },
        doNotIgnoreAutomatically: {
            title: '自動的に無視しない',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Unlimited',
    },
    taskTypes: {
        scanForNewSites: '新しい WordPress サイトを検出中',
        refreshInstallations: '更新中',
        detachInstallations: 'デタッチ中',
        updateInstallation: 'アップデート中',
        massUpdateInstallations: 'アップデート中',
        applyVirtualPatches: 'Enable vulnerability protection',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'アップデートの設定を保存中',
        clearInstallationCache: 'インストールキャッシュをクリア中',
        checkVulnerability: '脆弱性をチェック中',
        mitigateVulnerabilities: '脆弱性を緩和中',
        setDailyTaskStartTime: 'セキュリティチェックのスケジュールを変更',
    },
    installationComponents: {
        core: 'コア',
        plugin: 'プラグイン',
        theme: 'テーマ',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {ラベルが割り当てられました} other {ラベルが割り当てられました}}',
        failed: '{count, select, 1 {ラベルの割り当てに失敗しました} other {ラベルの割り当てに失敗しました}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {ラベルが割当解除されました} other {ラベルが割当解除されました}}',
        failed: '{count, select, 1 {ラベルの割当解除に失敗しました} other {ラベルの割当解除に失敗しました}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {ラベルが割り当てられました} other {ラベルが割り当てられました}}',
        failed: '{count, select, 1 {ラベルの割り当てに失敗しました} other {ラベルの割り当てに失敗しました}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {ラベルが割当解除されました} other {ラベルが割当解除されました}}',
        failed: '{count, select, 1 {ラベルの割当解除に失敗しました} other {ラベルの割当解除に失敗しました}}',
    },
    useCreateLabelMutation: {
        success: 'ラベルが作成されました',
        failed: 'ラベルの作成に失敗しました',
    },
    useEditLabelMutation: {
        success: 'ラベルが変更されました',
        failed: 'ラベルの変更に失敗しました',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {ラベルが削除されました} other {ラベルが削除されました}}',
        failed: '{count, select, 1 {ラベルの削除に失敗しました} other {ラベルの削除に失敗しました}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: '通知設定が更新されました。',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'サーバ <b>{selectedServerTitle}</b> でサイトデータの更新をスケジュールできません。',
        notifySeveralServersFail: '{selectedServersCount, plural, other {<b>#</b> 件のサーバでサイトデータの更新をスケジュールできません。}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'サーバ <b>{selectedServerTitle}</b> で新しい WordPress サイトの検出をスケジュールできません。',
        notifySeveralServersFail: '{selectedServersCount, plural, other {<b>#</b> 件のサーバで新しい WordPress サイトの検出をスケジュールできません。}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: '<b>{selectedServerTitle}</b> サーバは接続解除されました。',
        notifySeveralServers: '{selectedServersCount, plural, other {<b>#</b> 件のサーバが接続解除されました.}}',
        notifySingleServerFail: '<b>{selectedServerTitle}</b> サーバは接続解除されませんでした。',
        notifySeveralServersFail: '{selectedServersCount, plural, other {<b>#</b> 件のサーバが接続解除されませんでした。}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'サーバ <b>{selectedServerTitle}</b> でサイトのアップデートをスケジュールできません。',
        notifySeveralServersFail: '{selectedServersCount, plural, other {<b>#</b> 件のサーバでサイトのアップデートをスケジュールできません。}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'グローバル',
            server: 'サーバ',
        },
        coreLabels: {
            disabled: 'アップデートを自動的にインストールしない',
            major: 'すべてのアップデートを自動的にインストールする',
            minor: 'マイナー（セキュリティ）アップデートを自動的にインストールする',
            notSet: 'サイトレベルで定義済み',
            minorDescription: '例: 4.7.1 はインストールし、4.8 はしない',
            doNotTouch: '変更しない',
        },
        pluginsLabels: {
            disabled: 'サイト管理者が自動アップデート対象のプラグインを決定する',
            disabledDescription: 'WordPress はサイト管理者が各プラグインの自動アップデートを個別に有効化することを許可する',
            major: 'すべてのプラグインアップデートを自動的にインストールする',
            majorDescription: 'プラグインごとの自動アップデート設定を無視します',
            minor: 'セキュリティアップデートのみを自動的にインストールする',
            minorDescription: 'プラグインごとの自動アップデート設定を使用しますが、既知の脆弱性を修正するアップデートについては自動インストールします',
            notSet: 'サイトレベルで定義済み',
            doNotTouch: '変更しない',
        },
        themesLabels: {
            disabled: 'サイト管理者が自動アップデート対象のテーマを決定する',
            disabledDescription: 'WordPress はサイト管理者が各テーマの自動アップデートを個別に有効化することを許可する',
            major: 'すべてのテーマアップデートを自動的にインストールする',
            majorDescription: 'テーマごとの自動アップデート設定を無視します',
            minor: 'セキュリティアップデートのみを自動的にインストールする',
            minorDescription: 'テーマごとの自動アップデート設定を使用しますが、既知の脆弱性を修正するアップデートは自動インストールします',
            notSet: 'サイトレベルで定義済み',
            doNotTouch: '変更しない',
        },
        safeUpdatesLabels: {
            notSet: 'サイトレベルで定義済み',
            enabled: 'セーフアップデートを有効にする',
            disabled: 'セーフアップデートを無効にする',
            doNotTouch: '変更しない',
        },
        safeUpdates: {
            title: 'セーフアップデート',
            description: 'サイトにアップデートを適用する前にバックアップします。アップデートによってサイトが破損した場合、バックアップが自動的に復元されます',
            checkbox: 'セーフアップデートを有効にする',
        },
        labels: {
            core: 'WordPress コア',
            plugins: 'プラグイン',
            themes: 'テーマ',
        },
        doNotTouch: '変更しない',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'アップデート設定は、カスタマイズされたアップデート設定のないすべての新規追加サーバと既存のサーバに適用されます',
        checkboxDescription: 'カスタマイズされたアップデート設定のあるすべてのサーバとサイトにも設定を適用します',
    },
    DailyTaskTimeSection: {
        typeLabel: 'セキュリティチェックのスケジュール',
        typeOptions: {
            doNotTouch: '変更しない',
            unset: 'サーバによる定義',
            custom: 'カスタム時間',
        },
        timeLabel: '時間（24 時間）',
        timezoneDescription: 'タイムゾーン: サーバのデフォルトのタイムゾーン',
    },
    SettingsDrawer: {
        title: '設定',
        subTitle: '接続されたすべてのサーバとサイトの設定',
        loadFailed: '設定の読み込みに失敗しました。',
        tabs: {
            updates: 'アップデート',
            dailyTaskTime: 'スケジュール',
            vulnerabilities: '脆弱性',
        },
        DailyTaskTime: {
            description: 'WP Guardian は、24 時間ごとに新しい脆弱性や利用可能なアップデートがないか全サーバをチェックします。これはパフォーマンスに負荷を与える作業であるため、デフォルトですべてのサーバに対して定刻に実行されるようにスケジュールを設定することができます。',
        },
        successToast: '設定が保存されました。',
        buttonSave: '保存',
    },
    Operations: {
        openTaskManagerLinkText: 'さらに詳しく',
        operationsFailed: '{count, plural, other {# 件のオペレーションが失敗しました}}',
        fallback: {
            success: 'オペレーションが実行されました',
            failed: 'オペレーションの実行に失敗しました',
        },
        servers: {
            demo: {
                success: 'デモサーバでオペレーションが実行されました。',
                failed: 'デモサーバでのオペレーションに失敗しました。',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Failed to enable vulnerability protection',
            },
            update: {
                success: '{count, plural, other {# 件のサーバ上のインストールがアップデートされました}}',
                failed: '{count, plural, other {# 件のサーバ上のインストールのアップデートに失敗しました}}',
            },
            refresh: {
                success: 'サイトデータの更新が終了しました',
                failed: 'サイトデータの更新が失敗しました',
            },
            findNewSites: {
                success: '{count, plural, other {# 件のサーバ上の新しい WordPress インストールの検出が終了しました}}',
                failed: '{count, plural, other {# 件のサーバ上の新しい WordPress インストールの検出に失敗しました}}',
            },
            configureUpdates: {
                success: 'アップデート設定が構成されました',
                failed: 'アップデート設定の構成に失敗しました',
            },
            disconnect: {
                success: '{count, plural, other {# 件のサーバが接続解除されました}}',
                failed: '{count, plural, other {# 件のサーバの接続解除に失敗しました}}',
            },
        },
        installations: {
            demo: {
                success: 'デモサーバでのオペレーションが成功しました。',
                failed: 'デモサーバでのオペレーションに失敗しました。',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: '選択されたすべての WordPress インストールがアップデートされました',
                failed: '選択された WordPress インストールのアップデートに失敗しました。',
            },
            configureUpdates: {
                success: 'アップデート設定が変更されました',
                failed: 'アップデート設定の変更に失敗しました',
            },
            detach: {
                success: 'WordPress インストールが WP Guardian からデタッチされました。今後のサーバスキャンでは無視されます。このインストールを WP Guardian で認識させるには、インストールディレクトリから .wp-toolkit-ignore ファイルを削除します',
                failed: 'WordPress インストールのデタッチに失敗しました',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'アップデートのインストールにより、脆弱性が修正されました',
                failed: '{count, plural, other {# 件のインストールで脆弱性の修正に失敗しました}}',
            },
            mitigationDeactivateAsset: {
                success: 'プラグインの非アクティブ化により、脆弱性が緩和されました',
                failed: '{count, plural, other {# 件のインストールでプラグインの非アクティブ化による脆弱性の緩和に失敗しました}}',
            },
            mitigationActivateAsset: {
                success: '脆弱性の再アクティブ化により、プラグインが非アクティブ化されました',
                failed: '{count, plural, other {# 件のインストールで、以前に脆弱性があったプラグインの再アクティブ化に失敗しました}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'アップデートのインストールにより、脆弱性が修正されました',
                failed: '{count, plural, other {# 件の脆弱性の修正に失敗しました}}',
            },
            mitigationDeactivateAsset: {
                success: 'プラグインの非アクティブ化により、脆弱性が緩和されました',
                failed: '{count, plural, other {プラグインの非アクティブ化による # 件の脆弱性の緩和に失敗しました}}',
            },
            mitigationActivateAsset: {
                success: '脆弱性の再アクティブ化により、プラグインが非アクティブ化されました',
                failed: '{count, plural, other {# 件の脆弱性の影響を受けるプラグインの再アクティブ化に失敗しました}}',
            },
            ignoreVulnerabilities: {
                success: '脆弱性は無視されました',
                failed: '{count, plural, other {# 件の脆弱性の無視に失敗しました}}',
            },
            cancelIgnoreVulnerabilities: {
                success: '脆弱性はもう無視されていません',
                failed: '{count, plural, other {# 件の脆弱性の無視を解除することに失敗しました}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'クイックスタート',
        QuickStartWizard: {
            startStepName: '開始',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: '通知',
            securityCheckScheduleStepName: 'セキュリティチェック',
            updateStepName: 'アップデート',
        },
        QuickStartStep: {
            pagination: '{current} / {total}',
            ok: 'OK',
            next: '次へ',
            back: '戻る',
        },
        StartStep: {
            title: 'WP Guardian へようこそ',
            text: 'WordPress サイトのセキュリティを維持するために必要なすべてを設定しましょう',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'セキュリティチェックの実行時刻',
            checkingTimeText: 'WP Guardian は、24 時間ごとに変更がないか全サーバをチェックします。これはパフォーマンスに負荷を与える作業で、サーバのリソースを利用して時間をかけて行われます。',
            vulnerabilitiesUpdateTitle: '脆弱性データベースのアップデート',
            vulnerabilitiesUpdateText: '脆弱性データベースは 1 時間ごとに自動でアップデートされます。この作業はサーバやサイトのパフォーマンスに影響を与えません。',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: '仕組み',
            howItWorksText: 'プラグインは WordPress 内部にインストールされ、ウェブアプリケーションファイアウォールとして機能します。WordPress のファイルやサイトのコンテンツを変更することはありませんが、サイト上に存在する脆弱性のみを対象にファイアウォールルールを適用します。',
            poweredBy: 'powered by {providerLogo}',
        },
        UpdatesStep: {
            next: "次へ",
            updateSettings: '設定を更新',
            updateSettingsText: '後から追加されたサイトも含め、WP Guardian に接続されているすべてのサイトに自動アップデートを強制することができます。後でサイトごと、またはサーバごとに設定可能です。',
            backup: 'アップデート前にバックアップ',
            backupText: 'セーフアップデートを有効にすると、WP Guardian はアップデートの前にサイトをバックアップします。何か問題が発生した場合には、サイトがバックアップから自動的に復元されます。',
            safeUpdates: 'セーフアップデート',
            customizedSettings: 'カスタマイズされた設定',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'タスクログ',
        Toolbar: {
            buttons: {
                groupTitle: 'ボタン',
                allTasks: 'すべてのタスク',
                failed: '失敗',
            },
            filters: {
                groupTitle: 'フィルタ',
                typeUpdate: 'アップデートをインストール',
                refresh: 'データを更新',
                typeFindNewSites: '新しいサイトを検出',
                typeConfigureUpdates: 'アップデートを構成',
                typeFixVulnerabilitiesViaUpdate: 'アップデートにより脆弱性を修正',
                mitigationDeactivateAsset: 'プラグインの非アクティブ化によって脆弱性を緩和',
                mitigationActivateAsset: '脆弱性が原因で非アクティブ化されたプラグインを再アクティブ化',
                ignoreVulnerabilities: '脆弱性を無視',
                cancelIgnoreVulnerabilities: '脆弱性の無視を解除',
                setDailyTaskTime: 'セキュリティチェックのスケジュールを変更',
            },
            search: {
                title: '検索',
                placeholder: '検索',
            },
        },
        TasksList: {
            columns: {
                task: 'タスク',
                status: 'ステータス',
                started: '開始',
                finished: '終了',
            },
            taskTypes: {
                fallBack: "コード ''{type}'' のオペレーション",
                demo: 'デモサーバでのオペレーション',
                update: 'アップデートをインストール',
                refresh: 'データを更新',
                detach: 'デタッチ',
                findNewSites: '新しいサイトを検出',
                configureUpdates: 'アップデートを構成',
                setDailyTaskTime: 'セキュリティチェックのスケジュールを変更',
                protect: 'Enable vulnerability protection',
                unprotect: 'Disable vulnerability protection',
                disconnect: '接続解除',
                fixVulnerabilitiesViaUpdate: 'アップデートにより脆弱性を修正',
                changeInstallationPluginStatus: 'プラグインの状態を変更',
                mitigationDeactivateAsset: 'プラグインの非アクティブ化によって脆弱性を緩和',
                mitigationActivateAsset: '脆弱性が原因で非アクティブ化されたプラグインを再アクティブ化',
                ignoreVulnerabilities: '脆弱性を無視',
                cancelIgnoreVulnerabilities: '脆弱性の無視を解除',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, other {# 件のサーバ}}',
                sites: '{count, plural, other {# 件のサイト}}',
                vulnerabilities: '{count, plural, other {# 件の脆弱性}}',
            },
            statuses: {
                success: '成功',
                inProgress: '処理中',
                failed: '失敗',
                errors: 'エラー',
                unknown: '不明',
            },
            emptyList: {
                title: 'タスクなし',
                description: '実行されたタスクはありません。',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: '保護が無効化されているサイト',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {このサイトで保護を再び有効にする} other {これらのサイトで保護を再び有効にする}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "ライセンスがありません",
        trialDaysLeft: '{days, plural, other {評価期間 (残り# 日)}}',
        trialUntil: 'トライアル',
    },
    PromoCodeForm: {
        promoCode: 'プロモコード',
        apply: '適用',
        activationFailure: 'プロモコードのアクティブ化に失敗しました',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: '適用',
            trialTitle: '14 日間のトライアル',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial: 'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'ライセンスを選択',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: '評価期間が開始されました。',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'See Details',
                },
                trialExpired: {
                    title: '評価期間が終了しました',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Your license has been terminated',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'ライセンスなし',
            expired: '(期限切れ)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'フィードバックする',
            },
        },
        tabs: {
            servers: 'サーバ',
            installations: 'WordPress サイト',
            vulnerabilities: '脆弱性',
        },
        Footer: {
            Links: {
                followUs: '弊社をフォロー：',
                company: '会社情報',
                aboutWebpros: 'WebPros について',
                cookiehub: 'Cookie 設定',
                privacyPolicy: 'プライバシーポリシー',
                knowledgeBase: 'ナレッジベース',
                documentation: 'ドキュメント',
                helpCenter: 'ヘルプセンター',
                contactUs: 'お問い合わせ',
                legal: '法的情報',
            },
            copyright: '© {year} WebPros International GmbH. All rights reserved. WP Guardian および WP Guardian ロゴは、WebPros International GmbH の商標です。',
        },
        Header: {
            UpdateSettingsCard: {
                title: '自動アップデート設定',
            },
            CardLoader: {
                loading: 'ロード中...',
            },
            InstallationsCard: {
                title: 'WordPress サイト',
                vulnerable: '{count}: 脆弱性あり',
                outdated: '{count}: 古いバージョン',
                protected: '{count}: 保護中',
                safe: '{count}: OK',
                notOperable: '{count}: 動作不能',
                poweredBy: '<whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: '脆弱性',
                databaseUpdate: 'データベースアップデート',
                momentAgo: '少し前',
                noData: 'データなし',
                lastUpdateMinutes: '{minutes, plural, other {# 分前}}',
                lastUpdateHours: '{hours, plural, other {# 時間前}}',
                lastUpdateDays: '{days, plural, other {# 日前}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: '最も高い CVSS スコア',
                needsProtection: '{count, plural, other {#: 保護が必要}}',
            },
            QuickStartCard: {
                title: 'クイックスタート',
                ctaBlockTitle: 'セキュリティを設定',
                ctaBlockText: '数クリックで完了します',
            },
        },
        UserMenuAddon: {
            quickStart: 'Quick start',
            license: 'ライセンスの詳細',
        },
    },
    Vulnerabilities: {
        updateByProtect: '現時点では、この脆弱性を修正するアップデートはありません。',
        columns: {
            position: '場所',
        },
        Onboarding: {
            title: '脆弱性は見つかりませんでした',
            description: 'サーバを接続して WordPress サイトを検出し、脆弱性とアップデートがないかチェックします。',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: '緩和済み',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'プラグインの非アクティブ化により解決済み',
                tooltip: {
                    'singleSiteMitigatedByOther': 'この脆弱性の影響を受けるウェブサイトは、現在 WP Guardian 以外の手段でセキュリティが維持されています。',
                    'mitigatedByOther': 'この脆弱性の影響を受けるウェブサイトは、現在 WP Guardian 以外の手段でセキュリティが維持されています',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'ソース:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'このレコードには著作権の対象となる情報が含まれています。',
            wordfenceCopyright: '著作権 2012-2024 Defiant Inc. ライセンス：Defiant は、このソフトウェア脆弱性情報を複製し、派生物を作成し、公に展示し、公に実行し、サブライセンスし、配布するための、永続的、世界的、非独占的、無償、ロイヤリティフリー、取消不能の著作権ライセンスを付与します。このような目的のために作成するソフトウェア脆弱性情報のコピーは、この脆弱性レコードにハイパーリンクを含めること、およびこのようなコピーすべてに Defiant の著作権表示とこのライセンスを複製することを条件に許可されます。',
            readMore: 'さらに詳しく',
        },
        Toolbar: {
            groupFilterMenu: 'フィルタ',
            groupSearch: '検索',
            manage: '管理',
            patch: 'Protection',
            searchPlaceholder: '脆弱性を検索...',
            UpdateButton: {
                update: 'アップデートをインストール',
                cancel: 'キャンセル',
                noSelection: 'アップデートによって対処する脆弱性を 1 つ以上選択してください。',
                updateConfirmation: '{totalVulnerabilities, plural, other {# 件の脆弱性に対応するために複数のウェブサイトをアップデートしようとしています}}。アップデートのプロセスは、開始後に中断できません。',
                updateConfirmationPluginTitle: '複数のプラグインが無効化されました',
                updateConfirmationPluginCheckbox: 'セキュリティポリシーによって無効化されたプラグインを再アクティブ化',
                updateConfirmationPluginCheckboxHint: 'セキュリティポリシーによって無効化された脆弱性のあるプラグインは、脆弱性がアップデートによって修正されると自動的に再アクティブ化されます。',
                numberOfSites: 'アップデートの対象サイト数：',
                numberOfSitesUnmanaged: 'アップデートの対象サイト数（管理対象外サイトはスキップされます）：',
                sitesCalculating: '計算中...',
                sitesCalculatingError: 'カウントに失敗しました',
                affectedSites: '{count, plural, other {{count} 件}}',
                unmanagedLabel: '{count, plural, other {# 件が管理対象外}}',
                updateByProtect: '現時点では、この脆弱性を修正するアップデートはありません。',
                upgradeLicense: 'ライセンスをアップグレード',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: '無視',
                noSelection: '無視する脆弱性または無視を解除する脆弱性を 1 つ以上選択してください。',
            },
            PatchButton: {
                affectedSites: '{count, plural, other {{count} 件}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'キャンセル',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Enable Protection',
                providerLogo: '<whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: '計算中...',
                sitesCalculatingError: '影響を受けるサイト数の計算に失敗しました',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, other {# 件が管理対象外}}',
                update: 'Enable Protection',
                upgradeLicense: 'ライセンスをアップグレード',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: '保護が必要',
            filterStateIgnored: '無視',
            filterComponentCore: 'コア',
            filterComponentPlugin: 'プラグイン',
            filterComponentTheme: 'テーマ',
            everywhere: 'すべて',
        },
        UpdateConfirmation: {
            description: '{count, plural, other {# 件のウェブサイトにアップデートを適用しようとしています。}}開始すると、中止することはできません。',
        },
        Widgets: {
            disablePlugin: {
                title: 'プラグイン',
                installations: '{enabledCount, plural, other {# 件がアクティブ}}',
                installationsDeactivated: '{count, plural, other {# 件のウェブサイトで非アクティブ}}',
                confirmationDeactivation: '{count, plural, other {# 件のウェブサイトで脆弱性のあるプラグインを非アクティブ化しようとしています。}}このプロセスを開始すると停止することはできません。',
                confirmationReactivation: '{count, plural, other {# 件のウェブサイトでセキュリティ上の理由から非アクティブ化されていたプラグインを再アクティブ化しようとしています。このアクションによりウェブサイトが潜在的な危険にさらされる可能性があります。}} このプロセスを開始すると停止することはできません。',
                dropdownDeactivate: '非アクティブ化',
                buttonDeactivateWithCount: '{count} 件を非アクティブ化',
                buttonReactivateWithCount: '{count} 件を再アクティブ化',
                buttonDeactivateAll: 'すべて非アクティブ化',
                buttonReactivate: '再アクティブ化',
                tooltipAllPluginsDeactivatedByUser: 'WP Guardian が、サイト管理者によって手動で非アクティブ化されたプラグインを再アクティブ化することはありません。',
            },
            updates: {
                title: 'アップデートをインストール',
                installations: '{count, plural, other {# 件のサイト}}',
                waitingForUpdatesTitle: 'アップデートはありません',
                buttonName: 'すべてアップデート',
            },
            patches: {
                allProtected: '{count, plural, other {# 件のサイト}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'キャンセル',
                enableButton: '有効化',
                installations: '{count} / {total}',
                protectAllSites: '全サイトを保護',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: '保護されるサイト',
                titleAllProtected: 'すべてを保護済み',
            },
        },
        installations: '{count, plural, other {# 件のサイト}}',
        servers: '{count, plural, other {# 件のサーバ}}',
    },
    Onboarding: {
        title: '接続されたサーバはありません',
        description: 'サーバを接続して WordPress サイトを検出し、脆弱性とアップデートがないかチェックします。',
        demoServerWasAddedSuccessfully: 'デモサーバが追加されました。',
        connectServers: '<addServerButton>独自のサーバを接続</addServerButton> <delimiter>または</delimiter> <addDemoServerButton>デモサーバを使用</addDemoServerButton>',
        hint: 'デモサーバは WP Guardian チームより提供され、複数の WP サイトが含まれているため、自社のサーバを接続する前に製品の機能を簡単に試すことができます。',
        feedbackButton: 'フィードバックする',
        demoServerPopover: {
            description: 'デモサーバは読み取り専用モードです。アップデートのインストールのような侵襲的なオペレーションを行っても、デモサーバ上のウェブサイトの状態は変わりません。',
            connectBtn: '接続',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: '検索: {searchValue}',
        searchByServer: 'サーバ: {searchValue}',
        searchByVulnerability: '脆弱性: {searchValue}',
        showAll: 'すべて表示',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, other {現在、選択されたサーバで # 件のサイトが動作不能です}}。これらのサイトは破損などの理由で機能しないため、これらのサイトでは処理が実行されません。',
        state: {
            installingAgent: 'エージェントをインストール中',
            connectionError: '接続エラー',
            agentInstallationError: 'エージェントのインストールが完了していません',
            connectionBlocked: '接続がブロックされました',
            synchronizationInProgress: '同期を実行中',
            connectionErrorStatusMessage: 'サーバへの接続を正しく確立できません。',
            connectionErrorFixDescription: 'サーバ（{ipAddress}）でログと設定をチェックして、再チェックをお待ちください。',
            connectionBlockedStatusMessage: 'サーバへの接続がファイアウォールによってブロックされています',
            connectionBlockedFixDescription: '{ipAddress} サーバのポート 878 が開いていることを確認して、再チェックをお待ちください。',
            agentInstallationErrorMessage: 'エージェントのインストールが完了していません',
            agentInstallationErrorFixDescription: "WP Guardian エージェントから、5 分以内にインストールが完了したという応答がありませんでした。これは、インストールがまだ進行中であるか、失敗したことを意味します。サーバコンソールでインストールの進捗を確認するか、'/root/wp-agent-install.log' ファイルにあるインストールログをご確認ください。",
        },
        ConnectDrawer: {
            title: 'サーバを接続',
            done: '完了',
            tabs: {
                connectDroplet: 'Connect droplet',
                connectServer: 'サーバを接続',
            },
        },
        AddDroplet: {
            videoDialogTitle: 'Connecting DigitalOcean droplet to WP Guardian',
            docsLinkText: 'Need help with connecting droplets? Check out the documentation',
            goToDoButton: 'Go to DigitalOcean to connect the droplet',
            head1: '1. Copy the following snippet',
            head2: '2. Activate the snippet in droplet console',
            paragraph1singleDroplet: 'Press the button below to open the droplet console, paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
            paragraph1multipleDroplets: 'Press the button below, choose a droplet you\'d like to connect to WP Guardian. From the detail page, click the Access tab in the left menu, then launch the droplet console and paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
        },
        AddServer: {
            done: '完了',
            paragraph1: '接続したいすべてのサーバで、次の IP からの着信接続用にポート 878 を開いてください：34.254.37.129、52.51.23.204、52.213.169.7',
            paragraph2: '以下のスニペットをコピーしてサーバコンソールに貼り付け、インストールが完了するまでお待ちください。',
            errors: {
                failedToGenerateToken: 'インストールコマンドの生成に失敗しました。',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'コピー',
            copied: 'スニペットがコピーされました',
            refreshTooltip: '接続コマンドはこの間、無制限のサーバに対して有効です。さらに時間が必要な場合は、コマンドを更新してください',
        },
        SettingsDrawer: {
            title: '設定',
            subTitle: {
                single: 'サーバ: {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'アップデート',
                dailyTaskTime: 'スケジュール',
            },
            loadFailed: '設定の読み込みに失敗しました。',
            buttonSave: '保存',
            successToast: '設定が保存されました。',
            Updates: {
                customizedSection: {
                    description: 'アップデート設定は、カスタマイズされたアップデート設定のない、このサーバ上のすべての新規追加サイトと既存のサイトに適用されます',
                    checkboxDescription: 'カスタマイズされたアップデート設定のあるすべてのサイトにも設定を適用します',
                },
            },
            DailyTaskTime: {
                description: 'WP Guardian は、24 時間ごとに新しい脆弱性や利用可能なアップデートがないかチェックします。これはパフォーマンスに負荷を与える作業であるため、定刻に実行されるようにスケジュールを設定することができます',
                unsetOption: 'グローバル設定で定義済み',
            },
        },
        RemoveServersButton: {
            remove: '接続解除',
            noSelectedServers: '接続解除するサーバを少なくとも 1 つ選択してください。',
            removeDescription: 'サーバの接続解除後、WP Guardian エージェントは自動的に削除されません。WP Guardian エージェントを削除するには、アンインストーラスクリプトを使用してください（<a>手順</a>）。',
            removeSingleServerConfirmation: '{selectedServerTitle} を接続解除しますか？',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, other {<b>#</b> 件のサーバを接続解除しますか？}}',
        },
        ListActions: {
            remove: '接続解除',
            refresh: '更新',
            findNewSites: '新しいサイトを検出',
            settings: '設定',
            disablePatch: '保護を解除',
            update: 'すべてのサーバにアップデートを適用',
            manageLabels: 'ラベルを適用',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'キャンセル',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, other {# 件の WP サイト}}',
            notOperable: '{count}: 動作不能',
            vulnerable: '{count}: 脆弱性あり',
            vulnerabilities: '{count}: 脆弱性あり',
            outdated: '{count}: 古いバージョン',
            lastCheck: '最終チェック: {dateTime}',
            lastCheckToday: '最終チェック: 本日 {time}',
            unsupportedAgent: 'サポート対象外のエージェントバージョン',
            coreOutdated: '古いバージョン',
        },
        columns: {
            server: 'サーバ',
        },
        OutdatedAgentError: {
            message: 'サーバ上の WP Guardian エージェント（wp-agent）が古いバージョンです',
            agentVersion: '{version, select, _ {WP Guardian エージェントのバージョンが不明です。} other {WP Guardian エージェントはバージョン {version} です。}}',
            updateDescription: 'wp-agent パッケージをバージョン {wpAgentMinSupportedVersion} 以降にアップデートしてください。',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'アップデート設定',
            ipAddress: '<b>サーバ IP:</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'フィルタ',
            manage: '管理',
            remove: '接続解除',
            add: '接続',
            groupFilter: 'フィルタ',
            groupSearch: '検索',
            searchPlaceholder: 'サーバを検索...',
            noSelectedServers: '接続解除するサーバを少なくとも 1 つ選択してください。',
            FilterPanel: {
                filterAlerts: 'アラート',
                labelsPlaceholder: 'すべてのラベル',
            },
            RefreshButton: {
                buttonText: '更新',
                noSelectedServers: '更新するサーバを少なくとも 1 つ選択してください。',
            },
            FindNewSitesButton: {
                buttonText: '新しいサイトを検出',
                noSelectedServers: '新しい WordPress サイトを検出するサーバを少なくとも 1 つ選択してください。',
            },
            UpdateButton: {
                buttonText: 'アップデートをインストール',
                confirmButtonText: 'すべてアップデート',
                noSelectedServers: 'サーバを少なくとも 1 つ選択してください。このサーバ上のすべての WordPress サイトに、利用可能なすべてのアップデートがインストールされます。',
                updateSingleServerConfirmation: 'サーバ <b>{selectedServerTitle}</b> 上のすべての WordPress サイトに、利用可能なすべてのアップデートがインストールされます。',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, other {選択された <b>#</b> 件のサーバ上にあるすべての WordPress サイトに、利用可能なすべてのアップデートがインストールされます.}}',
            },
            ProtectButton: {
                buttonText: '保護',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'ラベルを適用',
            },
            SettingsButton: {
                buttonText: '設定',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: '接続',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: '低リスクの脆弱性は自動的に無視',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: '低リスクの脆弱性は自動的に無視',
            warningMessage: 'この設定をオフにすると、「自動的に無視」ラベルはすべて削除され、自動的に無視されたすべての脆弱性は再び完全に監視されるようになります',
        },
    },
    Installations: {
        yes: 'はい',
        no: 'いいえ',
        update: 'アップデートをインストール',
        refresh: '更新',
        detach: 'デタッチ',
        updateSettings: 'アップデート設定',
        sitesAreNotOperable: '{notOperable, plural, other {現在、選択されたサイトのうち # 件が動作不能です}}。これらのサイトは破損などの理由で機能しないため、これらのサイトでは処理が実行されません。',

        DetachInstallationsButton: {
            detach: 'デタッチ',
            detachSingleInstallationConfirmation: 'WP Guardian から <b>{selectedInstallationTitle}</b> をデタッチしますか？デタッチされたインストールは、以降のサーバスキャンで無視されるようになります。',
            detachSeveralInstallationsConfirmation: 'WP Guardian から <b>{selectedInstallationsCount}</b> 件のインストールをデタッチしますか？デタッチされたインストールは、以降のサーバスキャンで無視されるようになります。',
        },
        Onboarding: {
            title: 'WordPress サイトは検出されませんでした',
            description: 'サーバを接続して WordPress サイトを検出し、脆弱性や古いコンポーネントがないかチェックします。',
        },
        columns: {
            website: 'サイト',
            server: 'サーバ',
            synchronized: '接続中',
            lastCheck: '前回のチェック',
            protect: '保護',
            siteHealth: 'サイトヘルス',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'フィルターメニュー',
                filterAll: 'すべて',
                filterVulnerable: '脆弱性あり',
                filterOutdated: '古いバージョン',
                filterNotOperable: '動作不能',
                filterProtected: '保護中',
                filterUnmanaged: '管理対象外',
                filterNotProtected: '保護対象外',
                filterSafe: '問題なし',
            },
            labelsPlaceholder: 'すべてのラベル',
        },
        Toolbar: {
            groupFilterMenu: 'フィルタ',
            groupSearch: '検索',
            searchPlaceholder: 'ウェブサイトを検索...',
            groupActions: 'アクション',
            groupUpdate: 'アップデートをインストール',
            groupProtect: '保護',
            subGroupActions: 'アクション',
            buttons: {
                update: {
                    text: 'アップデートをインストール',
                    noSelection: '使用可能なすべてのアップデートをインストールするサイトを 1 つ以上選択してください。',
                },
                refresh: {
                    text: '更新',
                    noSelection: '更新するサイトを少なくとも 1 つ選択してください。',
                },
                protect: {
                    text: '保護',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'ラベルを適用',
                },
                settings: {
                    text: '設定',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: '<b>{selectedInstallationTitle}</b> をアップデートしますか？',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, other {<b>#</b> 件の WordPress サイトをアップデートしようとしています}}',
                confirmButton: 'アップデート',
                cancelButton: 'キャンセル',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: '保護',
                cancelButton: 'キャンセル',
            },
        },
        SiteHealthColumn: {
            outdated: '{count}: 古いバージョン',
            safe: 'OK',
            notOperable: '動作不能',
            unsupportedAgent: 'サポート対象外のエージェントバージョン',
            vulnerabilities: '{count}: 脆弱性あり',
            lastCheckAtTooltip: '最終チェック: {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: '設定が保存されました。',
            title: '設定を更新',
            subTitle: {
                single: 'ウェブサイト: {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: '{notOperableCount, plural, other {# 件の動作不能サイト}}および {unmanagedCount, plural, other {# 件の管理対象外サイト}}がスキップされます.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, other {# 件の管理対象外サイトはスキップされます} }.',
            },
            buttonSave: '保存',
            confirmationPopover: {
                confirmBtn: '保存',
                description: {
                    single: 'WordPress サイト {installationName} のアップデート設定を変更しようとしています。続行しますか？',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'アップデート',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Enable Vulnerability Protection',
        },
        InstallationSummary: {
            updateAll: 'すべてアップデート',
            updateSettings: '設定を更新',
            upToDate: '最新',
            AssetSummary: {
                plugin: {
                    title: 'プラグイン[{count}]',
                    update: 'プラグインのアップデートをインストール',
                },
                theme: {
                    title: 'テーマ: [{count}]',
                    update: 'テーマのアップデートをインストール',
                },
                safe: 'OK',
                vulnerable: '{count}: 脆弱性あり',
                outdated: '{count}: 古いバージョン',
                vulnerabilities: '{count}: 脆弱性あり',
                isOutdated: '古いバージョン',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: '{version} にアップデート',
                updateSettingsTitle: 'アップデート設定',
                vulnerabilities: '{count, plural, other {# 件の脆弱性}}',
                isOutdated: '古いバージョン',
            },
            unsupportedAgentError: 'サーバ上の wp-agent のバージョンが古くなっています。バージョン {wpAgentMinSupportedVersion} 以上である必要があります。',
            notOperableUnknownError: 'サイトの脆弱性スキャン中に不明なエラーが発生しました。',
            hostname: 'サーバのホスト名:',
            ipAddress: 'サーバ IP:',
            UpdateConfirmation: {
                title: 'アップデートを確認',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'キャンセル',
                continueButton: 'アップデート',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Vulnerability protection by <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: '脆弱性保護は無効',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: '保護を有効化',
            },
            ProtectInfoPopover: {
                label: '脆弱性はまだアクティブですか？',
                message: '脆弱性を無害化するには、特定の保護ルールが必要です。ある脆弱性に保護ルールが用意されていない場合、いくつかの理由が考えられます。',
                noPatch: {
                    title: '作業中',
                    description: '影響の大きい脆弱性用のルールは、通常、公開から数時間以内に利用可能になります。一方、影響の小さい脆弱性のルールは、作成に時間がかかる場合があります。',
                },
                unlikelyExploited: {
                    title: 'リスクが低い',
                    description: '脆弱性の中には、影響が最小限であったり、実用的な悪用方法がないものもあります。このような脆弱性はウェブサイトに大きな脅威ではないため、保護ルールは必要ありません。',
                },
                dbMatch: {
                    title: 'データベースに未登録',
                    description: 'Wordfence データベースにのみ存在する脆弱性や、Patchstack データベースの対応するエントリと一致しない脆弱性には、保護ルールが提供されません。',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'お知らせください',
            },
        },
        WebsiteDrawer: {
            title: 'WordPress サイトの詳細',
            titleUpdateInProgress: '{title} をアップデート中',
            subtitleUpdateInProgress: '選択されたアイテムをアップデート中',
            errors: {
                installationNotFound: "ID {id} のインストールの情報を読み込めませんでした",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'キャンセル',
                        updateButton: 'アップデート',
                        description: '{count, plural, other {<b>{website}</b> でアップデートにより # 件の脆弱性を修正しようとしています。}}アップデートプロセスを開始すると、中断することはできません。',
                        updateConfirmationPluginCheckbox: '{count, plural, other {セキュリティポリシーによって無効になったプラグインを再アクティブ化する}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, other {セキュリティポリシーによって無効になった脆弱性のあるプラグインは、アップデートにより脆弱性が修正されると自動的に再アクティブ化されます。}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'アクション',
                            buttons: {
                                update: 'アップデート',
                                processing: '処理中',
                            },
                        },
                        filters: {
                            title: 'フィルタ',
                            groupFiltersTitle: 'フィルタ',
                            search: {
                                title: '検索',
                                placeholder: '脆弱性を検索...',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: '保護が必要',
                                filterStateIgnored: '無視',
                                filterComponentHeader: '場所',
                            },
                        },
                    },
                    title: '脆弱性',
                    columns: {
                        component: '場所',
                    },
                    emptyList: {
                        title: 'すべてが安全です',
                        description: 'このウェブサイトでは既知の脆弱性が見つかりませんでした。',
                    },
                    waitingForUpdate: '利用できるアップデートはありません',
                    updateTo: '{availableVersion} にアップデート',
                    update: 'アップデート',
                    disablePlugin: 'プラグインを非アクティブ化',
                    enablePlugin: 'プラグインをアクティブ化',
                    updateByProtect: '現時点では、この脆弱性を修正するアップデートはありません。',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, other {<b>{website}</b> で # 件のアセットにアップデートを適用しようとしています。}}アップデートプロセスを開始すると、中断することはできません。',
                        cancelButton: 'キャンセル',
                        updateButton: 'アップデート',
                    },
                    title: '古いバージョン',
                    toolbar: {
                        actions: {
                            title: 'アクション',
                            buttons: {
                                update: 'アップデート',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'フィルタ',
                            groupSearchTitle: '検索',
                            search: {
                                title: '検索',
                                placeholder: '古いコンポーネントの検出...',
                            },
                        },
                    },
                    columns: {
                        name: '名前',
                        type: '場所',
                    },
                    emptyList: {
                        title: 'すべてが最新です',
                        description: 'このサイトには、WordPress コア、プラグイン、テーマの最新バージョンがインストールされています。',
                    },
                    updateTo: '{version} にアップデート',
                },
            },
        },
        Actions: {
            enableProtectButton: '保護',
            disableProtectButton: '保護を解除',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'ラベルを適用',
        },
        DisableProtectionButton: {
            confirmButton: '保護を解除',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: '通知',
        listEmptyTitle: '重要なイベントを見落とさないようにしましょう',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'すべてを既読にする',
        deleteNotification: 'この通知を削除',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: '新しいライセンスがアクティブ',
                licenseLimitReached: 'ウェブサイト数の上限に達しました',
                licenseExpired: 'ライセンスの期限切れ',
                licenseTerminated: 'ライセンスの終了',
                trialCreated: '評価期間の開始',
                trialExpired: '評価期間の終了',
                trialExpiredSoon: '評価期間は {expirationDate} に終了します',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: '新しい {licenseName} ライセンスキー #{licenseKey} がアクティブです。',
                licenseLimitReached: '{limit, plural, other {ご契約の WP Guardian ライセンスではサイトを # 件まで管理できますが、}} {total, plural, other {現在 # 件のサイトを接続しています。}}サイトをすべて管理するためには、ライセンスをアップグレードしてください。',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'WP Guardian の評価期間が開始されました。この期間中は、最大 {sitesLimit, plural, other {# 件のウェブサイト}}で WP Guardian のすべての機能を完全に利用できます。',
                trialExpired: "WP Guardian の評価期間が終了しました。ライセンスを選択して購入することで製品の使用を続けるか、上のフィードバックフォームに満足できなかった理由をご記入ください。",
                trialExpiredSoon: 'WP Guardian の評価期間は {expirationDate} に終了します。製品を使い続けるためには、ライセンスの購入をご検討ください。',
            },
            markAsRead: '既読にする',
            markAsUnread: '未読にする',
        },
        settings: '設定',
    },
    ConfirmNotificationsEmail: {
        title: '通知メールの確認',
        error: 'リンクは期限切れです',
        successMessage: '通知用のメールアドレスが変更されました。',
    },
    NotificationsSettingsDrawer: {
        title: '通知設定',
        Content: {
            sendSectionTitle: '通知の送信先',
            email: 'メールアドレス',
        },
    },
    NotificationSettingsEventSection: {
        title: '製品外に送信する通知',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'ライセンスに関する通知',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: '通知用のメールアドレスを変更',
        buttonSave: 'メールを送信',
        buttonCancel: 'キャンセル',
        buttonEdit: '編集',
        email: 'メールアドレス',
        description: '通知の受信に使用するメールアドレスを更新する方法についてメールをお送りします。',
        successMessage: '<b>{email}</b> 宛てにメールが送信されます。このメールの手順に従い、新しいメールアドレスを確認してください。',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: '脆弱性の検知',
            description: '接続されたサイトの WordPress 関連の新しい脆弱性をリアルタイムでアップデート',
        },
        secureSites: {
            title: 'WordPress サイトのセキュリティを維持',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: '自動アップデート',
            description: 'サーバとウェブサイトのための柔軟な自動アップデート設定',
        },
        safeUpdates: {
            title: 'セーフアップデート',
            description: 'アップデート前のバックアップにより、アップデートが失敗した場合の自動ロールバックが可能',
        },
        notifications: {
            title: 'ヘルスステータスと通知',
            description: 'WordPress サイトの状況を常に把握',
        },
    },
    LicensePage: {
        benefits: {
            title: 'WordPress サイトのセキュリティを確保する',
        },
        ExpirationLabel: {
            noLicense: "ライセンスがありません",
            expiredLicense: 'このライセンスは失効しました',
        },
        LicenseHeading: {
            welcome: 'WP Guardian へようこそ',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'ライセンスを選択',
            trial: 'ライセンスを選択',
            trialLicenseExpired: '評価期間は終了しました',
        },
        LicenseDescription: {
            licenseKey: 'ライセンスキー: #{number}',
            nextUpdate: 'ライセンスキー次回更新日: {date}',
            expirationDate: '有効期限: {date}',
            noLicense: '無料版の WP Guardian で提供されるのは、脆弱性情報と手動でのサイトアップデートのみです。WP Guardian のすべての機能にアクセスするには、ライセンスを購入してください.',
            trialJustStarted: '評価期間中は WP Guardian の全機能にアクセスできます。評価期間の終了後、お客様に最適なライセンスをご提案します。特定の制限のある無料版で WP Guardian のご利用を継続していただくこともできます。',
            trial: '{sites, plural, other {評価期間には期限があり、WordPress サイトを最大 # 件まで管理できます。}}WP Guardian の既存の機能と将来的に追加される機能をすべてご利用いただくには、ライセンスをご購入ください。',
            limit: '{sites, plural, other {このライセンスでは、最大 # 件の WordPress サイトを管理できます。}}',
            unlimited: 'お使いのライセンスでは、WordPress サイトを無制限に管理できます。{sites, plural, other {基本ライセンス料金には、# 件のサイトの管理が含まれています。}} この上限を超えると、サイトごとに従量課金されます。',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: '詳細を見る',
            nextBillingDate: '次回の課金日：{date}',
            subscriptionCancelled: 'Subscription is canceled.',
        },
        CurrentUsage: {
            youAreUsingNow: '現在の使用状況:',
            sitesTotal: '{sites, plural, other {合計 # 件のウェブサイト}}',
            allowedToManage: '{sites, plural, other {# 件のウェブサイトを管理可能}}',
            outOfLimit: '{sites, plural, other {# 件のウェブサイトがライセンスの上限を超過}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Unlimited',
                label: '1000 件超のサイト用',
                feature1: 'サイト 10,000 件まで',
                feature2: '10,000 件以上のサイトは従量課金',
                feature3: 'すべての機能を使用可能',
                pricePerSite: '{price, number, ::currency/USD} for each site above 10k',
            },
            standard: {
                title: 'Standard',
                label: '最大 1000 件のサイト用',
                feature1: '買い切り',
                feature2: 'すべての機能を使用可能',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'このプランを使用する',
            changePlan: 'プランを変更',
            chooseAnotherOption: '別のオプションを選択してプランをアップグレードする',
        },
        LicenseList: {
            columns: {
                license: 'ライセンス',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: '購入',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: '再アクティブ化',
            details: 'Details',
        },
        chooseLicenseNow: 'ライセンスを選択',
        licensesTitle: 'ニーズに適したライセンスを選択してください',
        faq: {
            title: 'よくある質問',
            question1: {
                question: '評価期間が終了するとどうなりますか？',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: 'ライセンスの上限に達したらどうなりますか？',
                answer: 'ライセンスの上限に達した後で WP Guardian に追加されたサイトには管理対象外というマークが付けられます。脆弱性に関する最新情報は提供いたしますが、アップデートは手動で行っていただく必要があります。他のすべての WP Guardian はご利用いただけません。',
            },
            question4: {
                question: '従量課金とはどのような仕組みですか？',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'ライセンスは <kaLink>Key Administrator</kaLink> または <manage2Link>Manage2</manage2Link> から入手するか、担当者にご依頼ください。',
            standard: {
                title: '{sites, plural, other {標準の # サイトライセンスをご希望です}}',
                licenseLimit: '{sites, plural, other {このライセンスにはサイトが # 件までしか含まれていません}}',
                feature1: '標準ライセンスには、特定の数のウェブサイトが含まれ、従量課金機能はありません。',
                feature2: 'ライセンスの上限に達した後で追加されたウェブサイトは、すべて「管理対象外」とされます。',
                feature3: 'ライセンスをアップグレードすることなく、管理対象外のウェブサイトを WP Guardian で管理することはできません。',
                feature4: '管理対象外のウェブサイトは、ライセンスの上限を引き上げた場合や現在のライセンスでウェブサイトに空きができた場合に、管理対象に切り替えられます。',
                feature5: '特定のウェブサイトが WP Guardian に接続されて 2 日以上経過すると、このウェブサイトはライセンスの使用数にカウントされます。',
                feature6: '破損など何らかの理由で使用できなくなったウェブサイトは、ライセンスの上限にカウントされません。',
            },
            unlimited: {
                title: '無制限ライセンスを選択しています',
                feature1: '無制限ライセンスは、基本料金で 10,000 件のウェブサイトが含まれます。',
                feature2: '最初の 10,000 件の後で追加されたウェブサイトは、すべて従量課金で請求されます。',
                feature3: '特定のウェブサイトが WP Guardian に接続されて 2 日以上経過すると、このウェブサイトはライセンスの使用数にカウントされます。',
                feature4: '破損など何らかの理由で使用できなくなったウェブサイトは、ライセンスの上限にカウントされません。',
            },
        },
        UpgradeButton: {
            upgrade: 'アップグレード',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'すべての機能を使用可能',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'ライセンスを購入',
            upgradeLicense: 'ライセンスをアップグレード',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'ホーム画面に戻る',
    },
    LabelsDrawer: {
        title: 'ラベルを管理',
        LabelsList: {
            create: 'ラベルを作成',
            edit: '編集',
            delete: '削除',
            noSelectedLabels: '削除するラベルを 1 つ以上選択してください。',
            columns: {
                title: 'ラベル',
            },
            search: {
                title: '検索',
                placeholder: 'ラベルを検索',
            },
            emptyList: {
                title: 'ラベルを作成',
                description: "サーバやサイトを区別するためのラベルがありません。見分けやすくしませんか？",
            },
            filteredEmptyList: {
                title: 'フィルタと一致するアイテムは見つかりませんでした',
                description: '探しているラベルテキストを変更してやり直す',
            },
        },
        DeleteLabelsPopover: {
            delete: '削除',
            confirmation: '{count, select, 1 {ラベルを削除すると、そのラベルが適用されていたすべてのオブジェクトからラベルが除去されます。} other {ラベルを削除すると、それらのラベルは適用されていたすべてのオブジェクトから除去されます。}}続行しますか？',
        },
        CreateLabelDrawer: {
            title: 'ラベルを作成',
        },
        EditLabelDrawer: {
            title: 'ラベルを編集',
            notFound: 'ラベルが見つかりません',
        },
        LabelForm: {
            save: '保存',
            cancel: 'キャンセル',
            fields: {
                title: {
                    label: 'ラベル名',
                    description: '最大 30 文字',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'ラベル',
        noSelection: 'リストから少なくとも 1 つのアイテムを選択して、ラベルを追加または削除してください。',
        buttons: {
            apply: '適用',
            create: '新規作成',
            manage: 'ラベルを管理',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'すべて',
            tooltip: 'セーフアップデートが有効です',
            components: {
                core: 'コア',
                themes: 'テーマ',
                plugins: 'プラグイン',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'セキュリティアップデート',
                major: 'すべてのアップデート',
                disabled: "インストールしない",
                notSet: 'サイトレベル',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, other {ライセンスに管理対象サイトが # 件含まれています}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, other {# 件の管理対象外サイトはスキップされます}}',
        operableNotManagedSitesMessage: '管理対象外のサイトはライセンスの上限を超過しているため WP Guardian に無視されます。これらのウェブサイトは一括アクションの実行時にスキップされます。 ',
        notOperableNotManagedSitesTitle: '{notOperableCount, plural, other {# 件の動作不能サイト}}および {unmanagedCount, plural, other {# 件の管理対象外サイト}}がスキップされます',
        notOperableNotManagedSitesMessage: '選択されたオペレーションは、動作不能であるかライセンスの上限を超えているため、これらのサイトに対して実行されません。このようなウェブサイトは、一括アクションの実行時にスキップされます。',
        managedSitesWillBePerformed: '選択した操作は、すべての管理対象サイトに対して無制限に実行されます。',
        chooseLicenseButton: 'ライセンスを選択',
        LicenseTerminatedDialog: {
            title: 'Your license is terminated',
            message: 'WP Guardian の高度な機能を使い続けたい場合は、ライセンスを取得してください。',
            chooseLicenseButton: 'ライセンスを選択',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'サイト定義のアップデート',
            autoupdate: '自動アップデート',
            manual: '手動アップデート',
            custom: 'カスタマイズされたアップデート',
        },
        unmanaged: {
            title: '管理対象外',
            tooltip: '管理対象外のサイトは、ライセンスの上限を超えるため、WP Guardian に無視されます。ライセンスの上限により管理対象外とマークされたサイトは、引き続き脆弱性に関する最新の情報を受信しますが、アップデートは手動でのみ可能です。このようなサイトは、ライセンスの上限が引き上げられた場合や、現在のライセンスに空きができた場合に、管理対象に切り替わります。',
        },
        menu: {
            remove: '削除',
            filter: 'このラベルでフィルタ',
        },
    },
    Cuttable: {
        showMore: '表示を増やす',
        showLess: '表示を減らす',
    },
};