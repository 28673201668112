// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'Метка с таким именем уже существует',
        invitationNotExist: 'Приглашения не существует.',
        wpApiClientUnreachable: 'Кажется, этот сервер или сайт сейчас отключен. Проверьте, доступен ли ваш сервер или сайт, и повторите попытку. Если этот сервер или сайт работает правильно и у него нет проблем с подключением к WP Guardian, обратитесь в службу поддержки.',
    },
    termsOfUseLink: 'Условия использования WP Guardian',
    NotFoundPage: {
        title: 'Страница не найдена',
        description: "Не удалось найти запрашиваемую страницу. Возможно, в ссылке допущена ошибка или страница была удалена.",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'Подтвердить отмену активации лицензии',
            description: 'Вы собираетесь отменить активацию лицензии {product}. Вы можете активировать эту лицензию в любое время позже, повторно перейдя по ссылке активации. Если лицензия приобретена для другой учетной записи, войдите в эту учетную запись и перейдите по ссылке активации.',
            cancelButton: 'Отмена',
            continueButton: 'Продолжить активацию',
        },
        SecurityDashboardActivateLicense: {
            title: 'Подтвердить активацию лицензии WP Guardian',
            description: 'Подтвердите активацию лицензии <b>{product}</b> для учетной записи <mailto>{userEmail}</mailto>. Лицензию можно активировать только для одной учетной записи.',
            fixableErrorDescription: 'Во время активации лицензии WP Guardian что-то пошло не так. Пожалуйста, попробуйте еще раз или отправьте запрос в службу поддержки.',
            nonFixableErrorDescription: 'Во время активации лицензии WP Guardian что-то пошло не так. Пожалуйста, отправьте запрос в службу поддержки.',
            loaderText: 'Ваша учетная запись WP Guardian настраивается',
            redirectText: 'Перенаправление на WP Guardian',
            confirmButton: 'Подтвердить',
            cancelButton: 'Отмена',
            retryButton: 'Повторить попытку',
            gotItButton: 'Понятно',
            errors: {
                linkError: 'Ссылка активации недействительна.',
                unknownError: 'Внутренняя ошибка службы активации.',
            },
        },
    },
    Filters: {
        all: 'Все',
    },
    FiltersForm: {
        title: 'Фильтр',
        clear: 'Очистить',
        apply: 'Применить фильтр',
    },
    Invitation: {
        activationFailure: 'Не удалось активировать ссылку приглашения',
    },
    UnderConstructionPopover: {
        title: 'В разработке',
        description: "Извините, эта функция еще не готова!",
    },
    RiskRankTitle: {
        title: 'Риск',
        description: 'Ранг риска — это агрегированная оценка воздействия уязвимости. Он рассчитывается на основе оценки CVSS, оценки EPSS, оценки Patchstack Patch Priority и других факторов.',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'Эта уязвимость уже активно используется. Рекомендуется немедленно принять меры.',
        highRisk: 'Это уязвимость с высоким уровнем риска. Ее следует устранить как можно скорее.',
        mediumRisk: 'Это уязвимость со средним уровнем риска. Срочное вмешательство не требуется, но со временем ее следует устранить.',
        lowRisk: 'Это уязвимость с низким уровнем риска, которую можно спокойно проигнорировать.',
        cvss: 'CVSS: {score}',
        emptyCvss: 'Нет данных',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Protection disabled',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: 'Игнорируется',
        },
        ignoredDueToLowRisk: {
            title: 'Автоматически проигнорировано',
        },
        doNotIgnoreAutomatically: {
            title: 'Не игнорировать автоматически',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Unlimited',
    },
    taskTypes: {
        scanForNewSites: 'Поиск новых сайтов WordPress',
        refreshInstallations: 'Обновление данных',
        detachInstallations: 'Отсоединение',
        updateInstallation: 'Обновление',
        massUpdateInstallations: 'Обновление',
        applyVirtualPatches: 'Enable vulnerability protection',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'Сохранение настроек обновлений',
        clearInstallationCache: 'Очистка кэша установки',
        checkVulnerability: 'Проверка на наличие уязвимостей',
        mitigateVulnerabilities: 'Частичное устранение уязвимостей',
        setDailyTaskStartTime: 'Изменение расписания проверок безопасности',
    },
    installationComponents: {
        core: 'Ядро',
        plugin: 'Плагин',
        theme: 'Тема',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {Метка назначена} other {Метки назначены}}',
        failed: '{count, select, 1 {Не удалось назначить метку} other {Не удалось назначить метки}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {Метка снята} other {Метки сняты}}',
        failed: '{count, select, 1 {Не удалось снять метку} other {Не удалось снять метки}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {Метка назначена} other {Метки назначены}}',
        failed: '{count, select, 1 {Не удалось назначить метку} other {Не удалось назначить метки}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {Метка снята} other {Метки сняты}}',
        failed: '{count, select, 1 {Не удалось снять метку} other {Не удалось снять метки}}',
    },
    useCreateLabelMutation: {
        success: 'Метка создана',
        failed: 'Ошибка создания метки',
    },
    useEditLabelMutation: {
        success: 'Метка изменена',
        failed: 'Не удалось изменить метку',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {Метка удалена} other {Метки удалены}}',
        failed: '{count, select, 1 {Не удалось удалить метку} other {Не удалось удалить метки}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: 'Настройки уведомлений обновлены.',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'Не удалось запланировать обновление данных сайтов на сервере <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Не удалось запланировать обновление данных сайтов на <b>#</b> сервере.} other {Не удалось запланировать обновление данных сайтов на <b>#</b> серверах.}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'Не удалось запланировать поиск новых сайтов WordPress на сервере <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Не удалось запланировать поиск новых сайтов WordPress на <b>#</b> сервере.} other {Не удалось запланировать поиск новых сайтов WordPress на <b>#</b> серверах.}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: 'Сервер <b>{selectedServerTitle}</b> отключен.',
        notifySeveralServers: '{selectedServersCount, plural, one {<b>#</b> сервер отключен.} few {<b>#</b> сервера отключены.} many {<b>#</b> серверов отключены.} other {<b>#</b> сервера отключены.}}',
        notifySingleServerFail: 'Сервер <b>{selectedServerTitle}</b> не был отключен.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {<b>#</b> сервер не был отключен.} few {<b>#</b> сервера не были отключены.} many {<b>#</b> серверов не были отключены.} other {<b>#</b> сервера не были отключены.}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'Не удалось запланировать обновление сайтов на сервере <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Не удалось запланировать обновление сайтов на <b>#</b> сервере.} other {Не удалось запланировать обновление сайтов на <b>#</b> серверах.}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'Глобально',
            server: 'Сервер',
        },
        coreLabels: {
            disabled: 'Не устанавливать обновления автоматически',
            major: 'Устанавливать все обновления автоматически',
            minor: 'Устанавливать промежуточные обновления безопасности автоматически',
            notSet: 'Определены на уровне сайта',
            minorDescription: 'Например, 4.7.1 установить, а 4.8 ― нет',
            doNotTouch: 'Не менять',
        },
        pluginsLabels: {
            disabled: 'Позволить администратору сайта решать, какие плагины должны обновляться автоматически',
            disabledDescription: 'WordPress позволяет администраторам сайта включать автоматическое обновление каждого плагина по отдельности',
            major: 'Устанавливать все обновления плагинов автоматически',
            majorDescription: 'Игнорировать индивидуальные настройки автоматических обновлений для каждого плагина',
            minor: 'Устанавливать автоматически только обновления безопасности',
            minorDescription: 'Использовать индивидуальные настройки автоматических обновлений плагинов, но устанавливать автоматически обновления, которые исправляют известные уязвимости',
            notSet: 'Определены на уровне сайта',
            doNotTouch: 'Не менять',
        },
        themesLabels: {
            disabled: 'Позволить администратору сайта решать, какие темы должны обновляться автоматически',
            disabledDescription: 'WordPress позволяет администраторам сайта включать автоматическое обновление каждой темы по отдельности',
            major: 'Устанавливать все обновления тем автоматически',
            majorDescription: 'Игнорировать индивидуальные настройки автоматических обновлений для каждой темы',
            minor: 'Устанавливать автоматически только обновления безопасности',
            minorDescription: 'Использовать индивидуальные настройки автоматических обновлений тем, но устанавливать автоматически обновления, которые исправляют известные уязвимости',
            notSet: 'Определены на уровне сайта',
            doNotTouch: 'Не менять',
        },
        safeUpdatesLabels: {
            notSet: 'Определены на уровне сайта',
            enabled: 'Включить безопасные обновления',
            disabled: 'Отключить безопасные обновления',
            doNotTouch: 'Не менять',
        },
        safeUpdates: {
            title: 'Безопасные обновления',
            description: 'Перед обновлением сделайте резервную копию сайта. Если после обновления работа сайта нарушится, резервная копия будет восстановлена автоматически',
            checkbox: 'Включить безопасные обновления',
        },
        labels: {
            core: 'Ядро WordPress',
            plugins: 'Плагины',
            themes: 'Темы',
        },
        doNotTouch: 'Не менять',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'Настройки обновлений будут применены ко всем новым и текущим серверам без персональных настроек обновления',
        checkboxDescription: 'Также применить настройки ко всем серверам и сайтам с персональными настройками обновлений',
    },
    DailyTaskTimeSection: {
        typeLabel: 'Расписание проверок безопасности',
        typeOptions: {
            doNotTouch: 'Не менять',
            unset: 'Определяется сервером',
            custom: 'Настроить время',
        },
        timeLabel: 'Время (24 ч.)',
        timezoneDescription: 'Часовой пояс: часовой пояс сервера по умолчанию',
    },
    SettingsDrawer: {
        title: 'Настройки',
        subTitle: 'Настройки всех связанных серверов и сайтов',
        loadFailed: 'Не удалось загрузить настройки.',
        tabs: {
            updates: 'Обновления',
            dailyTaskTime: 'Расписание',
            vulnerabilities: 'Уязвимости',
        },
        DailyTaskTime: {
            description: 'WP Guardian проверяет все серверы на наличие новых уязвимостей и доступных обновлений каждые 24 часа. Эта операция влияет на производительность, поэтому вы можете запланировать ее запуск в определенное время на всех серверах.',
        },
        successToast: 'Настройки сохранены.',
        buttonSave: 'Сохранить',
    },
    Operations: {
        openTaskManagerLinkText: 'Подробнее',
        operationsFailed: '{count, plural, one {У вас есть # невыполненная операция} few {У вас есть # невыполненных операции} many {У вас есть # невыполненных операций} other {У вас есть # невыполненных операций}}',
        fallback: {
            success: 'Операция выполнена',
            failed: 'Не удалось выполнить операцию',
        },
        servers: {
            demo: {
                success: 'Операция на демо-сервере выполнена.',
                failed: 'Не удалось выполнить операцию на демо-сервере.',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Failed to enable vulnerability protection',
            },
            update: {
                success: '{count, plural, one {Обновления на # сервере установлены} other {Обновления на # серверах установлены}}',
                failed: '{count, plural, one {Не удалось установить обновления на # сервере} other {Не удалось установить обновления на # серверах}}',
            },
            refresh: {
                success: 'Обновление данных сайтов завершено',
                failed: 'Не удалось обновить данные сайтов',
            },
            findNewSites: {
                success: '{count, plural, one {Поиск новых сайтов WordPress на # сервере завершен} other {Поиск новых сайтов WordPress на # серверах завершен}}',
                failed: '{count, plural, one {Не удалось выполнить поиск новых сайтов WordPress на # сервере} other {Не удалось выполнить поиск новых сайтов WordPress на # серверах}}',
            },
            configureUpdates: {
                success: 'Настройки обновлений сохранены',
                failed: 'Не удалось сохранить настройки автоматических обновлений',
            },
            disconnect: {
                success: '{count, plural, one {# сервер отключен} few {# сервера отключены} many {# серверов отключены} other {# сервера отключен}}',
                failed: '{count, plural, one {Не удалось отключить # сервер} few {Не удалось отключить # сервера} many {Не удалось отключить # серверов} other {Не удалось отключить # сервера}}',
            },
        },
        installations: {
            demo: {
                success: 'Операция на демо-сервере успешно выполнена.',
                failed: 'Не удалось выполнить операцию на демо-сервере.',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: 'Обновления установлены на все выбранные сайты WordPress',
                failed: 'Не удалось установить обновления на выбранных сайтах WordPress',
            },
            configureUpdates: {
                success: 'Настройки обновлений изменены',
                failed: 'Не удалось изменить настройки автоматических обновлений',
            },
            detach: {
                success: 'Сайт WordPress был отсоединен от WP Guardian. Он будет проигнорирован при последующем сканировании сервера. Чтобы сделать этот сайт видимым для WP Guardian, удалите файл .wp-toolkit-ignore из каталога сайта.',
                failed: 'Не удалось отсоединить сайт WordPress',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'Уязвимости были исправлены при установке обновлений',
                failed: '{count, plural, one {Не удалось исправить уязвимости на # установке} few {Не удалось исправить уязвимости на # установках} many {Не удалось исправить уязвимости на # установках} other {Не удалось исправить уязвимости на # установках}}',
            },
            mitigationDeactivateAsset: {
                success: 'Уязвимости были снижены при отключении плагина',
                failed: '{count, plural, one {Не удалось снизить уязвимости из-за деактивации плагина на # установке} few {Не удалось снизить уязвимости из-за деактивации плагина на # установках} many {Не удалось снизить уязвимости из-за деактивации плагина на # установках} other {Не удалось снизить уязвимости из-за деактивации плагина на # установках}}',
            },
            mitigationActivateAsset: {
                success: 'Плагины, отключенные из-за уязвимостей, вновь включены',
                failed: '{count, plural, one {Не удалось вновь включить ранее уязвимые плагины на # установке} few {Не удалось вновь включить ранее уязвимые плагины на # установках} many {Не удалось вновь включить ранее уязвимые плагины на # установках} other {Не удалось вновь включить ранее уязвимые плагины на # установках}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'Уязвимости были исправлены при установке обновлений',
                failed: '{count, plural, one {Не удалось исправить # уязвимость} few {Не удалось исправить # уязвимости} many {Не удалось исправить # уязвимостей} other {Не удалось исправить # уязвимость}}',
            },
            mitigationDeactivateAsset: {
                success: 'Уязвимости были снижены при отключении плагина',
                failed: 'Не удалось снизить {count, plural, one {# уязвимость} few {# уязвимости} many {# уязвимостей} other {# уязвимость}} отключением плагина',
            },
            mitigationActivateAsset: {
                success: 'Плагины, отключенные из-за уязвимостей, вновь включены',
                failed: 'Не удалось вновь включить {count, plural, one {# плагин с уязвимостью} few {# плагина с уязвимостью} many {# плагинов с уязвимостью} other {# плагина с уязвимостью}}',
            },
            ignoreVulnerabilities: {
                success: 'Уязвимости проигнорированы',
                failed: '{count, plural, one {Не удалось проигнорировать # уязвимость} few {Не удалось проигнорировать # уязвимости} many {Не удалось проигнорировать # уязвимостей} other {Не удалось проигнорировать # уязвимость}}',
            },
            cancelIgnoreVulnerabilities: {
                success: 'Уязвимости больше не игнорируются',
                failed: '{count, plural, one {Не удалось прекратить игнорировать # уязвимость} few {Не удалось прекратить игнорировать # уязвимости} many {Не удалось прекратить игнорировать # уязвимостей} other {Не удалось прекратить игнорировать # уязвимость}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'Быстрое начало',
        QuickStartWizard: {
            startStepName: 'Начало',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: 'Уведомления',
            securityCheckScheduleStepName: 'Проверка безопасности',
            updateStepName: 'Обновления',
        },
        QuickStartStep: {
            pagination: '{current} из {total}',
            ok: 'OK',
            next: 'Далее',
            back: 'Назад',
        },
        StartStep: {
            title: 'Добро пожаловать в WP Guardian',
            text: 'Настройте все необходимое для безопасности ваших сайтов WordPress',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'Время проверки безопасности',
            checkingTimeText: 'WP Guardian проверяет все серверы и сайты на наличие изменений каждые 24 часа. Эта операция влияет на производительность и требует времени и ресурсов сервера.',
            vulnerabilitiesUpdateTitle: 'Обновления базы данных уязвимостей',
            vulnerabilitiesUpdateText: 'База данных уязвимостей обновляется автоматически каждый час. Эта операция не влияет на производительность сервера и сайта.',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: 'Как это работает',
            howItWorksText: 'В WordPress устанавливается плагин, действующий как брандмауэр для веб-приложений. Он не изменяет файлы WordPress или содержимое сайта, но применяет правила брандмауэра только к имеющимся на сайте уязвимостям.',
            poweredBy: 'на базе {providerLogo}',
        },
        UpdatesStep: {
            next: "Далее",
            updateSettings: 'Настройки обновлений',
            updateSettingsText: 'Вы можете принудительно устанавливать автоматические обновления на всех сайтах, подключенных к WP Guardian, даже если они были добавлены позже. Затем вы сможете установить отдельные настройки для каждого сайта или сервера.',
            backup: 'Резервное копирование перед обновлением',
            backupText: 'При включении безопасных обновлений WP Guardian создает резервную копию сайта перед его обновлением. Если что-то пойдет не так, сайт будет восстановлен из резервной копии автоматически.',
            safeUpdates: 'Безопасные обновления',
            customizedSettings: 'Персональные настройки',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'Журнал задач',
        Toolbar: {
            buttons: {
                groupTitle: 'Кнопки',
                allTasks: 'Все задачи',
                failed: 'Невыполненные операции',
            },
            filters: {
                groupTitle: 'Фильтры',
                typeUpdate: 'Установить обновления',
                refresh: 'Обновить данные',
                typeFindNewSites: 'Поиск новых сайтов',
                typeConfigureUpdates: 'Настроить обновления',
                typeFixVulnerabilitiesViaUpdate: 'Устранить уязвимости через обновление',
                mitigationDeactivateAsset: 'Снизить уязвимости отключением плагинов',
                mitigationActivateAsset: 'Вновь включить плагины, отключенные из-за уязвимостей',
                ignoreVulnerabilities: 'Игнорировать уязвимости',
                cancelIgnoreVulnerabilities: 'Прекратить игнорировать уязвимости',
                setDailyTaskTime: 'Изменить расписания проверок безопасности',
            },
            search: {
                title: 'Поиск',
                placeholder: 'Поиск',
            },
        },
        TasksList: {
            columns: {
                task: 'Задача',
                status: 'Статус',
                started: 'Запущено',
                finished: 'Завершено',
            },
            taskTypes: {
                fallBack: "Операция с кодом ''{type}''",
                demo: 'Операция на демо-сервере',
                update: 'Установить обновления',
                refresh: 'Обновить данные',
                detach: 'Отсоединить',
                findNewSites: 'Поиск новых сайтов',
                configureUpdates: 'Настроить обновления',
                setDailyTaskTime: 'Изменить расписания проверок безопасности',
                protect: 'Enable vulnerability protection',
                unprotect: 'Disable vulnerability protection',
                disconnect: 'Отключить',
                fixVulnerabilitiesViaUpdate: 'Устранить уязвимости через обновление',
                changeInstallationPluginStatus: 'Изменить статус плагина',
                mitigationDeactivateAsset: 'Снизить уязвимости отключением плагинов',
                mitigationActivateAsset: 'Вновь включить плагины, отключенные из-за уязвимостей',
                ignoreVulnerabilities: 'Игнорировать уязвимости',
                cancelIgnoreVulnerabilities: 'Прекратить игнорировать уязвимости',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, one {# сервер} few {# сервера} many {# серверов} other {# сервера}}',
                sites: '{count, plural, one {# сайт} few {# сайта} many {# сайтов} other {# сайтов}}',
                vulnerabilities: '{count, plural, one {# уязвимость} few {# уязвимости} many {# уязвимостей} other {# уязвимости}}',
            },
            statuses: {
                success: 'Успешно',
                inProgress: 'В процессе',
                failed: 'Не выполнено',
                errors: 'Ошибки',
                unknown: 'Неизвестно',
            },
            emptyList: {
                title: 'Нет задач',
                description: 'Пока нет выполненных задач.',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: 'Сайты с отключенной защитой',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {Повторно включить защиту на этом сайте} other {Повторно включить защиту на этих сайтах}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "У вас нет лицензии",
        trialDaysLeft: '{days, plural, one {Пробный период (остался # день)} few {Пробный период (осталось # дня)} many {Пробный период (осталось # дней)} other {Пробный период (осталось # дня)}}',
        trialUntil: 'Пробная версия',
    },
    PromoCodeForm: {
        promoCode: 'Ваш промокод',
        apply: 'Применить',
        activationFailure: 'Не удалось активировать промокод',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: 'Применить',
            trialTitle: '14-дневная пробная версия',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial: 'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'Выберите лицензию',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: 'Ваш пробный период начался!',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'Посмотреть подробности',
                },
                trialExpired: {
                    title: 'Срок действия пробного периода истек',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Действие вашей лицензии прекращено',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'Нет лицензии',
            expired: '(просрочен)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'Оставить отзыв',
            },
        },
        tabs: {
            servers: 'Серверы',
            installations: 'Сайты WordPress',
            vulnerabilities: 'Уязвимости',
        },
        Footer: {
            Links: {
                followUs: 'Следите за нашими новостями:',
                company: 'Компания',
                aboutWebpros: 'О WebPros',
                cookiehub: 'Настройки файлов cookie',
                privacyPolicy: 'Политика конфиденциальности',
                knowledgeBase: 'База знаний',
                documentation: 'Документация',
                helpCenter: 'Центр помощи',
                contactUs: 'Свяжитесь с нами',
                legal: 'Правовая информация',
            },
            copyright: '© {year} WebPros International GmbH. Все права защищены. WP Guardian и логотип WP Guardian являются товарными знаками WebPros International GmbH.',
        },
        Header: {
            UpdateSettingsCard: {
                title: 'Настройки автоматических обновлений',
            },
            CardLoader: {
                loading: 'Загрузка...',
            },
            InstallationsCard: {
                title: 'Сайты WordPress',
                vulnerable: 'Уязвимы: {count}',
                outdated: 'Устарели: {count}',
                protected: 'Защищены: {count}',
                safe: 'В норме: {count}',
                notOperable: 'Недействующие: {count}',
                poweredBy: '<whiteSpaceNoWrap>На базе {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: 'Уязвимости',
                databaseUpdate: 'Обновление базы данных',
                momentAgo: 'только что',
                noData: 'Нет данных',
                lastUpdateMinutes: '{minutes, plural, one {# минуту назад} few {# минуты назад} many {# минут назад} other {# минуты назад}}',
                lastUpdateHours: '{hours, plural, one {# час назад} few {# часа назад} many {# часов назад} other {# часа назад}}',
                lastUpdateDays: '{days, plural, one {# день назад} few {# дня назад} many {# дней назад} other {# дня назад}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: 'Наивысшая оценка CVSS',
                needsProtection: '{count, plural, one {# нуждается в защите} few {# нуждаются в защите} many {# нуждаются в защите} other {# нуждаются в защите}}',
            },
            QuickStartCard: {
                title: 'Быстрое начало',
                ctaBlockTitle: 'Настроить безопасность',
                ctaBlockText: 'в пару кликов',
            },
        },
        UserMenuAddon: {
            quickStart: 'Quick start',
            license: 'Информация о лицензии',
        },
    },
    Vulnerabilities: {
        updateByProtect: 'Сейчас нет обновлений для исправления этой уязвимости.',
        columns: {
            position: 'Где',
        },
        Onboarding: {
            title: 'Уязвимостей не найдено',
            description: 'Подключите серверы, чтобы найти сайты WordPress и проверить их на наличие уязвимостей и обновлений.',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: 'Частично устранено',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'Частично устранено отключением плагина',
                tooltip: {
                    'singleSiteMitigatedByOther': 'Подверженный этой уязвимости сайт защищен средствами, не входящими в WP Guardian.',
                    'mitigatedByOther': 'Подверженные этой уязвимости сайты защищены средствами, не входящими в WP Guardian',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'Источник:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'Эта запись содержит материалы, защищенные авторским правом.',
            wordfenceCopyright: 'Авторское право 2012-2024 Defiant Inc. Лицензия: Defiant предоставляет вам бессрочную, глобальную, неисключительную, безвозмездную, не требующую отчислений от продажи безотзывную лицензию на объект авторского права, которая позволяет воспроизводить, создавать производные продукты, публично показывать, публично исполнять, сублицензировать и распространять информацию об уязвимости этого программного обеспечения. Любая копия информации об уязвимости этого программного обеспечения является авторизованной при условии, что в каждой такой копии вы укажете гиперссылку на запись об этой уязвимости и воспроизведете текст информации об авторских правах Defiant, а также текст данной лицензии.',
            readMore: 'Подробнее',
        },
        Toolbar: {
            groupFilterMenu: 'Фильтр',
            groupSearch: 'Поиск',
            manage: 'Управлять',
            patch: 'Protection',
            searchPlaceholder: 'Найти уязвимость...',
            UpdateButton: {
                update: 'Установить обновления',
                cancel: 'Отмена',
                noSelection: 'Выберите хотя бы одну уязвимость, чтобы исправить с помощью обновления.',
                updateConfirmation: '{totalVulnerabilities, plural, one {Вы собираетесь обновить сайты, чтобы исправить # уязвимость} few {Вы собираетесь обновить сайты, чтобы исправить # уязвимости} many {Вы собираетесь обновить сайты, чтобы исправить # уязвимостей} other {Вы собираетесь обновить сайты, чтобы исправить # уязвимости}}. После начала обновления процесс нельзя будет прервать.',
                updateConfirmationPluginTitle: 'Несколько плагинов были отключены',
                updateConfirmationPluginCheckbox: 'Вновь включить плагины, отключенные политиками безопасности',
                updateConfirmationPluginCheckboxHint: 'Уязвимые плагины, отключенные политиками безопасности, автоматически включатся вновь, если их уязвимости будут исправлены после обновлений.',
                numberOfSites: 'Количество сайтов для обновления:',
                numberOfSitesUnmanaged: 'Количество сайтов для обновления (неуправляемые сайты будут пропущены):',
                sitesCalculating: 'Подсчитывается...',
                sitesCalculatingError: 'Ошибка подсчета',
                affectedSites: '{count, plural, one {{count} сайт} few {{count} сайта} many {{count} сайтов} other {{count} сайтов}}',
                unmanagedLabel: '{count, plural, one {# неуправляемый} few {# неуправляемых} many {# неуправляемых} other {# неуправляемых}}',
                updateByProtect: 'Сейчас нет обновлений для исправления этой уязвимости.',
                upgradeLicense: 'Повысьте лицензию',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: 'Игнорировать',
                noSelection: 'Выберите хотя бы одну уязвимость, чтобы проигнорировать или прекратить игнорировать',
            },
            PatchButton: {
                affectedSites: '{count, plural, one {{count} сайт} few {{count} сайта} many {{count} сайтов} other {{count} сайтов}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'Отмена',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Enable Protection',
                providerLogo: '<whiteSpaceNoWrap>На базе {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: 'Подсчитывается...',
                sitesCalculatingError: 'Не удалось подсчитать количество затронутых сайтов',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, one {# неуправляемый} few {# неуправляемых} many {# неуправляемых} other {# неуправляемых}}',
                update: 'Enable Protection',
                upgradeLicense: 'Повысьте лицензию',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: 'Нуждающиеся в защите',
            filterStateIgnored: 'Игнорируемые',
            filterComponentCore: 'Ядро',
            filterComponentPlugin: 'Плагин',
            filterComponentTheme: 'Тема',
            everywhere: 'Везде',
        },
        UpdateConfirmation: {
            description: '{count, plural, one {Вы собираетесь обновить # сайт.} few {Вы собираетесь обновить # сайта.} many {Вы собираетесь обновить # сайтов.} other {Вы собираетесь обновить # сайта.}} После начала обновления процесс нельзя будет прервать.',
        },
        Widgets: {
            disablePlugin: {
                title: 'Плагины',
                installations: '{enabledCount, plural, one {# активный} few {# активных} many {# активных} other {# активных}}',
                installationsDeactivated: '{count, plural, one {Деактивировано на # сайте} few {Деактивировано на # сайтах} many {Деактивировано на # сайтах} other {Деактивировано на # сайта}}',
                confirmationDeactivation: '{count, plural, one {Вы собираетесь отключить уязвимый плагин на # сайте.} other {Вы собираетесь отключить уязвимый плагин на # сайтах.}} После начала этот процесс нельзя будет прервать.',
                confirmationReactivation: '{count, plural, one {Вы собираетесь включить плагин, ранее отключенный по соображениям безопасности на # сайте. Это действие сделает ваш сайт уязвимым.} other {Вы собираетесь включить плагин, ранее отключенный по соображениям безопасности на # сайтах. Это действие сделает ваш сайт уязвимым.}} После начала этот процесс нельзя будет прервать.',
                dropdownDeactivate: 'Деактивировать',
                buttonDeactivateWithCount: 'Деактивировать {count}',
                buttonReactivateWithCount: 'Повторно активировать {count}',
                buttonDeactivateAll: 'Деактивировать все',
                buttonReactivate: 'Повторно активировать',
                tooltipAllPluginsDeactivatedByUser: 'WP Guardian не включает плагины, отключенные вручную администраторами сайта.',
            },
            updates: {
                title: 'Установить обновления',
                installations: '{count, plural, one {# сайт} few {# сайта} many {# сайтов} other {# сайтов}}',
                waitingForUpdatesTitle: 'Обновлений пока нет',
                buttonName: 'Обновить все',
            },
            patches: {
                allProtected: '{count, plural, one {# сайт} few {# сайта} many {# сайтов} other {# сайтов}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'Отмена',
                enableButton: 'Включить',
                installations: '{count} из {total}',
                protectAllSites: 'Защитить все',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: 'Защищенные сайты',
                titleAllProtected: 'Все защищены',
            },
        },
        installations: '{count, plural, one {# сайт} few {# сайта} many {# сайтов} other {# сайтов}}',
        servers: '{count, plural, one {# сервер} few {# сервера} many {# серверов} other {# сервера}}',
    },
    Onboarding: {
        title: 'Нет подключенных серверов',
        description: 'Подключите серверы, чтобы найти сайты WordPress и проверить их на наличие уязвимостей и обновлений.',
        demoServerWasAddedSuccessfully: 'Демо-сервер добавлен.',
        connectServers: '<addServerButton>Подключите свой сервер</addServerButton> <delimiter>или</delimiter> <addDemoServerButton>используйте наш демо-сервер</addDemoServerButton>',
        hint: 'Демо-сервер предоставлен командой WP Guardian. На нем находится несколько сайтов WordPress, благодаря чему вы можете легко изучить функциональные возможности продукта, прежде чем подключать собственный сервер.',
        feedbackButton: 'Оставить отзыв',
        demoServerPopover: {
            description: 'Демо-сервер работает в режиме "только для чтения". Сторонние операции, такие как установка обновлений, не изменят состояние сайтов на демо-сервере.',
            connectBtn: 'Подключить',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: 'Поиск: {searchValue}',
        searchByServer: 'Сервер: {searchValue}',
        searchByVulnerability: 'Уязвимость: {searchValue}',
        showAll: 'Показать все',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, one {# из сайтов на выбранных серверах в данный момент является недействующим} few {# из сайтов на выбранных серверах в данный момент являются недействующими} many {# из сайтов на выбранных серверах в данный момент являются недействующими} other {# из сайтов на выбранных серверах в данный момент являются недействующими}}. На таких сайтах операция производиться не будет, поскольку они либо сломаны, либо недоступны по каким-либо другим причинам.',
        state: {
            installingAgent: 'Установка агента',
            connectionError: 'Ошибка соединения',
            agentInstallationError: 'Установка агента не завершена',
            connectionBlocked: 'Подключение заблокировано',
            synchronizationInProgress: 'Синхронизация выполняется',
            connectionErrorStatusMessage: 'Подключение к серверу не может быть корректно установлено.',
            connectionErrorFixDescription: 'Проверьте журналы и настройки на вашем сервере ({ipAddress}) и дождитесь повторной проверки.',
            connectionBlockedStatusMessage: 'Соединение с сервером блокируется брандмауэром.',
            connectionBlockedFixDescription: 'Убедитесь, что порт 878 на сервере {ipAddress} открыт, и дождитесь повторной проверки.',
            agentInstallationErrorMessage: 'Установка агента не завершена',
            agentInstallationErrorFixDescription: "Мы не получили ответа от агента WP Guardian об успешном завершении установки в течение 5 минут. Это означает, что установка все еще продолжается, либо ее не удалось выполнить. Проверьте ход установки в консоли сервера или просмотрите журнал установки в файле '/root/wp-agent-install.log'",
        },
        ConnectDrawer: {
            title: 'Подключите свой сервер',
            done: 'Выполнено',
            tabs: {
                connectDroplet: 'Connect droplet',
                connectServer: 'Подключить сервер',
            },
        },
        AddDroplet: {
            videoDialogTitle: 'Connecting DigitalOcean droplet to WP Guardian',
            docsLinkText: 'Need help with connecting droplets? Check out the documentation',
            goToDoButton: 'Go to DigitalOcean to connect the droplet',
            head1: '1. Copy the following snippet',
            head2: '2. Activate the snippet in droplet console',
            paragraph1singleDroplet: 'Press the button below to open the droplet console, paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
            paragraph1multipleDroplets: 'Press the button below, choose a droplet you\'d like to connect to WP Guardian. From the detail page, click the Access tab in the left menu, then launch the droplet console and paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
        },
        AddServer: {
            done: 'Выполнено',
            paragraph1: 'Откройте порт 878 для входящих подключений со следующих IP-адресов на каждом сервере, к которому вы хотите подключиться: 34.254.37.129, 52.51.23.204 и 52.213.169.7',
            paragraph2: 'Скопируйте и вставьте следующее описание в консоль сервера и дождитесь завершения процесса установки',
            errors: {
                failedToGenerateToken: 'Не удалось создать команду установки.',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'Копировать',
            copied: 'Описание скопировано',
            refreshTooltip: 'Команда подключения действительна для неограниченного количества серверов в течение этого времени. Обновите команду, если вам нужно больше времени.',
        },
        SettingsDrawer: {
            title: 'Настройки',
            subTitle: {
                single: 'Сервер: {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'Обновления',
                dailyTaskTime: 'Расписание',
            },
            loadFailed: 'Не удалось загрузить настройки.',
            buttonSave: 'Сохранить',
            successToast: 'Настройки сохранены.',
            Updates: {
                customizedSection: {
                    description: 'Настройки обновлений будут применены ко всем новым и текущим сайтам на этом сервере без персональных настроек обновлений',
                    checkboxDescription: 'Также применить настройки ко всем сайтам с персональными настройками обновлений',
                },
            },
            DailyTaskTime: {
                description: 'WP Guardian проверяет наличие новых уязвимостей и доступных обновлений каждые 24 часа. Эта операция влияет на производительность, поэтому вы можете запланировать ее запуск в определенное время.',
                unsetOption: 'Определено глобальными настройками',
            },
        },
        RemoveServersButton: {
            remove: 'Отключить',
            noSelectedServers: 'Выберите хотя бы один сервер для отключения.',
            removeDescription: 'Агент WP Guardian не удаляется автоматически при отключении сервера. Используйте скрипт удаления, чтобы удалить агент WP Guardian (<a>инструкция</a>).',
            removeSingleServerConfirmation: 'Отключить {selectedServerTitle}?',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, one {Отключить <b>#</b> сервер?} few {Отключить <b>#</b> сервера?} many {Отключить <b>#</b> серверов?} other {Отключить <b>#</b> сервера?}}',
        },
        ListActions: {
            remove: 'Отключить',
            refresh: 'Обновить данные',
            findNewSites: 'Поиск новых сайтов',
            settings: 'Настройки',
            disablePatch: 'Снять защиту',
            update: 'Обновить все сайты',
            manageLabels: 'Применить метки',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'Отмена',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, one {# WP сайт} few {# WP сайта} many {# WP сайтов} other {# WP сайта}}',
            notOperable: 'Недействующие: {count}',
            vulnerable: 'Уязвимы: {count}',
            vulnerabilities: 'Уязвимостей: {count}',
            outdated: 'Устарели: {count}',
            lastCheck: 'Последняя проверка: {dateTime}',
            lastCheckToday: 'Последняя проверка: сегодня в {time}',
            unsupportedAgent: 'Неподдерживаемая версия агента',
            coreOutdated: 'Устаревшие',
        },
        columns: {
            server: 'Сервер',
        },
        OutdatedAgentError: {
            message: 'Версия агента WP Guardian (wp-agent) на сервере устарела',
            agentVersion: '{version, select, _ {Ваша версия агента WP Guardian неизвестна.} other {Ваша версия агента WP Guardian: {version}.}}',
            updateDescription: 'Обновите пакет wp-agent до версии {wpAgentMinSupportedVersion} или выше.',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'Настройки обновлений',
            ipAddress: '<b>IP сервера:</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'Фильтр',
            manage: 'Управлять',
            remove: 'Отключить',
            add: 'Подключить',
            groupFilter: 'Фильтр',
            groupSearch: 'Поиск',
            searchPlaceholder: 'Найти сервер...',
            noSelectedServers: 'Выберите хотя бы один сервер для отключения.',
            FilterPanel: {
                filterAlerts: 'Оповещения',
                labelsPlaceholder: 'Все метки',
            },
            RefreshButton: {
                buttonText: 'Обновить данные',
                noSelectedServers: 'Выберите хотя бы один сервер для обновления.',
            },
            FindNewSitesButton: {
                buttonText: 'Поиск новых сайтов',
                noSelectedServers: 'Выберите хотя бы один сервер, чтобы выполнить поиск новых сайтов WordPress.',
            },
            UpdateButton: {
                buttonText: 'Установить обновления',
                confirmButtonText: 'Обновить все',
                noSelectedServers: 'Выберите хотя бы один сервер, чтобы установить все доступные обновления для всех сайтов WordPress.',
                updateSingleServerConfirmation: 'Все доступные обновления будут установлены для всех сайтов WordPress, расположенных на сервере <b>{selectedServerTitle}</b>.',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, one {Все доступные обновления будут установлены для всех сайтов WordPress, расположенных на <b>#</b> выбранном сервере.} few {Все доступные обновления будут установлены для всех сайтов WordPress, расположенных на <b>#</b> выбранных серверах.} many {Все доступные обновления будут установлены для всех сайтов WordPress, расположенных на <b>#</b> выбранных серверах.} other {Все доступные обновления будут установлены для всех сайтов WordPress, расположенных на <b>#</b> выбранных серверах.}}',
            },
            ProtectButton: {
                buttonText: 'Защитить',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'Применить метки',
            },
            SettingsButton: {
                buttonText: 'Настройки',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: 'Подключить',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: 'Игнорировать уязвимости с низким уровнем риска',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: 'Игнорировать уязвимости с низким уровнем риска',
            warningMessage: 'Если этот параметр отключен, все метки «игнорировать автоматически» будут удалены, и все автоматически игнорируемые уязвимости снова станут видимыми.',
        },
    },
    Installations: {
        yes: 'Да',
        no: 'Нет',
        update: 'Установить обновления',
        refresh: 'Обновить данные',
        detach: 'Отсоединить',
        updateSettings: 'Настройки обновлений',
        sitesAreNotOperable: '{notOperable, plural, one {# из выбранных сайтов в данный момент является недействующим} few {# из выбранных сайтов в данный момент являются недействующими} many {# из выбранных сайтов в данный момент являются недействующими} other {# из выбранных сайтов в данный момент являются недействующими}}. На таких сайтах операция производиться не будет, поскольку они либо сломаны, либо недоступны по каким-либо другим причинам.',

        DetachInstallationsButton: {
            detach: 'Отсоединить',
            detachSingleInstallationConfirmation: 'Отсоединить установку <b>{selectedInstallationTitle}</b> от WP Guardian? Отсоединенные установки будут игнорироваться при последующем сканировании сервера.',
            detachSeveralInstallationsConfirmation: 'Отсоединить установки (<b>{selectedInstallationsCount}</b>) от WP Guardian? Отсоединенные установки будут игнорироваться при последующем сканировании сервера.',
        },
        Onboarding: {
            title: 'Сайты WordPress не найдены',
            description: 'Подключите серверы, чтобы найти сайты WordPress и проверить их на наличие уязвимостей и устаревших компонентов.',
        },
        columns: {
            website: 'Сайт',
            server: 'Сервер',
            synchronized: 'Сессия подключена',
            lastCheck: 'Последняя проверка',
            protect: 'Защитить',
            siteHealth: 'Состояние сайта',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'Меню фильтров',
                filterAll: 'Все',
                filterVulnerable: 'С уязвимостями',
                filterOutdated: 'Устаревшие',
                filterNotOperable: 'Недействующие',
                filterProtected: 'Защищенные',
                filterUnmanaged: 'Неуправляемые',
                filterNotProtected: 'Незащищенные',
                filterSafe: 'Проблем нет',
            },
            labelsPlaceholder: 'Все метки',
        },
        Toolbar: {
            groupFilterMenu: 'Фильтр',
            groupSearch: 'Поиск',
            searchPlaceholder: 'Найти сайт...',
            groupActions: 'Действия',
            groupUpdate: 'Установить обновления',
            groupProtect: 'Защитить',
            subGroupActions: 'Действия',
            buttons: {
                update: {
                    text: 'Установить обновления',
                    noSelection: 'Выберите хотя бы один сайт, чтобы установить все возможные обновления.',
                },
                refresh: {
                    text: 'Обновить данные',
                    noSelection: 'Выберите хотя бы один сайт для обновления.',
                },
                protect: {
                    text: 'Защитить',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'Применить метки',
                },
                settings: {
                    text: 'Настройки',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: 'Обновить <b>{selectedInstallationTitle}</b>?',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, one {Вы собираетесь обновить <b>#</b> сайт WordPress} few {Вы собираетесь обновить <b>#</b> сайта WordPress} many {Вы собираетесь обновить <b>#</b> сайтов WordPress} other {Вы собираетесь обновить <b>#</b> сайта WordPress}}',
                confirmButton: 'Обновить',
                cancelButton: 'Отмена',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: 'Защитить',
                cancelButton: 'Отмена',
            },
        },
        SiteHealthColumn: {
            outdated: 'Устарели: {count}',
            safe: 'OK',
            notOperable: 'Недействующий',
            unsupportedAgent: 'Неподдерживаемая версия агента',
            vulnerabilities: 'Уязвимостей: {count}',
            lastCheckAtTooltip: 'Последняя проверка: {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: 'Настройки сохранены.',
            title: 'Настройки обновлений',
            subTitle: {
                single: 'Сайт: {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: '{notOperableCount, plural, one {# недействующий сайт} few {# недействующих сайта} many {# недействующих сайтов} other {# недействующих сайтов}} и {unmanagedCount, plural, one {# неуправляемый сайт} few {# неуправляемых сайта} many {# неуправляемых сайтов} other {# неуправляемых сайтов}} будут пропущены.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, one {# неуправляемый сайт будет пропущен} few {# неуправляемых сайта будут пропущены} many {# неуправляемых сайтов будет пропущено} other {# неуправляемых сайтов будет пропущено} }.',
            },
            buttonSave: 'Сохранить',
            confirmationPopover: {
                confirmBtn: 'Сохранить',
                description: {
                    single: 'Вы собираетесь изменить настройки обновлений для сайта WordPress: {installationName}. Продолжить?',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'Обновления',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Включить защиту от уязвимостей',
        },
        InstallationSummary: {
            updateAll: 'Обновить все',
            updateSettings: 'Настройки обновлений',
            upToDate: 'Актуально',
            AssetSummary: {
                plugin: {
                    title: 'Плагины: [{count}]',
                    update: 'Установить обновления плагинов',
                },
                theme: {
                    title: 'Темы: [{count}]',
                    update: 'Установить обновления тем',
                },
                safe: 'OK',
                vulnerable: 'Уязвимы: {count}',
                outdated: 'Устарели: {count}',
                vulnerabilities: 'Уязвимостей: {count}',
                isOutdated: 'Устаревшие',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: 'Обновить до версии {version}',
                updateSettingsTitle: 'Настройки обновлений',
                vulnerabilities: '{count, plural, one {# уязвимость} few {# уязвимости} many {# уязвимостей} other {# уязвимости}}',
                isOutdated: 'Устарело',
            },
            unsupportedAgentError: 'Версия wp-agent сервера устарела. Минимальная требуемая версия: {wpAgentMinSupportedVersion}',
            notOperableUnknownError: 'Во время сканирования сайта на наличие уязвимостей произошла неизвестная ошибка.',
            hostname: 'Имя хоста сервера:',
            ipAddress: 'IP-адрес сервера:',
            UpdateConfirmation: {
                title: 'Подтвердите обновление',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'Отмена',
                continueButton: 'Обновить',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Защита от уязвимостей с помощью <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: 'Защита от уязвимостей отключена',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: 'Включить защиту',
            },
            ProtectInfoPopover: {
                label: 'Уязвимость все еще активна?',
                message: 'Для нейтрализации уязвимости требуются определенные правила защиты. Вот почему правила защиты могут быть недоступны для определенной уязвимости:',
                noPatch: {
                    title: 'В разработке',
                    description: 'Правила для уязвимостей с высокой степенью воздействия обычно становятся доступными в течение нескольких часов после их обнаружения. Создание правил для уязвимостей с низкой степенью воздействия может занимать больше времени.',
                },
                unlikelyExploited: {
                    title: 'Низкий риск',
                    description: 'Некоторые уязвимости оказывают минимальное воздействие или не обладают реальными возможностями нанесения вреда. Поскольку они не представляют реальной угрозы для сайтов, правила защиты от них не требуются.',
                },
                dbMatch: {
                    title: 'Отсутствует в базе данных',
                    description: 'Уязвимости, которые присутствуют только в базе данных WordPress или не сопоставлены с соответствующими записями из базы данных Patchstack, не получают правил защиты.',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'сообщите нам',
            },
        },
        WebsiteDrawer: {
            title: 'О сайте WordPress',
            titleUpdateInProgress: 'Обновление: {title}',
            subtitleUpdateInProgress: 'Обновление выбранных объектов',
            errors: {
                installationNotFound: "Не удалось загрузить информацию об установке с идентификатором {id}",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'Отмена',
                        updateButton: 'Обновить',
                        description: '{count, plural, one {Вы собираетесь исправить # уязвимость с помощью обновления на сайте <b>{website}</b>.} few {Вы собираетесь исправить # уязвимости с помощью обновления на сайте <b>{website}</b>.} many {Вы собираетесь исправить # уязвимостей с помощью обновления на сайте <b>{website}</b>.} other {Вы собираетесь исправить # уязвимости с помощью обновления на сайте <b>{website}</b>.}} После начала обновления процесс нельзя будет прервать.',
                        updateConfirmationPluginCheckbox: '{count, plural, one {Повторное включение плагина отключено политикой безопасности} few {Повторное включение плагинов отключено политикой безопасности} many {Повторное включение плагинов отключено политикой безопасности} other {Повторное включение плагинов отключено политикой безопасности}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, one {Уязвимый плагин, отключенный политикой безопасности, будет автоматически включен повторно, если его уязвимость исправлена в обновлении.} few {Уязвимые плагины, отключенные политикой безопасности, будут автоматически включены повторно, если их уязвимость исправлена в обновлении.} many {Уязвимые плагины, отключенные политикой безопасности, будут автоматически включены повторно, если их уязвимость исправлена в обновлении.} other {Уязвимые плагины, отключенные политикой безопасности, будут автоматически включены повторно, если их уязвимость исправлена в обновлении.}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'Действия',
                            buttons: {
                                update: 'Обновить',
                                processing: 'Обработка',
                            },
                        },
                        filters: {
                            title: 'Фильтры',
                            groupFiltersTitle: 'Фильтр',
                            search: {
                                title: 'Поиск',
                                placeholder: 'Найти уязвимость...',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: 'Нуждающиеся в защите',
                                filterStateIgnored: 'Игнорируемые',
                                filterComponentHeader: 'Где',
                            },
                        },
                    },
                    title: 'Уязвимости',
                    columns: {
                        component: 'Где',
                    },
                    emptyList: {
                        title: 'Все данные в безопасности',
                        description: 'На этом сайте не найдены известные уязвимости.',
                    },
                    waitingForUpdate: 'Нет доступных обновлений',
                    updateTo: 'Обновить до версии {availableVersion}',
                    update: 'Обновить',
                    disablePlugin: 'Деактивировать плагин',
                    enablePlugin: 'Активировать плагин',
                    updateByProtect: 'Сейчас нет обновлений для исправления этой уязвимости.',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, one {Вы собираетесь обновить # ресурс на сайте <b>{website}</b>.} few {Вы собираетесь обновить # ресурса на сайте <b>{website}</b>.} many {Вы собираетесь обновить # ресурсов на сайте <b>{website}</b>.} other {Вы собираетесь обновить # ресурса на сайте <b>{website}</b>.}} После начала обновления процесс нельзя будет прервать.',
                        cancelButton: 'Отмена',
                        updateButton: 'Обновить',
                    },
                    title: 'Устаревшие',
                    toolbar: {
                        actions: {
                            title: 'Действия',
                            buttons: {
                                update: 'Обновить',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'Фильтры',
                            groupSearchTitle: 'Поиск',
                            search: {
                                title: 'Поиск',
                                placeholder: 'Поиск устаревших компонентов...',
                            },
                        },
                    },
                    columns: {
                        name: 'Имя',
                        type: 'Где',
                    },
                    emptyList: {
                        title: 'Все обновлено',
                        description: 'На этом сайте установлены последние версии ядра WordPress, плагинов и тем.',
                    },
                    updateTo: 'Обновить до версии {version}',
                },
            },
        },
        Actions: {
            enableProtectButton: 'Защитить',
            disableProtectButton: 'Снять защиту',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'Применить метки',
        },
        DisableProtectionButton: {
            confirmButton: 'Снять защиту',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: 'Уведомления',
        listEmptyTitle: 'Будьте в курсе самых важных событий',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'Отметить все прочитанным',
        deleteNotification: 'Удалить уведомление',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: 'Новая лицензия активна',
                licenseLimitReached: 'Достигнуто ограничение на количество сайтов',
                licenseExpired: 'Срок действия лицензии истек',
                licenseTerminated: 'Действие лицензии прекращено',
                trialCreated: 'Пробный период запущен',
                trialExpired: 'Пробный период закончился',
                trialExpiredSoon: 'Пробный период истекает {expirationDate}',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: 'Ваш новый лицензионный ключ ({licenseName}) #{licenseKey} теперь активен.',
                licenseLimitReached: '{limit, plural, one {Ваша лицензия WP Guardian позволяет управлять # сайтом,} other {Ваша лицензия WP Guardian позволяет управлять # сайтами,}} {total, plural, one {но сейчас у вас подключен # сайт.} few {но сейчас у вас подключено # сайта.} many {но сейчас у вас подключено # сайтов.} other {но сейчас у вас подключен # сайт.}} Повысьте свою лицензию, чтобы управлять всеми своими сайтами.',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'Пробный период WP Guardian начался. Во время пробного периода у вас есть полный доступ ко всем функциям WP Guardian максимум для {sitesLimit, plural, one {# сайта} few {# сайтов} many {# сайтов} other {# сайтов}}.',
                trialExpired: "Ваш пробный период WP Guardian закончился. Выберите и приобретите лицензию, чтобы продолжить использование продукта, или сообщите нам, почему вы не хотите этого делать, через форму обратной связи выше.",
                trialExpiredSoon: 'Пробный период WP Guardian истекает {expirationDate}. Рекомендуем приобрести лицензию, чтобы продолжить использование продукта.',
            },
            markAsRead: 'Пометить прочитанным',
            markAsUnread: 'Пометить непрочитанным',
        },
        settings: 'Настройки',
    },
    ConfirmNotificationsEmail: {
        title: 'Подтверждение почты для уведомлений',
        error: 'Срок действия ссылки истек',
        successMessage: 'Почта для уведомлений была изменена.',
    },
    NotificationsSettingsDrawer: {
        title: 'Настройки уведомлений',
        Content: {
            sendSectionTitle: 'Куда присылать уведомления',
            email: 'Электронная почта',
        },
    },
    NotificationSettingsEventSection: {
        title: 'Какие уведомления отправлять',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'Уведомления о лицензии',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: 'Изменить почту для уведомлений',
        buttonSave: 'Отправить письмо',
        buttonCancel: 'Отмена',
        buttonEdit: 'Редактировать',
        email: 'Электронная почта',
        description: 'Мы отправим вам письмо с инструкциями о том, как обновить адрес почты, используемый для получения уведомлений.',
        successMessage: 'Письмо было отправлено на адрес <b>{email}</b>. Следуйте инструкциям в этом письме, чтобы подтвердить новый адрес электронной почты.',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: 'Обнаружение уязвимостей',
            description: 'Обновления в режиме реального времени по новым уязвимостям WordPress на подключенных сайтах',
        },
        secureSites: {
            title: 'Обеспечение безопасности сайтов WordPress',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: 'Автоматические обновления',
            description: 'Гибкие настройки автоматических обновлений для серверов и сайтов',
        },
        safeUpdates: {
            title: 'Безопасные обновления',
            description: 'Резервное копирование перед обновлением для автоматического отката в случае ошибки обновления',
        },
        notifications: {
            title: 'Текущее состояние и уведомления',
            description: 'Будьте в курсе состояния ваших сайтов WordPress',
        },
    },
    LicensePage: {
        benefits: {
            title: 'Обеспечение безопасности сайтов WordPress',
        },
        ExpirationLabel: {
            noLicense: "У вас нет лицензии",
            expiredLicense: 'Истек срок действия вашей лицензии',
        },
        LicenseHeading: {
            welcome: 'Добро пожаловать в WP Guardian',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'Выберите лицензию',
            trial: 'Выберите лицензию',
            trialLicenseExpired: 'Пробный период окончен',
        },
        LicenseDescription: {
            licenseKey: 'Ключ лицензии #{number}',
            nextUpdate: 'Следующее обновление ключа лицензии: {date}',
            expirationDate: 'Дата окончания срока действия: {date}',
            noLicense: 'Бесплатная версия WP Guardian показывает только информацию об уязвимостях, а обновления сайта нужно проводить вручную. Приобретите лицензию, чтобы получить доступ ко всем функциям WP Guardian.',
            trialJustStarted: 'У вас есть доступ ко всем функциям WP Guardian в течение пробного периода. После его окончания вам будет предложена наиболее подходящая лицензия. У вас также будет возможность продолжить использование WP Guardian бесплатно с некоторыми ограничениями.',
            trial: '{sites, plural, one {В пробной версии вы сможете управлять максимум # сайтом WordPress в течение ограниченного времени.} few {В пробной версии вы сможете управлять максимум # сайтами WordPress в течение ограниченного времени.} many {В пробной версии вы сможете управлять максимум # сайтами WordPress в течение ограниченного времени.} other {В пробной версии вы сможете управлять # WordPress sites в течение ограниченного времени.}} Приобретите лицензию, если хотите продолжать пользоваться всеми текущими и будущими функциями WP Guardian.',
            limit: '{sites, plural, one {Ваша лицензия позволяет управлять не более # сайтом WordPress.} few {Ваша лицензия позволяет управлять не более # сайтами WordPress.} many {Ваша лицензия позволяет управлять не более # сайтами WordPress.} other {Ваша лицензия позволяет управлять не более # сайта WordPress.}}',
            unlimited: 'Ваша лицензия позволяет управлять неограниченным количеством сайтов WordPress. {sites, plural, one {В стоимость базовой лицензии входит управление # сайтом.} few {В стоимость базовой лицензии входит управление # сайтами.} many {В стоимость базовой лицензии входит управление # сайтами.} other {В стоимость базовой лицензии входит управление # сайта.}} Все сайты, превышающие лимит, оплачиваются по принципу "оплата по факту".',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: 'Посмотреть подробности',
            nextBillingDate: 'Дата следующего счета: {date}',
            subscriptionCancelled: 'Подписка отменена.',
        },
        CurrentUsage: {
            youAreUsingNow: 'Текущая загрузка:',
            sitesTotal: '{sites, plural, one {Всего # сайт} few {Всего # сайта} many {Всего # сайтов} other {Всего # сайтов}}',
            allowedToManage: '{sites, plural, one {Можно управлять # сайтом} few {Можно управлять # сайтами} many {Можно управлять # сайтами} other {Можно управлять # сайтами}}',
            outOfLimit: '{sites, plural, one {Сайтов сверх лимита лицензии: #} other {Сайтов сверх лимита лицензии: #}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Unlimited',
                label: 'Для более чем 1000 сайтов',
                feature1: '10 000 сайтов',
                feature2: 'Оплата по факту за более 10 000 сайтов',
                feature3: 'Доступ ко всем функциям',
                pricePerSite: '{price, number, ::currency/USD} for each site above 10k',
            },
            standard: {
                title: 'Standard',
                label: 'До 1000 сайтов',
                feature1: 'Оплата перед использованием',
                feature2: 'Доступ ко всем функциям',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'Использовать этот тариф',
            changePlan: 'Изменить план',
            chooseAnotherOption: 'Выберите другой вариант, чтобы повысить свой план',
        },
        LicenseList: {
            columns: {
                license: 'Лицензия',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: 'Купить',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: 'Повторно активировать',
            details: 'Details',
        },
        chooseLicenseNow: 'Выберите лицензию',
        licensesTitle: 'Выберите лицензию, которая вам подходит',
        faq: {
            title: 'Часто задаваемые вопросы',
            question1: {
                question: 'Что произойдет после окончания пробного периода?',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: 'Что произойдет при достижении лимита лицензии?',
                answer: 'Сайты, добавленные в WP Guardian после достижения лимита лицензии, будут помечены как неуправляемые. Информация об уязвимостях таких сайтов будет по-прежнему актуальна, а обновления можно будет запустить только вручную. Все остальные функции WP Guardian для них будут недоступны.',
            },
            question4: {
                question: 'Что такое "оплата по факту"?',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'Вы можете получить лицензию в <kaLink>Key Administrator</kaLink>, <manage2Link>Manage2</manage2Link> или у торгового представителя.',
            standard: {
                title: '{sites, plural, one {Вы хотите выбрать стандартную лицензию для # сайта} few {Вы хотите выбрать стандартную лицензию для # сайтов} many {Вы хотите выбрать стандартную лицензию для # сайтов} other {Вы хотите выбрать стандартную лицензию для # сайтов}}',
                licenseLimit: '{sites, plural, one {Лицензия включает только # сайт} few {Лицензия включает только # сайта} many {Лицензия включает только # сайтов} other {Лицензия включает только # сайта}}',
                feature1: 'Стандартная лицензия включает определенное количество сайтов без возможности оплаты по факту.',
                feature2: 'Все сайты, добавленные после достижения лимита лицензии, будут помечены как неуправляемые.',
                feature3: 'Вы не сможете управлять неуправляемыми сайтами через WP Guardian, пока не улучшите свою лицензию.',
                feature4: 'Неуправляемые сайты станут управляемыми, если будет повышен лимит лицензии или если в текущей лицензии освободится место.',
                feature5: 'Если сайт был подключен к WP Guardian больше нескольких дней, он учитывается при использовании лицензии.',
                feature6: 'Сломанные сайты и сайты, недоступные по другим причинам, не учитываются при использовании лицензии.',
            },
            unlimited: {
                title: 'Вы выбрали неограниченную лицензию',
                feature1: 'Базовая цена неограниченной лицензии включает в себя 10 000 сайтов.',
                feature2: 'За сайты свыше первых 10 000 оплата производится по факту.',
                feature3: 'Если сайт был подключен к WP Guardian больше нескольких дней, он учитывается при использовании лицензии.',
                feature4: 'Сломанные сайты и сайты, недоступные по другим причинам, не учитываются при использовании лицензии.',
            },
        },
        UpgradeButton: {
            upgrade: 'Повысить',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'Доступ ко всем функциям',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'Купить лицензию',
            upgradeLicense: 'Повысьте лицензию',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'Назад на Домашнюю страницу',
    },
    LabelsDrawer: {
        title: 'Управлять метками',
        LabelsList: {
            create: 'Создать метку',
            edit: 'Редактировать',
            delete: 'Удалить',
            noSelectedLabels: 'Выберите хотя бы одну метку для удаления.',
            columns: {
                title: 'Метка',
            },
            search: {
                title: 'Найти',
                placeholder: 'Найти метку',
            },
            emptyList: {
                title: 'Создать метку',
                description: "У вас нет меток, которые позволяют различать серверы и сайты. Хотите их создать?",
            },
            filteredEmptyList: {
                title: 'Не удалось найти элементы, соответствующие фильтру',
                description: 'Измените текст метки, которую вы ищете, и попробуйте еще раз',
            },
        },
        DeleteLabelsPopover: {
            delete: 'Удалить',
            confirmation: '{count, select, 1 {Удаление метки снимет ее со всех элементов, к которым она применялась.} other {Удаление меток снимет их со всех элементов, к которым они применялись.}} Продолжить?',
        },
        CreateLabelDrawer: {
            title: 'Создать метку',
        },
        EditLabelDrawer: {
            title: 'Изменить метку',
            notFound: 'Метка не найдена',
        },
        LabelForm: {
            save: 'Сохранить',
            cancel: 'Отмена',
            fields: {
                title: {
                    label: 'Имя метки',
                    description: 'Максимум 30 символов',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'ОТМЕТИТЬ КАК',
        noSelection: 'Выберите хотя бы один сервер, чтобы добавить или снять метку.',
        buttons: {
            apply: 'Применить',
            create: 'Создать новую метку',
            manage: 'Управлять метками',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'все',
            tooltip: 'Безопасные обновления включены',
            components: {
                core: 'ядро',
                themes: 'темы',
                plugins: 'плагины',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'обновления безопасности',
                major: 'все обновления',
                disabled: "не устанавливать",
                notSet: 'на уровене сайта',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, one {Ваша лицензия включает управление только # сайтом} few {Ваша лицензия включает управление только # сайтами} many {Ваша лицензия включает управление только # сайтами} other {Ваша лицензия включает управление только # сайтами}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, one {# неуправляемый сайт будет пропущен} few {# неуправляемых сайта будут пропущены} many {# неуправляемых сайтов будет пропущено} other {# неуправляемых сайтов будет пропущено}}',
        operableNotManagedSitesMessage: 'WP Guardian игнорирует неуправляемые сайты, поскольку они превышают ограничения вашей лицензии. Такие сайты пропускаются при проведении массовых действий. ',
        notOperableNotManagedSitesTitle: '{notOperableCount, plural, one {# недействующий сайт} few {# недействующих сайта} many {# недействующих сайтов} other {# недействующих сайтов}} и {unmanagedCount, plural, one {# неуправляемый сайт} few {# неуправляемых сайта} many {# неуправляемых сайтов} other {# неуправляемых сайтов}} будут пропущены',
        notOperableNotManagedSitesMessage: 'Выбранная вами операция не будет выполнена для этих сайтов, так как они являются недействующими, либо превышают ограничения вашей лицензии. Такие сайты пропускаются при проведении массовых действий.',
        managedSitesWillBePerformed: 'На всех управляемых сайтах выбранная вами операция будет выполнена без ограничений.',
        chooseLicenseButton: 'Выберите лицензию',
        LicenseTerminatedDialog: {
            title: 'Your license is terminated',
            message: 'Приобретите лицензию, чтобы продолжить пользоваться расширенными функциями WP Guardian.',
            chooseLicenseButton: 'Выберите лицензию',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'Определяемые сайтом обновления',
            autoupdate: 'Автообновления',
            manual: 'Обновления вручную',
            custom: 'Настраиваемые обновления',
        },
        unmanaged: {
            title: 'Не управляется',
            tooltip: 'WP Guardian игнорирует неуправляемые сайты, потому что они превышают ограничения вашей лицензии. Сайты, помеченные как неуправляемые из-за лицензионных ограничений, по-прежнему будут иметь актуальную информацию об уязвимостях, но обновить их можно только вручную. Такие сайты будут переведены в управляемые, если лимит лицензии будет увеличен или в текущей лицензии появится свободное место.',
        },
        menu: {
            remove: 'Удалить',
            filter: 'Фильтр по этой метке',
        },
    },
    Cuttable: {
        showMore: 'Развернуть',
        showLess: 'Свернуть',
    },
};