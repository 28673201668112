// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Button, IconProps, TranslationProps } from '@plesk/ui-library';
import { useNavigate } from 'react-router-dom';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';

type ConnectServersButtonProps = {
    buttonText?: TranslationProps;
    icon?: IconProps | string;
};

const ConnectServersButton = ({ buttonText, ...props }: ConnectServersButtonProps) => {
    const translate = useTranslate('security-dashboard.Servers.ConnectServersButton');
    const navigate = useNavigate();
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();

    const handleClickConnect = () => {
        analyticsEvents.wpConnectServerClick();
        navigate('/security-dashboard/servers/add/server');
    };

    return (
        <Button intent="primary" onClick={handleClickConnect} {...props}>
            {buttonText ? buttonText : translate('buttonText')}
        </Button>
    );
};

export default ConnectServersButton;
