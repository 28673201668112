// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'Esiste già un\'etichetta con questo nome',
        invitationNotExist: 'L\'invito non esiste.',
        wpApiClientUnreachable: 'Il server o il sito sembra essere momentaneamente offline. Verifica l\'accessibilità del server o del sito e riprova. Se il server o il sito è operativo e la sua connessione con WP Guardian sembra funzionare correttamente, contatta il nostro supporto tecnico.',
    },
    termsOfUseLink: 'Termini di utilizzo di WP Guardian',
    NotFoundPage: {
        title: 'Pagina non trovata',
        description: "Impossibile trovare la pagina richiesta. È possibile che tu abbia seguito un link errato o che la pagina sia stata eliminata.",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'Conferma l\'annullamento dell\'attivazione della licenza',
            description: 'Stai per annullare l\'attivazione della licenza di {product}. Puoi attivare la licenza successivamente in un qualsiasi momento seguendo di nuovo il link. Se la licenza è stata acquistata per un altro account, accedi a tale account e segui il link di attivazione.',
            cancelButton: 'Annulla',
            continueButton: 'Continua l\'attivazione',
        },
        SecurityDashboardActivateLicense: {
            title: 'Conferma l\'attivazione della licenza di WP Guardian',
            description: 'Conferma l\'attivazione della licenza di <b>{product}</b> per il seguente account: <mailto>{userEmail}</mailto>. La licenza può essere attivata per un solo account.',
            fixableErrorDescription: 'Si è verificato un errore durante l\'attivazione della licenza di WP Guardian. Riprova o invia una richiesta di supporto.',
            nonFixableErrorDescription: 'Si è verificato un errore durante l\'attivazione della licenza di WP Guardian. Invia una richiesta di supporto.',
            loaderText: 'È in corso la configurazione del tuo account WP Guardian',
            redirectText: 'Reindirizzamento a WP Guardian',
            confirmButton: 'Conferma',
            cancelButton: 'Annulla',
            retryButton: 'Riprova',
            gotItButton: 'OK',
            errors: {
                linkError: 'Il link di attivazione non è valido.',
                unknownError: 'Errore interno del servizio di attivazione.',
            },
        },
    },
    Filters: {
        all: 'Tutte',
    },
    FiltersForm: {
        title: 'Filtro',
        clear: 'Elimina',
        apply: 'Applica filtro',
    },
    Invitation: {
        activationFailure: 'Attivazione del link d\'invito non riuscita',
    },
    UnderConstructionPopover: {
        title: 'In costruzione',
        description: "Scusa, ci stiamo ancora lavorando!",
    },
    RiskRankTitle: {
        title: 'Rischio',
        description: 'Il grado di rischio è una valutazione aggregata dell\'impatto della vulnerabilità, basata su classificazioni CVSS ed EPSS, sulla priorità delle patch di Patchstack e su altri fattori.',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'Questa vulnerabilità viene sfruttata attivamente, si consiglia un intervento immediato.',
        highRisk: 'Questa vulnerabilità è ad alto rischio e richiede un intervento tempestivo',
        mediumRisk: 'Questa vulnerabilità è a rischio moderato e non richiede un intervento tempestivo, ma è consigliabile risolverla al più presto',
        lowRisk: 'Questa vulnerabilità è a basso rischio e può essere ignorata senza problemi',
        cvss: 'CVSS: {score}',
        emptyCvss: 'N/D',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Protection disabled',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: 'Ignorate',
        },
        ignoredDueToLowRisk: {
            title: 'Ignorata(e) automaticamente',
        },
        doNotIgnoreAutomatically: {
            title: 'Non ignorare automaticamente',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Unlimited',
    },
    taskTypes: {
        scanForNewSites: 'Ricerca di nuovi siti WordPress',
        refreshInstallations: 'Aggiornamento in corso',
        detachInstallations: 'Dissociazione in corso',
        updateInstallation: 'Aggiornamento in corso',
        massUpdateInstallations: 'Aggiornamento in corso',
        applyVirtualPatches: 'Enable vulnerability protection',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'Salvataggio impostazioni di aggiornamento',
        clearInstallationCache: 'Cancellazione cache d\'installazione',
        checkVulnerability: 'Verifica delle vulnerabilità',
        mitigateVulnerabilities: 'Mitigazione vulnerabilità',
        setDailyTaskStartTime: 'Modifica pianificazione di verifica della sicurezza',
    },
    installationComponents: {
        core: 'Nucleo',
        plugin: 'Plug-in',
        theme: 'Tema',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {L\'etichetta è stata assegnata} other {Le etichette sono state assegnate}}',
        failed: '{count, select, 1 {Impossibile assegnare l\'etichetta} other {Impossibile assegnare le etichette}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {L\'etichetta è stata rimossa} other {Le etichette sono state rimosse}}',
        failed: '{count, select, 1 {Impossibile rimuovere l\'etichetta} other {Impossibile rimuovere le etichette}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {L\'etichetta è stata assegnata} other {Le etichette sono state assegnate}}',
        failed: '{count, select, 1 {Impossibile assegnare l\'etichetta} other {Impossibile assegnare le etichette}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {L\'etichetta è stata rimossa} other {Le etichette sono state rimosse}}',
        failed: '{count, select, 1 {Impossibile rimuovere l\'etichetta} other {Impossibile rimuovere le etichette}}',
    },
    useCreateLabelMutation: {
        success: 'L\'etichetta è stata creata',
        failed: 'Creazione dell\'etichetta non riuscita',
    },
    useEditLabelMutation: {
        success: 'L\'etichetta è stata modificata',
        failed: 'Modifica dell\'etichetta non riuscita',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {L\'etichetta è stata cancellata} other {Le etichette sono state cancellate}}',
        failed: '{count, select, 1 {Impossibile cancellare l\'etichetta} other {Impossibile cancellare le etichette}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: 'Le impostazioni di notifica sono state aggiornate.',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'Impossibile pianificare l\'aggiornamento dati del sito sul server <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Impossibile pianificare l\'aggiornamento dati del sito su <b>#</b> server.} other {Impossibile pianificare l\'aggiornamento dati del sito su <b>#</b> server.}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'Impossibile pianificare la ricerca di nuovi siti di WordPress sul server <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Impossibile pianificare la ricerca di nuovi siti di WordPress su <b>#</b> server.} other {Impossibile pianificare la ricerca di nuovi siti di WordPress su <b>#</b> server.}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: 'Il server <b>{selectedServerTitle}</b> è stato disconnesso.',
        notifySeveralServers: '{selectedServersCount, plural, one {<b>#</b> server è stato disconnesso.} other {<b>#</b> server sono stati disconnessi.}}',
        notifySingleServerFail: 'Il server <b>{selectedServerTitle}</b> non è stato disconnesso.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {<b>#</b> server non è stato disconnesso.} other {<b>#</b> server non sono stati disconnessi.}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'Impossibile pianificare gli aggiornamenti del sito sul server <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Impossibile pianificare gli aggiornamenti del sito su <b>#</b> server.} other {Impossibile pianificare gli aggiornamenti del sito su <b>#</b> server.}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'Globale',
            server: 'Server',
        },
        coreLabels: {
            disabled: 'Non installare gli aggiornamenti automaticamente',
            major: 'Installa tutti gli aggiornamenti automaticamente',
            minor: 'Installa gli aggiornamenti (di sicurezza) minori automaticamente',
            notSet: 'Definito a livello di sito',
            minorDescription: 'Ad esempio, installa automaticamente la versione 4.7.1 ma non la 4.8',
            doNotTouch: 'Non modificare',
        },
        pluginsLabels: {
            disabled: 'Permetti all\'amministratore del sito di decidere quali plugin aggiornare automaticamente',
            disabledDescription: 'WordPress permette agli amministratori di attivare gli aggiornamenti automatici per i singoli plugin separatamente',
            major: 'Installa tutti gli aggiornamenti dei plugin automaticamente',
            majorDescription: 'Ignora le impostazioni di aggiornamento automatico per i singoli plugin',
            minor: 'Installa automaticamente solo gli aggiornamenti di sicurezza',
            minorDescription: 'Usa le impostazioni di aggiornamento automatico dei singoli plugin, ma installa automaticamente gli aggiornamenti se correggono una vulnerabilità nota',
            notSet: 'Definito a livello di sito',
            doNotTouch: 'Non modificare',
        },
        themesLabels: {
            disabled: 'Permetti all\'amministratore del sito di decidere quali temi aggiornare automaticamente',
            disabledDescription: 'WordPress permette agli amministratori di attivare gli aggiornamenti automatici per i singoli temi separatamente',
            major: 'Installa tutti gli aggiornamenti dei temi automaticamente',
            majorDescription: 'Ignora le impostazioni di aggiornamento automatico per i singoli temi',
            minor: 'Installa automaticamente solo gli aggiornamenti di sicurezza',
            minorDescription: 'Usa le impostazioni di aggiornamento automatico dei singoli temi, ma installa automaticamente gli aggiornamenti se correggono una vulnerabilità nota',
            notSet: 'Definito a livello di sito',
            doNotTouch: 'Non modificare',
        },
        safeUpdatesLabels: {
            notSet: 'Definito a livello di sito',
            enabled: 'Attiva aggiornamenti sicuri',
            disabled: 'Disattiva gli aggiornamenti sicuri',
            doNotTouch: 'Non modificare',
        },
        safeUpdates: {
            title: 'Aggiornamenti sicuri',
            description: 'Esegui il backup del sito prima di aggiornarlo. Se l\'aggiornamento danneggia il sito, il backup verrà ripristinato automaticamente',
            checkbox: 'Attiva aggiornamenti sicuri',
        },
        labels: {
            core: 'Core di WordPress',
            plugins: 'Plug-in',
            themes: 'Temi',
        },
        doNotTouch: 'Non modificare',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'Le impostazioni di aggiornamento saranno applicate a tutti i server presenti e futuri che non possiedono impostazioni di aggiornamento personalizzate',
        checkboxDescription: 'Applica le impostazioni anche a tutti i server e i siti che possiedono impostazioni di aggiornamento personalizzate',
    },
    DailyTaskTimeSection: {
        typeLabel: 'Pianificazione di verifica della sicurezza',
        typeOptions: {
            doNotTouch: 'Non modificare',
            unset: 'Definito dal server',
            custom: 'Ora personalizzata',
        },
        timeLabel: 'Tempo (24 ore)',
        timezoneDescription: 'Fuso orario: predefinito del server',
    },
    SettingsDrawer: {
        title: 'Impostazioni',
        subTitle: 'Impostazioni per tutti i server e i siti connessi',
        loadFailed: 'Caricamento delle impostazioni non riuscito.',
        tabs: {
            updates: 'Aggiornamenti',
            dailyTaskTime: 'Pianificazione',
            vulnerabilities: 'Vulnerabilità',
        },
        DailyTaskTime: {
            description: 'WP Guardian esamina tutti i server ogni 24 ore in cerca di nuove vulnerabilità e aggiornamenti disponibili. Essendo un\'operazione che incide molto sulle prestazioni, puoi pianificarla affinché venga eseguita a un orario preciso su tutti i server',
        },
        successToast: 'Impostazioni salvate.',
        buttonSave: 'Salva',
    },
    Operations: {
        openTaskManagerLinkText: 'Per saperne di più',
        operationsFailed: '{count, plural, one {C\'è # operazione fallita} other {Ci sono # operazioni fallite}}',
        fallback: {
            success: 'Operazione eseguita',
            failed: 'Operazione non riuscita',
        },
        servers: {
            demo: {
                success: 'È stata eseguita un\'operazione su un server dimostrativo.',
                failed: 'Operazione su server dimostrativo non riuscita.',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Abilitazione della protezione delle vulnerabilità non riuscita',
            },
            update: {
                success: '{count, plural, one {Le installazioni su # server sono state aggiornate} other {Le installazioni su # server sono state aggiornate}}',
                failed: '{count, plural, one {Aggiornamento delle installazioni su # server non riuscito} other {Aggiornamento delle installazioni su # server non riuscito}}',
            },
            refresh: {
                success: 'Aggiornamento dati del sito concluso',
                failed: 'Aggiornamento dati del sito non riuscito',
            },
            findNewSites: {
                success: '{count, plural, one {Ricerca di nuove installazioni di WordPress su # server completata} other {Ricerca di nuove installazioni di WordPress su # server completata}}',
                failed: '{count, plural, one {Ricerca di nuove installazioni di WordPress su # server non riuscita} other {Ricerca di nuove installazioni di WordPress su # server non riuscita}}',
            },
            configureUpdates: {
                success: 'Le impostazioni di aggiornamento sono state configurate',
                failed: 'Configurazione delle impostazioni di aggiornamento non riuscita',
            },
            disconnect: {
                success: '{count, plural, one {# server è stato disconnesso} other {# server sono stati disconnessi}}',
                failed: '{count, plural, one {Disconnessione di # server non riuscita} other {Disconessione di # server non riuscita}}',
            },
        },
        installations: {
            demo: {
                success: 'L\'operazione su un server dimostrativo è andata a buon fine.',
                failed: 'Operazione su server dimostrativo non riuscita.',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: 'Tutte le installazioni di WordPress selezionate sono state aggiornate',
                failed: 'Aggiornamento delle installazioni di WordPress selezionate non riuscito',
            },
            configureUpdates: {
                success: 'Le impostazioni di aggiornamento sono state modificate',
                failed: 'Modifica delle impostazioni di aggiornamento non riuscita',
            },
            detach: {
                success: 'L\'installazione di WordPress è stata separata da WP Guardian e verrà ignorata durante le successive scansioni dei server. Per rendere questa installazione visibile a WP Guardian, rimuovi il file .wp-toolkit-ignore dalla cartella d\'installazione.',
                failed: 'Separazione dell\'installazione di WordPress non riuscita',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'L\'installazione degli aggiornamenti ha corretto le vulnerabilità',
                failed: '{count, plural, one {Correzione di vulnerabilità su # installazione non riuscita} other {Correzione di vulnerabilità su # installazioni non riuscita}}',
            },
            mitigationDeactivateAsset: {
                success: 'Alcune vulnerabilità sono state mitigate tramite la disattivazione dei plugin',
                failed: '{count, plural, one {Mitigazione di vulnerabilità tramite disattivazione dei plugin su # installazione non riuscita} other {Mitigazione di vulnerabilità tramite disattivazione dei plugin su # installazioni non riuscita}}',
            },
            mitigationActivateAsset: {
                success: 'I plugin disattivati a causa delle vulnerabilità sono stati riattivati',
                failed: '{count, plural, one {Riattivazione di plugin precedentemente vulnerabili su # installazione non riuscita} other {Riattivazione di plugin precedentemente vulnerabili su # installazioni non riuscita}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'L\'installazione degli aggiornamenti ha corretto le vulnerabilità',
                failed: '{count, plural, one {Correzione di # vulnerabilità non riuscita} other {Correzione di # vulnerabilità non riuscita}}',
            },
            mitigationDeactivateAsset: {
                success: 'Alcune vulnerabilità sono state mitigate tramite la disattivazione dei plugin',
                failed: '{count, plural, one {Mitigazione di # vulnerabilità tramite disattivazione dei plugin non riuscita} other {Mitigazione di # vulnerabilità tramite disattivazione dei plugin non riuscita}}',
            },
            mitigationActivateAsset: {
                success: 'I plugin disattivati a causa delle vulnerabilità sono stati riattivati',
                failed: '{count, plural, one {Riattivazione dei plugin affetti da # vulnerabilità non riuscita} other {Riattivazione dei plugin affetti da # vulnerabilità non riuscita}}',
            },
            ignoreVulnerabilities: {
                success: 'Le vulnerabilità sono state ignorate',
                failed: '{count, plural, one {Impossibile ignorare # vulnerabilità} other {Impossibile ignorare # vulnerabilità}}',
            },
            cancelIgnoreVulnerabilities: {
                success: 'Alcune vulnerabilità non sono più ignorate',
                failed: '{count, plural, one {Impossibile annullare l\'ignoramento di # vulnerabilità} other {Impossibile annullare l\'ignoramento di # vulnerabilità}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'Avvio rapido',
        QuickStartWizard: {
            startStepName: 'Avvia',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: 'Notifiche',
            securityCheckScheduleStepName: 'Controllo di sicurezza',
            updateStepName: 'Aggiornamenti',
        },
        QuickStartStep: {
            pagination: '{current} di {total}',
            ok: 'OK',
            next: 'Avanti',
            back: 'Indietro',
        },
        StartStep: {
            title: 'Ti diamo il benvenuto in WP Guardian',
            text: 'Configura ciò che serve per mantenere al sicuro i tuoi siti di WordPress',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'Tempo di verifica della sicurezza',
            checkingTimeText: 'WP Guardian esamina tutti i server e i siti ogni 24 ore in cerca di modifiche. Si tratta di un\'operazione che incide sulle prestazioni, richiedendo diverso tempo e risorse del server.',
            vulnerabilitiesUpdateTitle: 'Aggiornamenti database delle vulnerabilità',
            vulnerabilitiesUpdateText: 'Il database delle vulnerabilità viene aggiornato automaticamente ogni ora. Questa operazione non incide sulle prestazioni del server e dei siti',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: 'Funzionamento',
            howItWorksText: 'Viene installato un plugin all\'interno di WordPress, che agisce come firewall di applicazione web. I file di WordPress e i contenuti del sito non vengono alterati, ma il plugin fa uso delle regole di firewall per intervenire sulle specifiche vulnerabilità presenti sul sito.',
            poweredBy: 'a cura di {providerLogo}',
        },
        UpdatesStep: {
            next: "Avanti",
            updateSettings: 'Impostazioni di aggiornamento',
            updateSettingsText: 'Puoi forzare gli aggiornamenti automatici su tutti i siti connessi a WP Guardian, inclusi quelli aggiunti successivamente. È possibile configurare in seguito le impostazioni su specifici siti o server.',
            backup: 'Backup pre-aggiornamento',
            backupText: 'Abilitando gli aggiornamenti sicuri, WP Guardian eseguirà un backup del sito prima di aggiornarlo. In caso di errore, verrà ripristinato automaticamente il backup.',
            safeUpdates: 'Aggiornamenti sicuri',
            customizedSettings: 'Impostazioni personalizzate',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'Registro attività',
        Toolbar: {
            buttons: {
                groupTitle: 'Pulsanti',
                allTasks: 'Tutte le attività',
                failed: 'Non riuscite',
            },
            filters: {
                groupTitle: 'Filtri',
                typeUpdate: 'Installa aggiornamenti',
                refresh: 'Aggiorna dati',
                typeFindNewSites: 'Trova nuovi siti',
                typeConfigureUpdates: 'Configura aggiornamenti',
                typeFixVulnerabilitiesViaUpdate: 'Correggi vulnerabilità tramite aggiornamento',
                mitigationDeactivateAsset: 'Mitiga vulnerabilità tramite disattivazione dei plugin',
                mitigationActivateAsset: 'Riattiva plugin disattivati a causa delle vulnerabilità',
                ignoreVulnerabilities: 'Ignora vulnerabilità',
                cancelIgnoreVulnerabilities: 'Annulla ignoramento vulnerabilità',
                setDailyTaskTime: 'Modifica pianificazione di verifica della sicurezza',
            },
            search: {
                title: 'Cerca',
                placeholder: 'Cerca',
            },
        },
        TasksList: {
            columns: {
                task: 'Attività',
                status: 'Stato',
                started: 'Avviata',
                finished: 'Terminata',
            },
            taskTypes: {
                fallBack: "Operazione con codice ''{type}''",
                demo: 'Operazione su server dimostrativo',
                update: 'Installa aggiornamenti',
                refresh: 'Aggiorna dati',
                detach: 'Dissocia',
                findNewSites: 'Trova nuovi siti',
                configureUpdates: 'Configura aggiornamenti',
                setDailyTaskTime: 'Modifica pianificazione di verifica della sicurezza',
                protect: 'Enable vulnerability protection',
                unprotect: 'Disable vulnerability protection',
                disconnect: 'Disconnetti',
                fixVulnerabilitiesViaUpdate: 'Correggi vulnerabilità tramite aggiornamento',
                changeInstallationPluginStatus: 'Cambia stato plugin',
                mitigationDeactivateAsset: 'Mitiga vulnerabilità tramite disattivazione dei plugin',
                mitigationActivateAsset: 'Riattiva plugin disattivati a causa delle vulnerabilità',
                ignoreVulnerabilities: 'Ignora vulnerabilità',
                cancelIgnoreVulnerabilities: 'Annulla ignoramento vulnerabilità',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, one {# server} other {# server}}',
                sites: '{count, plural, one {# sito} other {# siti}}',
                vulnerabilities: '{count, plural, one {# vulnerabilità} other {# vulnerabilità}}',
            },
            statuses: {
                success: 'Riuscita',
                inProgress: 'In progresso',
                failed: 'Non riuscita',
                errors: 'Errori',
                unknown: 'Sconosciuto',
            },
            emptyList: {
                title: 'Nessuna attività',
                description: 'Non è stata ancora eseguita alcuna attività.',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: 'Siti con protezione disabilitata',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {Abilita nuovamente la protezione su questo sito} other {Abilita nuovamente la protezione su questi siti}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "Non disponi di una licenza",
        trialDaysLeft: '{days, plural, one {Prova (# giorno rimasto)} other {Prova (# giorni rimasti)}}',
        trialUntil: 'Prova',
    },
    PromoCodeForm: {
        promoCode: 'Il tuo codice promozionale',
        apply: 'Applica',
        activationFailure: 'Attivazione del codice promozionale non riuscita',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: 'Applica',
            trialTitle: 'Prova di 14 giorni',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial: 'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'Scegli una licenza',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: 'Il tuo periodo di prova è iniziato!',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'See Details',
                },
                trialExpired: {
                    title: 'Il tuo periodo di prova è scaduto',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Your license has been terminated',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'Nessuna licenza',
            expired: '(scaduta)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'Condividi il tuo feedback',
            },
        },
        tabs: {
            servers: 'Server',
            installations: 'Siti di WordPress',
            vulnerabilities: 'Vulnerabilità',
        },
        Footer: {
            Links: {
                followUs: 'Seguici:',
                company: 'Società',
                aboutWebpros: 'Informazioni su WebPros',
                cookiehub: 'Impostazioni cookie',
                privacyPolicy: 'Informativa sulla privacy',
                knowledgeBase: 'Base di Conoscenza',
                documentation: 'Documentazione',
                helpCenter: 'Centro assistenza',
                contactUs: 'Contattaci',
                legal: 'Legale',
            },
            copyright: '© {year} WebPros Internazionale GmbH. Tutti i diritti riservati. WP Guardian e il logo WP Guardian sono marchi registrati di WebPros International GmbH.',
        },
        Header: {
            UpdateSettingsCard: {
                title: 'Impostazioni aggiornamento automatico',
            },
            CardLoader: {
                loading: 'Caricamento in corso...',
            },
            InstallationsCard: {
                title: 'Siti di WordPress',
                vulnerable: 'Vulnerabili: {count}',
                outdated: 'Obsoleti: {count}',
                protected: 'Protetti: {count}',
                safe: 'Ok: {count}',
                notOperable: 'Inoperabili: {count}',
                poweredBy: '<whiteSpaceNoWrap>A cura di {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: 'Vulnerabilità',
                databaseUpdate: 'Aggiornamento database',
                momentAgo: 'un attimo fa',
                noData: 'Nessun dato',
                lastUpdateMinutes: '{minutes, plural, one {# minuto fa} other {# minuti fa}}',
                lastUpdateHours: '{hours, plural, one {# ora fa} other {# ore fa}}',
                lastUpdateDays: '{days, plural, one {# giorno fa} other {# giorni fa}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: 'Punteggio CVSS più alto',
                needsProtection: '{count, plural, one {# richiede protezione} other {# richiedono protezione}}',
            },
            QuickStartCard: {
                title: 'Avvio rapido',
                ctaBlockTitle: 'Imposta la sicurezza',
                ctaBlockText: 'in pochi clic',
            },
        },
        UserMenuAddon: {
            quickStart: 'Quick start',
            license: 'Dettagli della licenza',
        },
    },
    Vulnerabilities: {
        updateByProtect: 'Non sono disponibili aggiornamenti per correggere questa vulnerabilità.',
        columns: {
            position: 'Dove',
        },
        Onboarding: {
            title: 'Nessuna vulnerabilità trovata',
            description: 'Connetti i server per trovare siti WordPress e verifica la presenza di vulnerabilità e aggiornamenti.',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: 'Mitigata',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'Mitigato da disattivazione plugin',
                tooltip: {
                    'singleSiteMitigatedByOther': 'La sicurezza del sito web affetto da questa vulnerabilità è gestita da un servizio esterno a WP Guardian.',
                    'mitigatedByOther': 'La sicurezza dei siti web affetti da questa vulnerabilità è gestita da un servizio esterno a WP Guardian',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'Origine:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'Questo record contiene materiale protetto da copyright.',
            wordfenceCopyright: 'Copyright 2012-2024 Defiant Inc. Licenza: Defiant concede all\'utente una licenza di copyright perpetua, universale, non esclusiva, esente da oneri e royalty, e irrevocabile per riprodurre, creare opere derivate, visualizzare o impiegare pubblicamente, concedere in sottolicenza e distribuire le informazioni di vulnerabilità di questo sotware. Ogni copia delle informazioni di vulnerabilità del software creata dall\'utente per uno qualsiasi di tali scopi viene autorizzata previa inclusione, all\'interno di ciascuna copia, di un collegamento ipertestuale a questo record di vulnerabilità, e una riproduzione dell\'avviso di copyright di Defiant e di questa licenza.',
            readMore: 'Più informazioni',
        },
        Toolbar: {
            groupFilterMenu: 'Filtro',
            groupSearch: 'Cerca',
            manage: 'Gestisci',
            patch: 'Protection',
            searchPlaceholder: 'Trova vulnerabilità...',
            UpdateButton: {
                update: 'Installa aggiornamenti',
                cancel: 'Annulla',
                noSelection: 'Seleziona almeno una vulnerabilità da correggere tramite aggiornamento.',
                updateConfirmation: '{totalVulnerabilities, plural, one {Stai per aggiornare più siti web per correggere # vulnerabilità} other {Stai per aggiornare più siti web per correggere # vulnerabilità}}. La procedura di aggiornamento non può essere interrotta una volta avviata.',
                updateConfirmationPluginTitle: 'Sono stati disabilitati diversi plugin',
                updateConfirmationPluginCheckbox: 'Riattiva i plugin disattivati in base alle norme di sicurezza',
                updateConfirmationPluginCheckboxHint: 'I plugin vulnerabili disattivati in base alle norme di sicurezza vengono automaticamente riattivati se le loro vulnerabilità vengono risolte tramite gli aggiornamenti.',
                numberOfSites: 'Numero di siti da aggiornare:',
                numberOfSitesUnmanaged: 'Numero di siti da aggiornare (i siti non gestiti verranno ignorati):',
                sitesCalculating: 'Calcolo in corso...',
                sitesCalculatingError: 'Conteggio non riuscito',
                affectedSites: '{count, plural, one {{count} sito} other {{count} siti}}',
                unmanagedLabel: '{count, plural, one {# non gestito} other {# non gestiti}}',
                updateByProtect: 'Non sono disponibili aggiornamenti per correggere questa vulnerabilità.',
                upgradeLicense: 'Esegui l\'upgrade della licenza',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: 'Da ignorare',
                noSelection: 'Seleziona almeno una vulnerabilità da ignorare o da non ignorare',
            },
            PatchButton: {
                affectedSites: '{count, plural, one {{count} sito} other {{count} siti}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'Annulla',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Abilita protezione',
                providerLogo: '<whiteSpaceNoWrap>A cura di {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: 'Calcolo in corso...',
                sitesCalculatingError: 'Calcolo del numero di siti coinvolti non riuscito',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, one {# non gestito} other {# non gestiti}}',
                update: 'Abilita protezione',
                upgradeLicense: 'Upgrade della licenza',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: 'Protezione necessaria',
            filterStateIgnored: 'Ignorate',
            filterComponentCore: 'Nucleo',
            filterComponentPlugin: 'Plug-in',
            filterComponentTheme: 'Tema',
            everywhere: 'Ovunque',
        },
        UpdateConfirmation: {
            description: '{count, plural, one {Stai per aggiornare # sito web.} other {Stai per aggiornare # siti web.}} La procedura di aggiornamento non può essere interrotta una volta avviata.',
        },
        Widgets: {
            disablePlugin: {
                title: 'Plug-in',
                installations: '{enabledCount, plural, one {# attivo} other {# attivi}}',
                installationsDeactivated: '{count, plural, one {Disattivato su # sito web} other {Disattivato su # siti web}}',
                confirmationDeactivation: '{count, plural, one {Stai per disattivare un plugin vulnerabile su # sito web.} other {Stai per disattivare un plugin vulnerabile su # siti web.}} La procedura non può essere interrotta una volta avviata.',
                confirmationReactivation: '{count, plural, one {Stai per riattivare un plugin precedentemente disattivato per ragioni di sicurezza su # sito web. Questa azione esporrà il sito in questione a potenziali exploit.} other {Stai per riattivare un plugin precedentemente disattivato per ragioni di sicurezza su # siti web. Questa azione esporrà i siti in questione a potenziali exploit.}} La procedura non può essere interrotta una volta avviata.',
                dropdownDeactivate: 'Disattiva',
                buttonDeactivateWithCount: 'Disattiva {count}',
                buttonReactivateWithCount: 'Riattiva {count}',
                buttonDeactivateAll: 'Disattiva tutto',
                buttonReactivate: 'Riattiva',
                tooltipAllPluginsDeactivatedByUser: 'WP Guardian non riattiva i plugin disattivati manualmente dagli amministratori di sistema.',
            },
            updates: {
                title: 'Installa aggiornamenti',
                installations: '{count, plural, one {# sito} other {# siti}}',
                waitingForUpdatesTitle: 'Ancora nessun aggiornamento',
                buttonName: 'Aggiorna tutto',
            },
            patches: {
                allProtected: '{count, plural, one {# sito} other {# siti}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'Annulla',
                enableButton: 'Abilita',
                installations: '{count} di {total}',
                protectAllSites: 'Proteggi tutti',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: 'Siti protetti',
                titleAllProtected: 'Tutti protetti',
            },
        },
        installations: '{count, plural, one {# sito} other {# siti}}',
        servers: '{count, plural, one {# server} other {# server}}',
    },
    Onboarding: {
        title: 'Nessun server connesso',
        description: 'Connetti i server per trovare siti WordPress e verifica la presenza di vulnerabilità e aggiornamenti.',
        demoServerWasAddedSuccessfully: 'È stato aggiunto un server dimostrativo.',
        connectServers: '<addServerButton>Connettiti al tuo server</addServerButton> <delimiter>o</delimiter> <addDemoServerButton>usa il nostro server dimostrativo</addDemoServerButton>',
        hint: 'Il server dimostrativo è fornito dal team di WP Guardian. Al suo interno sono disponibili diversi siti di WordPress, che ti permetteranno di esplorare facilmente le funzionalità del prodotto prima di connettere il tuo nuovo server.',
        feedbackButton: 'Lascia feedback',
        demoServerPopover: {
            description: 'Il server dimostrativo funziona in modalità sola lettura. Le operazioni invasive come l\'installazione di aggiornamenti non cambieranno lo stato dei siti ospitati sul server dimostrativo.',
            connectBtn: 'Connetti',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: 'Ricerca: {searchValue}',
        searchByServer: 'Server: {searchValue}',
        searchByVulnerability: 'Vulnerabilità: {searchValue}',
        showAll: 'Mostra tutto',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, one {# dei siti presenti sui server selezionati non è utilizzabile} other {# dei siti presenti sui server selezionati non sono utilizzabili}}. L\'operazione non sarà eseguita su tali siti poiché sono danneggiati o non disponibili.',
        state: {
            installingAgent: 'Installazione agente',
            connectionError: 'Errore di connessione',
            agentInstallationError: 'Installazione dell\'agente non completata',
            connectionBlocked: 'Connessione bloccata',
            synchronizationInProgress: 'Sincronizzazione in corso',
            connectionErrorStatusMessage: 'Non è possibile stabilire correttamente la connessione al server.',
            connectionErrorFixDescription: 'Verifica i registri e le impostazioni sul server ({ipAddress}) e attendi una nuova verifica.',
            connectionBlockedStatusMessage: 'La connessione al server è bloccata dal firewall.',
            connectionBlockedFixDescription: 'Verifica che la porta 878 sul server {ipAddress} sia aperta e attendi una nuova verifica.',
            agentInstallationErrorMessage: 'Installazione dell\'agente non completata',
            agentInstallationErrorFixDescription: "Non abbiamo ricevuto entro 5 minuti una risposta dall'agente di WP Guardian riguardo al completamento dell'installazione. Questo significa che l'installazione è ancora in corso o che non è riuscita. Verifica il progresso dell'installazione nella console del server, oppure consulta il registro d'installazione nel file '/root/wp-agent-install.log'.",
        },
        ConnectDrawer: {
            title: 'Connetti il tuo server',
            done: 'Fatto',
            tabs: {
                connectDroplet: 'Connect droplet',
                connectServer: 'Connetti server',
            },
        },
        AddDroplet: {
            videoDialogTitle: 'Connecting DigitalOcean droplet to WP Guardian',
            docsLinkText: 'Need help with connecting droplets? Check out the documentation',
            goToDoButton: 'Go to DigitalOcean to connect the droplet',
            head1: '1. Copy the following snippet',
            head2: '2. Activate the snippet in droplet console',
            paragraph1singleDroplet: 'Press the button below to open the droplet console, paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
            paragraph1multipleDroplets: 'Press the button below, choose a droplet you\'d like to connect to WP Guardian. From the detail page, click the Access tab in the left menu, then launch the droplet console and paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
        },
        AddServer: {
            done: 'Fatto',
            paragraph1: 'Su ciascun server che vuoi collegare, apri la porta 878 per le connessioni in entrata dai seguenti IP: 34.254.37.129, 52.51.23.204 e 52.213.169.7',
            paragraph2: 'Copia e incolla il seguente frammento nella console dei server e attendi il completamento della procedura d\'installazione',
            errors: {
                failedToGenerateToken: 'Impossibile generare il comando d\'installazione.',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'Copia',
            copied: 'Frammento copiato',
            refreshTooltip: 'Il comando di connessione è valido per un numero illimitato di server durante questo periodo. Aggiorna il comando se hai bisogno di più tempo.',
        },
        SettingsDrawer: {
            title: 'Impostazioni',
            subTitle: {
                single: 'Server: {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'Aggiornamenti',
                dailyTaskTime: 'Pianificazione',
            },
            loadFailed: 'Caricamento delle impostazioni non riuscito.',
            buttonSave: 'Salva',
            successToast: 'Impostazioni salvate.',
            Updates: {
                customizedSection: {
                    description: 'Le impostazioni di aggiornamento saranno applicate a tutti i siti presenti e futuri che si trovano su questo server e che non possiedono impostazioni di aggiornamento personalizzate',
                    checkboxDescription: 'Applica le impostazioni anche a tutti i siti che possiedono impostazioni di aggiornamento personalizzate',
                },
            },
            DailyTaskTime: {
                description: 'WP Guardian esamina ogni 24 ore le nuove vulnerabilità e gli aggiornamenti disponibili. Essendo un\'operazione che incide molto sulle prestazioni, puoi pianificarla affinché venga eseguita a un orario preciso',
                unsetOption: 'Definito per impostazioni globali',
            },
        },
        RemoveServersButton: {
            remove: 'Disconnetti',
            noSelectedServers: 'Seleziona almeno un sever da disconnettere.',
            removeDescription: 'L\'agente WP Guardian non viene rimosso automaticamente quando il server si disconnette. Usa lo script di disinstallazione per rimuovere l\'agente WP Guardian (<a>istruzioni</a>).',
            removeSingleServerConfirmation: 'Vuoi disconnettere {selectedServerTitle}?',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, one {Disconnettere <b>#</b> server?} other {Disconnettere <b>#</b> server?}}',
        },
        ListActions: {
            remove: 'Disconnetti',
            refresh: 'Aggiorna',
            findNewSites: 'Trova nuovi siti',
            settings: 'Impostazioni',
            disablePatch: 'Rimozione protezione',
            update: 'Aggiorna tutti i siti',
            manageLabels: 'Applica etichette',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'Annulla',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, one {# sito WP} other {# siti WP}}',
            notOperable: 'Inoperabili: {count}',
            vulnerable: 'Vulnerabili: {count}',
            vulnerabilities: 'Vulnerabilità: {count}',
            outdated: 'Obsoleti: {count}',
            lastCheck: 'Ultima verifica: {dateTime}',
            lastCheckToday: 'Ultima verifica: oggi alle {time}',
            unsupportedAgent: 'Versione dell\'agente non supportata',
            coreOutdated: 'Obsoleto',
        },
        columns: {
            server: 'Server',
        },
        OutdatedAgentError: {
            message: 'La versione dell\'agente WP Guardian (wp-agent) sul server è obsoleta',
            agentVersion: '{version, select, _ {La versione del tuo agente WP Guardian non è disponibile.} other {La versione del tuo agente WP Guardian è {version}.}}',
            updateDescription: 'Aggiorna il pacchetto wp-agent alla versione {wpAgentMinSupportedVersion} o superiore.',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'Impostazioni di aggiornamento',
            ipAddress: '<b>IP server:</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'Filtro',
            manage: 'Gestisci',
            remove: 'Disconnetti',
            add: 'Connetti',
            groupFilter: 'Filtro',
            groupSearch: 'Cerca',
            searchPlaceholder: 'Trova server...',
            noSelectedServers: 'Seleziona almeno un sever da disconnettere.',
            FilterPanel: {
                filterAlerts: 'Avvisi',
                labelsPlaceholder: 'Tutte le etichette',
            },
            RefreshButton: {
                buttonText: 'Aggiorna',
                noSelectedServers: 'Seleziona almeno un server da aggiornare.',
            },
            FindNewSitesButton: {
                buttonText: 'Trova nuovi siti',
                noSelectedServers: 'Seleziona almeno un server per trovare nuovi siti WordPress.',
            },
            UpdateButton: {
                buttonText: 'Installa aggiornamenti',
                confirmButtonText: 'Aggiorna tutto',
                noSelectedServers: 'Seleziona almeno un server per installare tutti gli aggiornamenti disponibili su tutti i siti WordPress.',
                updateSingleServerConfirmation: 'Tutti gli aggiornamenti disponibili saranno installati su tutti i siti WordPress situati sul server <b>{selectedServerTitle}</b>.',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, one {Tutti gli aggiornamenti disponibili saranno installati su tutti i siti di WordPress che si trovano su <b>#</b> server selezionato.} other {Tutti gli aggiornamenti disponibili saranno installati su tutti i siti di WordPress che si trovano su <b>#</b> server selezionati.}}',
            },
            ProtectButton: {
                buttonText: 'Proteggi',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'Applica etichette',
            },
            SettingsButton: {
                buttonText: 'Impostazioni',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: 'Connetti',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: 'Ignora automaticamente le vulnerabilità a basso rischio',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: 'Ignora automaticamente le vulnerabilità a basso rischio',
            warningMessage: 'Disattivando questa impostazione, tutte le etichette "Ignora automaticamente" saranno rimosse e tutte le vulnerabilità ignorate in automatico saranno nuovamente visualizzate',
        },
    },
    Installations: {
        yes: 'Sì',
        no: 'No',
        update: 'Installa aggiornamenti',
        refresh: 'Aggiorna',
        detach: 'Dissocia',
        updateSettings: 'Impostazioni di aggiornamento',
        sitesAreNotOperable: '{notOperable, plural, one {# dei siti selezionati non è attualmente utilizzabile} other {# dei siti selezionati non sono attualmente utilizzabili}}. L\'operazione non sarà eseguita su tali siti poiché sono danneggiati o non disponibili.',

        DetachInstallationsButton: {
            detach: 'Dissocia',
            detachSingleInstallationConfirmation: 'Dissociare <b>{selectedInstallationTitle}</b> da WP Guardian? Le installazioni dissociate saranno ignorate dalle successive scansioni del server.',
            detachSeveralInstallationsConfirmation: 'Dissociare <b>{selectedInstallationsCount}</b> installazioni da WP Guardian? Le installazioni dissociate saranno ignorate dalle successive scansioni del server.',
        },
        Onboarding: {
            title: 'Nessun sito WordPress trovato',
            description: 'Connetti i server per trovare siti di WordPress e verifica la presenza di vulnerabilità e componenti obsoleti.',
        },
        columns: {
            website: 'Sito',
            server: 'Server',
            synchronized: 'Connesso',
            lastCheck: 'Ultima verifica',
            protect: 'Proteggi',
            siteHealth: 'Salute del sito',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'Menù filtri',
                filterAll: 'Tutte',
                filterVulnerable: 'Vulnerabile',
                filterOutdated: 'Obsoleti',
                filterNotOperable: 'Inoperabile',
                filterProtected: 'Protetto',
                filterUnmanaged: 'Non gestiti',
                filterNotProtected: 'Non protetti',
                filterSafe: 'Nessun problema',
            },
            labelsPlaceholder: 'Tutte le etichette',
        },
        Toolbar: {
            groupFilterMenu: 'Filtro',
            groupSearch: 'Cerca',
            searchPlaceholder: 'Trova il sito...',
            groupActions: 'Azioni',
            groupUpdate: 'Installa aggiornamenti',
            groupProtect: 'Proteggi',
            subGroupActions: 'Azioni',
            buttons: {
                update: {
                    text: 'Installa aggiornamenti',
                    noSelection: 'Seleziona almeno un sito su cui installare tutti gli aggiornamenti disponibili.',
                },
                refresh: {
                    text: 'Aggiorna',
                    noSelection: 'Seleziona almeno un sito da aggiornare.',
                },
                protect: {
                    text: 'Proteggi',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'Applica etichette',
                },
                settings: {
                    text: 'Impostazioni',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: 'Aggiorna <b>{selectedInstallationTitle}</b>?',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, one {Stai per aggiornare <b>#</b> sito di WordPress} other {Stai per aggiornate <b>#</b> siti di WordPress}}',
                confirmButton: 'Aggiorna',
                cancelButton: 'Annulla',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: 'Proteggi',
                cancelButton: 'Annulla',
            },
        },
        SiteHealthColumn: {
            outdated: 'Obsoleti: {count}',
            safe: 'OK',
            notOperable: 'Inoperabile',
            unsupportedAgent: 'Versione dell\'agente non supportata',
            vulnerabilities: 'Vulnerabilità: {count}',
            lastCheckAtTooltip: 'Ultima verifica: {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: 'Impostazioni salvate.',
            title: 'Impostazioni di aggiornamento',
            subTitle: {
                single: 'Sito web: {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: '{notOperableCount, plural, one {# sito inoperabile} other {# siti inoperabili}} e {unmanagedCount, plural, one {# sito non gestito} other {# siti non gestiti}} saranno ignorati.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, one {# sito non gestito verrà ignorato} other {# siti non gestiti verranno ignorati} }.',
            },
            buttonSave: 'Salva',
            confirmationPopover: {
                confirmBtn: 'Salva',
                description: {
                    single: 'Stai per modificare le impostazioni di aggiornamento del sito di WordPress {installationName}. Vuoi continuare?',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'Aggiornamenti',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Enable Vulnerability Protection',
        },
        InstallationSummary: {
            updateAll: 'Aggiorna tutto',
            updateSettings: 'Impostazioni di aggiornamento',
            upToDate: 'Aggiornato',
            AssetSummary: {
                plugin: {
                    title: 'Plugin [{count}]',
                    update: 'Installa aggiornamenti dei plug-in',
                },
                theme: {
                    title: 'Temi [{count}]',
                    update: 'Installa aggiornamenti dei temi',
                },
                safe: 'OK',
                vulnerable: 'Vulnerabili: {count}',
                outdated: 'Obsoleti: {count}',
                vulnerabilities: 'Vulnerabilità: {count}',
                isOutdated: 'Obsoleto',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: 'Aggiorna a {version}',
                updateSettingsTitle: 'Impostazioni di aggiornamento',
                vulnerabilities: '{count, plural, one {# vulnerabilità} other {# vulnerabilità}}',
                isOutdated: 'Obsoleta',
            },
            unsupportedAgentError: 'La versione del wp-agent sul server è obsoleta. La versione minima richiesta è {wpAgentMinSupportedVersion}',
            notOperableUnknownError: 'Si è verificato un errore sconosciuto durante la scansione delle vulnerabilità dei siti.',
            hostname: 'Host name server:',
            ipAddress: 'IP server:',
            UpdateConfirmation: {
                title: 'Conferma aggiornamento',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'Annulla',
                continueButton: 'Aggiorna',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Vulnerability protection by <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: 'La protezione delle vulnerabilità è disabilitata',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: 'Enable protection',
            },
            ProtectInfoPopover: {
                label: 'La vulnerabilità è ancora attiva?',
                message: 'Per neutralizzare una vulnerabilità sono richieste specifiche regole di protezione. Ecco i motivi per cui delle regole specifiche per una data vulnerabilità potrebbero non essere disponibili:',
                noPatch: {
                    title: 'In lavorazione',
                    description: 'Le regole per le vulnerabilità ad alto impatto sono di solito disponibili entro poche ore dalla scoperta. Creare delle regole per le vulnerabilità a basso impatto potrebbe richiedere più tempo.',
                },
                unlikelyExploited: {
                    title: 'Basso rischio',
                    description: 'Alcune vulnerabilità hanno un impatto minimo o non presentano metodi realistici che permettano di sfruttarle. Non rappresentando una vera minaccia per i siti web, simili vulnerabilità non richiedono regole di protezione.',
                },
                dbMatch: {
                    title: 'Non presente in database',
                    description: 'Le vulnerabilità presenti solo nel database di Wordfence o che non hanno corrispondenze nel database di Patchstack non ricevono regole di protezione.',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'faccelo sapere',
            },
        },
        WebsiteDrawer: {
            title: 'Dettagli dei siti WordPress',
            titleUpdateInProgress: 'Aggiornamento di {title}',
            subtitleUpdateInProgress: 'Aggiornamento degli elementi selezionati',
            errors: {
                installationNotFound: "Impossibile caricare le informazioni per l'installazione con ID {id}",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'Annulla',
                        updateButton: 'Aggiorna',
                        description: '{count, plural, one {Stai per correggere # vulnerabilità tramite aggiornamento su <b>{website}</b>.} other {Stai per correggere # vulnerabilità tramite aggiornamento su <b>{website}</b>.}} La procedura di aggiornamento non può essere interrotta una volta avviata.',
                        updateConfirmationPluginCheckbox: '{count, plural,one {Riattiva il plugin disabilitato in base alle norme di sicurezza} other {Riattiva i plugin disabilitati in base alle norme di sicurezza}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, one {Il plugin vulnerabile disabilitato in base alle norme di sicurezza sarà automaticamente riattivato una volta corretta la vulnerabilità tramite aggiornamento.} other {I plugin vulnerabili disabilitati in base alle norme di sicurezza saranno automaticamente riattivati una volta corrette le vulnerabilità tramite aggiornamento.}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'Azioni',
                            buttons: {
                                update: 'Aggiorna',
                                processing: 'Elaborazione',
                            },
                        },
                        filters: {
                            title: 'Filtri',
                            groupFiltersTitle: 'Filtro',
                            search: {
                                title: 'Cerca',
                                placeholder: 'Trova vulnerabilità...',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: 'Protezione necessaria',
                                filterStateIgnored: 'Ignorate',
                                filterComponentHeader: 'Dove',
                            },
                        },
                    },
                    title: 'Vulnerabilità',
                    columns: {
                        component: 'Dove',
                    },
                    emptyList: {
                        title: 'Tutto è protetto',
                        description: 'Nessuna vulnerabilità nota trovata su questo sito web.',
                    },
                    waitingForUpdate: 'Nessun aggiornamento disponibile',
                    updateTo: 'Aggiorna a {availableVersion}',
                    update: 'Aggiorna',
                    disablePlugin: 'Disattiva plugin',
                    enablePlugin: 'Attiva plugin',
                    updateByProtect: 'Non sono disponibili aggiornamenti per correggere questa vulnerabilità.',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, one {Stai per aggiornare # risorsa su <b>{website}</b>.} other {Stai per aggiornare # risorse su <b>{website}</b>.}} La procedura di aggiornamento non può essere interrotta una volta avviata.',
                        cancelButton: 'Annulla',
                        updateButton: 'Aggiorna',
                    },
                    title: 'Obsoleto',
                    toolbar: {
                        actions: {
                            title: 'Azioni',
                            buttons: {
                                update: 'Aggiorna',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'Filtri',
                            groupSearchTitle: 'Cerca',
                            search: {
                                title: 'Cerca',
                                placeholder: 'Ricerca di componenti obsoleti...',
                            },
                        },
                    },
                    columns: {
                        name: 'Nome',
                        type: 'Dove',
                    },
                    emptyList: {
                        title: 'Tutto è aggiornato',
                        description: 'Su questo sito sono installate le ultime versioni del core WordPress, dei plugin e dei temi.',
                    },
                    updateTo: 'Aggiorna a {version}',
                },
            },
        },
        Actions: {
            enableProtectButton: 'Proteggi',
            disableProtectButton: 'Rimozione protezione',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'Applica etichette',
        },
        DisableProtectionButton: {
            confirmButton: 'Rimozione protezione',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: 'Notifiche',
        listEmptyTitle: 'Resta aggiornato sugli eventi più importanti',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'Segna tutto come già letto',
        deleteNotification: 'Elimina questa notifica',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: 'Nuova licenza attiva',
                licenseLimitReached: 'Limite siti web raggiunto',
                licenseExpired: 'Licenza scaduta',
                licenseTerminated: 'Licenza revocata',
                trialCreated: 'Prova iniziata',
                trialExpired: 'Prova scaduta',
                trialExpiredSoon: 'La prova scade il giorno {expirationDate}',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: 'La tua nuova chiave di licenza per {licenseName} #{licenseKey} è ora attiva.',
                licenseLimitReached: '{limit, plural, one {La tua licenza per WP Guardian permette di gestire # sito,} other {La tua licenza per WP Guardian permette di gestire # siti,}} {total, plural, one {ma al momento hai # sito connesso.} other {ma al momento hai # siti connessi.}} Fai l\'upgrade della tua licenza per gestire tutti i tuoi siti.',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'Il tuo periodo di prova di WP Guardian è iniziato. Avrai accesso completo a tutte le funzioni di WP Guardian per tutta la durata della prova, su un massimo di {sitesLimit, plural, one {# sito web} other {# siti web}}.',
                trialExpired: "Il tuo periodo di prova di WP Guardian è terminato. Seleziona e acquista una licenza per continuare a usare il prodotto, oppure dicci cosa non ti ha convinto tramite il modulo di feedback qui sopra.",
                trialExpiredSoon: 'Il tuo periodo di prova di WP Guardian scade il giorno {expirationDate}. Considera l\'acquisto di una licenza per continuare a usare il prodotto.',
            },
            markAsRead: 'Segna come già letto',
            markAsUnread: 'Segna come non letto',
        },
        settings: 'Impostazioni',
    },
    ConfirmNotificationsEmail: {
        title: 'Conferma e-mail di notifica',
        error: 'Il collegamento è scaduto',
        successMessage: 'L\'e-mail di notifica è cambiata.',
    },
    NotificationsSettingsDrawer: {
        title: 'Impostazioni di notifica',
        Content: {
            sendSectionTitle: 'Dove inviarmi le notifiche',
            email: 'E-mail',
        },
    },
    NotificationSettingsEventSection: {
        title: 'Notifiche da inviare al di fuori del prodotto',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'Notifiche sulla licenza',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: 'Cambia l\'e-mail per le notifiche',
        buttonSave: 'Invia e-mail',
        buttonCancel: 'Annulla',
        buttonEdit: 'Modificare',
        email: 'E-mail',
        description: 'Invieremo un\'e-mail con le istruzioni su come aggiornare l\'indirizzo e-mail utilizzato per la ricezione delle notifiche.',
        successMessage: 'Abbiamo inviato un\'e-mail all\'indirizzo <b>{email}</b>. Segui le istruzioni presenti nell\'e-mail per verificare il tuo nuovo indirizzo e-mail.',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: 'Rilevazione vulnerabilità',
            description: 'Aggiornamenti in tempo reale riguardanti nuove vulnerabilità di WordPress sui siti collegati',
        },
        secureSites: {
            title: 'Sicurezza per i siti di WordPress',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: 'Aggiornamenti automatici',
            description: 'Impostazioni di aggiornamento automatico flessibili disponibili per server e siti web',
        },
        safeUpdates: {
            title: 'Aggiornamenti sicuri',
            description: 'I backup pre-aggiornamento forniscono un ripristino automatico nel caso in cui l\'aggiornamento non vada a buon fine',
        },
        notifications: {
            title: 'Stato di salute e notifiche',
            description: 'Resta sempre informato sullo stato dei tuoi siti di WordPress',
        },
    },
    LicensePage: {
        benefits: {
            title: 'Verifica della sicurezza dei siti web di WordPress',
        },
        ExpirationLabel: {
            noLicense: "Non disponi di una licenza",
            expiredLicense: 'La tua licenza è scaduta',
        },
        LicenseHeading: {
            welcome: 'Ti diamo il benvenuto in WP Guardian',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'Scegli la tua licenza',
            trial: 'Scegli la tua licenza',
            trialLicenseExpired: 'Il tuo periodo di prova è terminato',
        },
        LicenseDescription: {
            licenseKey: 'Chiave di licenza n. #{number}',
            nextUpdate: 'Prossimo aggiornamento della chiave di licenza: {date}',
            expirationDate: 'Data di scadenza: {date}',
            noLicense: 'La versione gratuita di WP Guardian include solo le informazioni sulle vulnerabilità e gli aggiornamenti manuali dei siti. Acquista una licenza per accedere a tutte le funzioni di WP Guardian.',
            trialJustStarted: 'Durante il periodo di prova, hai accesso a tutte le funzionalità offerte da WP Guardian. Alla fine della prova, ti verrà consigliata una licenza adatta ai tuoi bisogni, e avrai l\'opzione di continuare a usare WP Guardian gratuitamente con alcune limitazioni.',
            trial: '{sites, plural, one {Puoi provare il prodotto per un tempo limitato, durante il quale puoi gestire fino a # sito di WordPress.} other {Puoi provare il prodotto per un tempo limitato, durante il quale puoi gestire fino a # siti di WordPress.}} Acquista una licenza se vuoi continuare a usare tutte le funzionalità presenti e future di WP Guardian.',
            limit: '{sites, plural, one {La tua licenza permette di gestire solo # sito di WordPress.} other {La tua licenza permette di gestire fino a # siti di WordPress.}}',
            unlimited: 'La tua licenza ti consente di gestire un numero illimitato di siti di WordPress. {sites, plural, one {Il prezzo della licenza di base include la gestione di # sito.} other {Il prezzo della licenza di base include la gestione di # siti.}} Tutti i siti che eccedono tale limite vengono fatturati su base Pay-As-You-Go.',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: 'Vedi dettagli',
            nextBillingDate: 'Data di fatturazione successiva: {date}',
            subscriptionCancelled: 'Subscription is canceled.',
        },
        CurrentUsage: {
            youAreUsingNow: 'Sito attuale:',
            sitesTotal: '{sites, plural, one {# sito web in totale} other {# siti web in totale}}',
            allowedToManage: '{sites, plural, one {# sito web gestibile} other {# siti web gestibili}}',
            outOfLimit: '{sites, plural, one {# sito web oltre il limite di licenza} other {# siti web oltre il limite di licenza}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Unlimited',
                label: 'Per più di 1000 siti',
                feature1: '10 mila siti inclusi',
                feature2: 'Paga in base al consumo per i siti che eccedono il limite di 10 mila',
                feature3: 'Tutte le funzionalità incluse',
                pricePerSite: '{price, number, ::currency/USD} for each site above 10k',
            },
            standard: {
                title: 'Standard',
                label: 'Per non più di 1000 siti',
                feature1: 'Paga prima dell\'uso',
                feature2: 'Tutte le funzionalità incluse',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'Usa questo piano',
            changePlan: 'Cambia piano',
            chooseAnotherOption: 'Scegli un\'altra opzione per eseguire l\'upgrade del tuo piano',
        },
        LicenseList: {
            columns: {
                license: 'Licenza',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: 'Acquista',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: 'Riattiva',
            details: 'Details',
        },
        chooseLicenseNow: 'Scegli la tua licenza',
        licensesTitle: 'Seleziona la licenza adatta alle tue esigenze',
        faq: {
            title: 'Domande frequenti',
            question1: {
                question: 'Cosa succede dopo la scadenza del periodo di prova?',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: 'Cosa succede se raggiungo i limiti della mia licenza?',
                answer: 'I siti aggiunti a WP Guardian dopo il raggiungimento del limite di licenza saranno contrassegnati come non gestiti. Tali siti continueranno ad avere informazioni aggiornate sulle vulnerabilità e potranno essere aggiornati manualmente, ma non avranno a disposizione tutte le altre funzioni di WP Guardian.',
            },
            question4: {
                question: 'Come funziona Pay As You Go?',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'Puoi ottenere una licenza in <kaLink>Key Administrator</kaLink>, in <manage2Link>Manage2</manage2Link> o dal tuo rappresentante alle vendite.',
            standard: {
                title: '{sites, plural, one {Vuoi scegliere una licenza standard per # sito} other {Vuoi scegliere una licenza standard per # siti}}',
                licenseLimit: '{sites, plural, one {La licenza include solo # sito} other {La licenza include solo # siti}}',
                feature1: 'La licenza standard include un numero specifico di siti senza capacità Pay-As-You-Go.',
                feature2: 'Tutti i siti web aggiunti dopo il raggiungimento del limite di licenza saranno contrassegnati come Non gestiti.',
                feature3: 'I siti web non gestiti non possono essere amministrati tramite WP Guardian senza un aggiornamento della licenza.',
                feature4: 'Se il limite di licenza viene aumentato o se la licenza attuale ottiene uno slot gratuito, i siti non gestiti cambiano stato e passano tra quelli gestiti.',
                feature5: 'Se un sito web rimane connesso a WP Guardian per più di qualche giorno, viene aggiunto al conteggio d\'uso della licenza.',
                feature6: 'I siti web danneggiati o non disponibili non vengono considerati nel conteggio del limite di licenza.',
            },
            unlimited: {
                title: 'Hai scelto una licenza illimitata',
                feature1: 'La licenza illimitata include 10.000 siti web nel suo prezzo di riferimento.',
                feature2: 'Tutti i siti web che eccedono il limite di 10.000 vengono fatturati su base Pay-As-You-Go.',
                feature3: 'Se un sito web rimane connesso a WP Guardian per più di qualche giorno, viene aggiunto al conteggio d\'uso della licenza.',
                feature4: 'I siti web danneggiati o non disponibili non vengono considerati nel conteggio del limite di licenza.',
            },
        },
        UpgradeButton: {
            upgrade: 'Upgrade',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'Tutte le funzionalità incluse',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'Acquista la licenza',
            upgradeLicense: 'Esegui l\'upgrade della licenza',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'Torna alla schermata home',
    },
    LabelsDrawer: {
        title: 'Gestisci etichette',
        LabelsList: {
            create: 'Crea etichetta',
            edit: 'Modificare',
            delete: 'Elimina',
            noSelectedLabels: 'Seleziona almeno un\'etichetta da eliminare.',
            columns: {
                title: 'Etichetta',
            },
            search: {
                title: 'Trova',
                placeholder: 'Trova etichetta',
            },
            emptyList: {
                title: 'Crea un\'etichetta',
                description: "Non hai nessuna etichetta a disposizione per differenziare i tuoi server e i tuoi siti. Vuoi metterne qualcuno in risalto?",
            },
            filteredEmptyList: {
                title: 'Impossibile trovare elementi corrispondenti al filtro',
                description: 'Modifica il testo dell\'etichetta che stai cercando e riprova',
            },
        },
        DeleteLabelsPopover: {
            delete: 'Elimina',
            confirmation: '{count, select, 1 {Eliminare l\'etichetta la rimuoverà da tutti gli oggetti a cui è stata applicata.} other {Eliminare le etichette le rimuoverà da tutti gli oggetti a cui sono state applicate.}} Vuoi continuare?',
        },
        CreateLabelDrawer: {
            title: 'Crea etichetta',
        },
        EditLabelDrawer: {
            title: 'Modifica etichetta',
            notFound: 'Etichetta non trovata',
        },
        LabelForm: {
            save: 'Salva',
            cancel: 'Annulla',
            fields: {
                title: {
                    label: 'Nome etichetta',
                    description: 'Massimo 30 simboli',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'AGGIUNGI ETICHETTA',
        noSelection: 'Seleziona almeno un elemento della lista per aggiungere o rimuovere le etichette.',
        buttons: {
            apply: 'Applica',
            create: 'Crea nuova etichetta',
            manage: 'Gestisci etichette',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'tutte',
            tooltip: 'Gli aggiornamenti sicuri sono attivi',
            components: {
                core: 'nucleo',
                themes: 'temi',
                plugins: 'plug-in',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'aggiornamenti di sicurezza',
                major: 'tutti gli aggiornamenti',
                disabled: "non installare",
                notSet: 'a livello di sito',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, one {La tua licenza include # sito in gestione} other {La tua licenza include # siti in gestione}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, one {# sito non gestito verrà ignorato} other {# siti non gestiti verranno ignorati}}',
        operableNotManagedSitesMessage: 'I siti non gestiti vengono ignorati da WP Guardian poiché superano il limite di licenza, e sono esclusi durante l\'esecuzione delle azioni di massa. ',
        notOperableNotManagedSitesTitle: '{notOperableCount, plural, one {# sito inoperabile} other {# siti inoperabili}} e {unmanagedCount, plural, one {# sito non gestito} other {# siti non gestiti}} saranno ignorati',
        notOperableNotManagedSitesMessage: 'L\'operazione selezionata non verrà eseguita su questi siti poiché sono inoperabili o superano il limite della tua licenza. I siti web di questo tipo vengono ignorati quando si eseguono azioni di massa.',
        managedSitesWillBePerformed: 'L\'operazione selezionata verrà eseguita senza restrizioni su tutti i siti gestiti.',
        chooseLicenseButton: 'Scegli una licenza',
        LicenseTerminatedDialog: {
            title: 'Your license is terminated',
            message: 'Ottieni una licenza se desideri continuare a usare le funzionalità più avanzate di WP Guardian.',
            chooseLicenseButton: 'Scegli una licenza',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'Aggiornamenti definiti dal sito',
            autoupdate: 'Aggiornamenti automatici',
            manual: 'Aggiornamenti manuali',
            custom: 'Aggiornamenti personalizzati',
        },
        unmanaged: {
            title: 'Non gestiti',
            tooltip: 'I siti non gestiti vengono ignorati da WP Guardian perché eccedono il limite della licenza. I siti contrassegnati come non gestiti a causa dei limiti di licenza continueranno ad avere informazioni aggiornate sulle loro vulnerabilità e potranno essere aggiornati solo manualmente. Tali siti diventeranno gestiti se viene incrementato il limite di licenza o se diventa disponibile uno slot libero della licenza corrente.',
        },
        menu: {
            remove: 'Elimina',
            filter: 'Filtra per questa etichetta',
        },
    },
    Cuttable: {
        showMore: 'Mostra altro',
        showLess: 'Mostra meno',
    },
};