// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'Label with such name already exists',
        invitationNotExist: 'Invitation does not exist.',
        wpApiClientUnreachable: 'This server or site appears to be offline right now. Please check if your server or site is accessible and try again. If this server or site is working properly and there seem to be no issues with its connection to WP Guardian, please contact our support.',
    },
    termsOfUseLink: 'WP Guardian Terms of Use',
    NotFoundPage: {
        title: 'Page Not Found',
        description: "Sorry, the requested page could not be found. It's possible that you followed an incorrect link or the page has been deleted.",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'Confirm canceling of license activation',
            description: 'You are about to cancel the activation of {product} license. You can activate this license any time later by following the activation link again. If the license is purchased for another account, log in to that account and follow the activation link.',
            cancelButton: 'Cancel',
            continueButton: 'Continue activation',
        },
        SecurityDashboardActivateLicense: {
            title: 'Confirm activation of WP Guardian license',
            description: 'Confirm the activation of the <b>{product}</b> license for the following account: <mailto>{userEmail}</mailto>. The license can be activated only for one account.',
            fixableErrorDescription: 'Something went wrong during WP Guardian license activation. Please try again or submit a support request.',
            nonFixableErrorDescription: 'Something went wrong during WP Guardian license activation. Please submit a support request.',
            loaderText: 'Your WP Guardian account is being configured',
            redirectText: 'Redirecting you to WP Guardian',
            confirmButton: 'Confirm',
            cancelButton: 'Cancel',
            retryButton: 'Retry',
            gotItButton: 'Got it',
            errors: {
                linkError: 'Activation link is invalid.',
                unknownError: 'Internal activation service error.',
            },
        },
    },
    Filters: {
        all: 'All',
    },
    FiltersForm: {
        title: 'Filter',
        clear: 'Clear',
        apply: 'Apply filter',
    },
    Invitation: {
        activationFailure: 'Failed to activate the invitation link',
    },
    UnderConstructionPopover: {
        title: 'Under construction',
        description: "Sorry, we're still working on this!",
    },
    RiskRankTitle: {
        title: 'Risk',
        description: 'Risk rank is an aggregate rating of vulnerability impact. It is based on CVSS rating, EPSS rating, Patchstack Patch Priority and other factors.',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'This vulnerability is actively exploited already, immediate reaction is strongly advised.',
        highRisk: 'This is a High risk vulnerability that should be addressed as soon as possible',
        mediumRisk: 'This is a Medium risk vulnerability that does not require urgent intervention, but should be addressed eventually',
        lowRisk: 'This is a Low risk vulnerability that can be safely ignored',
        cvss: 'CVSS: {score}',
        emptyCvss: 'N/A',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Protection disabled',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: 'Ignored',
        },
        ignoredDueToLowRisk: {
            title: 'Automatically ignored',
        },
        doNotIgnoreAutomatically: {
            title: 'Do not ignore automatically',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Unlimited',
    },
    taskTypes: {
        scanForNewSites: 'Finding new WordPress sites',
        refreshInstallations: 'Refreshing',
        detachInstallations: 'Detaching',
        updateInstallation: 'Updating',
        massUpdateInstallations: 'Updating',
        applyVirtualPatches: 'Enable vulnerability protection',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'Saving update settings',
        clearInstallationCache: 'Clearing installation cache',
        checkVulnerability: 'Checking for vulnerabilities',
        mitigateVulnerabilities: 'Mitigating vulnerabilities',
        setDailyTaskStartTime: 'Changing security check schedule',
    },
    installationComponents: {
        core: 'Core',
        plugin: 'Plugin',
        theme: 'Theme',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {Label was assigned} other {Labels were assigned}}',
        failed: '{count, select, 1 {Failed to assign label} other {Failed to assign labels}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {Label was removed} other {Labels were removed}}',
        failed: '{count, select, 1 {Failed to remove label} other {Failed to remove labels}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {Label was assigned} other {Labels were assigned}}',
        failed: '{count, select, 1 {Failed to assign label} other {Failed to assign labels}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {Label was removed} other {Labels were removed}}',
        failed: '{count, select, 1 {Failed to remove label} other {Failed to remove labels}}',
    },
    useCreateLabelMutation: {
        success: 'Label was created',
        failed: 'Failed to create label',
    },
    useEditLabelMutation: {
        success: 'Label was modified',
        failed: 'Failed to modify label',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {Label was deleted} other {Labels were deleted}}',
        failed: '{count, select, 1 {Failed to delete label} other {Failed to delete labels}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: 'Notification settings were updated.',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'Unable to schedule site data refresh on server <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule site data refresh on <b>#</b> server.} other {Unable to schedule site data refresh on <b>#</b> servers.}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'Unable to schedule search for new WordPress sites on server <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule search for new WordPress sites on <b>#</b> server.} other {Unable to schedule search for new WordPress sites on <b>#</b> servers.}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: 'Server <b>{selectedServerTitle}</b> was disconnected.',
        notifySeveralServers: '{selectedServersCount, plural, one {<b>#</b> server was disconnected.} other {<b>#</b> servers were disconnected.}}',
        notifySingleServerFail: 'Server <b>{selectedServerTitle}</b> was not disconnected.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {<b>#</b> server was not disconnected.} other {<b>#</b> servers were not disconnected.}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'Unable to schedule site updates on server <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule site updates on <b>#</b> server.} other {Unable to schedule site updates on <b>#</b> servers.}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'Global',
            server: 'Server',
        },
        coreLabels: {
            disabled: 'Do not install any updates automatically',
            major: 'Install all updates automatically',
            minor: 'Install minor (security) updates automatically',
            notSet: 'Defined on site level',
            minorDescription: 'For example, automatically install 4.7.1, but not 4.8',
            doNotTouch: 'Do not change',
        },
        pluginsLabels: {
            disabled: 'Let site admin decide which plugins to autoupdate',
            disabledDescription: 'WordPress allows site admins to enable autoupdates for each plugin separately',
            major: 'Install all plugin updates automatically',
            majorDescription: 'Ignore individual plugin autoupdate settings for each plugin',
            minor: 'Install only security updates automatically',
            minorDescription: 'Use individual plugin autoupdate settings, but autoinstall updates if they fix a known vulnerability',
            notSet: 'Defined on site level',
            doNotTouch: 'Do not change',
        },
        themesLabels: {
            disabled: 'Let site admin decide which themes to autoupdate',
            disabledDescription: 'WordPress allows site admins to enable autoupdates for each theme separately',
            major: 'Install all theme updates automatically',
            majorDescription: 'Ignore individual theme autoupdate settings for each theme',
            minor: 'Install only security updates automatically',
            minorDescription: 'Use individual theme autoupdate settings, but autoinstall updates if they fix a known vulnerability',
            notSet: 'Defined on site level',
            doNotTouch: 'Do not change',
        },
        safeUpdatesLabels: {
            notSet: 'Defined on site level',
            enabled: 'Enable safe updates',
            disabled: 'Disable safe updates',
            doNotTouch: 'Do not change',
        },
        safeUpdates: {
            title: 'Safe Updates',
            description: 'Back up the site before updating it. If an update breaks the site, backup will be restored automatically',
            checkbox: 'Enable safe updates',
        },
        labels: {
            core: 'WordPress core',
            plugins: 'Plugins',
            themes: 'Themes',
        },
        doNotTouch: 'Do not change',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'Update settings will be applied to all newly added and current servers without customized update settings',
        checkboxDescription: 'Also apply settings to all servers and sites with customized update settings',
    },
    DailyTaskTimeSection: {
        typeLabel: 'Security check schedule',
        typeOptions: {
            doNotTouch: 'Do not change',
            unset: 'Defined by server',
            custom: 'Custom time',
        },
        timeLabel: 'Time (24-hour)',
        timezoneDescription: 'Timezone: default server time zone',
    },
    SettingsDrawer: {
        title: 'Settings',
        subTitle: 'Settings for all connected servers and sites',
        loadFailed: 'Failed to load settings.',
        tabs: {
            updates: 'Updates',
            dailyTaskTime: 'Schedule',
            vulnerabilities: 'Vulnerabilities',
        },
        DailyTaskTime: {
            description: 'WP Guardian checks all servers for new vulnerabilities and available updates every 24 hours. This is a performance-intensive operation, so you can schedule it to run at a particular time on all servers by default',
        },
        successToast: 'Settings were saved.',
        buttonSave: 'Save',
    },
    Operations: {
        openTaskManagerLinkText: 'Learn more',
        operationsFailed: '{count, plural, one {You have # failed operation} other {You have # failed operations}}',
        fallback: {
            success: 'Operation was performed',
            failed: 'Failed to perform operation',
        },
        servers: {
            demo: {
                success: 'Operation on a demo server was performed.',
                failed: 'Operation on a demo server failed.',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Failed to enable vulnerability protection',
            },
            update: {
                success: '{count, plural, one {Installations on # server were updated} other {Installations on # servers were updated}}',
                failed: '{count, plural, one {Failed to update installations on # server} other {Failed to update installations on # servers}}',
            },
            refresh: {
                success: 'Site data refresh finished',
                failed: 'Failed to refresh site data',
            },
            findNewSites: {
                success: '{count, plural, one {Search for new WordPress installations on # server completed} other {Search for new WordPress installations on # servers completed}}',
                failed: '{count, plural, one {Failed to search for new WordPress installations on # server} other {Failed to search for new WordPress installations on # servers}}',
            },
            configureUpdates: {
                success: 'Update settings were configured',
                failed: 'Failed to configure Update settings',
            },
            disconnect: {
                success: '{count, plural, one {# server was disconnected} other {# servers were disconnected}}',
                failed: '{count, plural, one {Failed to disconnect # server} other {Failed to disconnect # servers}}',
            },
        },
        installations: {
            demo: {
                success: 'Operation on a demo server was successful.',
                failed: 'Operation on a demo server failed.',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: 'All selected WordPress installations were updated',
                failed: 'Failed to update selected WordPress installations',
            },
            configureUpdates: {
                success: 'Update settings were changed',
                failed: 'Failed to change update settings',
            },
            detach: {
                success: 'WordPress installation was detached from WP Guardian. It will be ignored during the subsequent server scans. To make this installation visible to WP Guardian, remove the .wp-toolkit-ignore file from the installation directory',
                failed: 'Failed to detach WordPress installation',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'Vulnerabilities were fixed by installing updates',
                failed: '{count, plural, one {Failed to fix vulnerabilities on # installation} other {Failed to fix vulnerabilities on # installations}}',
            },
            mitigationDeactivateAsset: {
                success: 'Vulnerabilities were mitigated via plugin deactivation',
                failed: '{count, plural, one {Failed to mitigate vulnerability via plugin deactivation on # installation} other {Failed to mitigate vulnerabilities via plugin deactivation on # installations}}',
            },
            mitigationActivateAsset: {
                success: 'Plugins deactivated due to vulnerabilities were reactivated',
                failed: '{count, plural, one {Failed to reactivate previously vulnerable plugins on # installation} other {Failed to reactivate previously vulnerable plugins on # installations}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'Vulnerabilities were fixed by installing updates',
                failed: '{count, plural, one {Failed to fix # vulnerability} other {Failed to fix # vulnerabilities}}',
            },
            mitigationDeactivateAsset: {
                success: 'Vulnerabilities were mitigated via plugin deactivation',
                failed: '{count, plural, one {Failed to mitigate # vulnerability via plugin deactivation} other {Failed to mitigate # vulnerabilities via plugin deactivation}}',
            },
            mitigationActivateAsset: {
                success: 'Plugins deactivated due to vulnerabilities were reactivated',
                failed: '{count, plural, one {Failed to reactivate plugins affected by # vulnerability} other {Failed to reactivate plugins affected by # vulnerabilities}}',
            },
            ignoreVulnerabilities: {
                success: 'Vulnerabilities were ignored',
                failed: '{count, plural, one {Failed to ignore # vulnerability} other {Failed to ignore # vulnerabilities}}',
            },
            cancelIgnoreVulnerabilities: {
                success: 'Vulnerabilities are no longer ignored',
                failed: '{count, plural, one {Failed to unignore # vulnerability} other {Failed to unignore # vulnerabilities}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'Quick Start',
        QuickStartWizard: {
            startStepName: 'Start',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: 'Notifications',
            securityCheckScheduleStepName: 'Security check',
            updateStepName: 'Updates',
        },
        QuickStartStep: {
            pagination: '{current} of {total}',
            ok: 'OK',
            next: 'Next',
            back: 'Back',
        },
        StartStep: {
            title: 'Welcome To WP Guardian',
            text: 'Set up everything that\'s needed to keep your WordPress sites secure',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'Security checking time',
            checkingTimeText: 'WP Guardian checks all servers and sites for changes every 24 hours. This is a performance-intensive operation that takes time and uses server resources.',
            vulnerabilitiesUpdateTitle: 'Vulnerability database updates',
            vulnerabilitiesUpdateText: 'Vulnerability database is updated automatically every hour. This operation does not affect server and site performance',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: 'How it works',
            howItWorksText: 'A plugin is installed inside WordPress, acting as a Web Application Firewall. It does not modify WordPress files or site content, but it applies firewall rules targeting only vulnerabilities present on a site.',
            poweredBy: 'powered by {providerLogo}',
        },
        UpdatesStep: {
            next: "Next",
            updateSettings: 'Update Settings',
            updateSettingsText: 'You can force automatic updates on all sites connected to WP Guardian, including sites added afterwards. This can be configured per-site or per-server later.',
            backup: 'Backup before update',
            backupText: 'Enabling safe updates will make WP Guardian back up a site before updating it. If something goes wrong, the site will be restored from the backup automatically.',
            safeUpdates: 'Safe updates',
            customizedSettings: 'Customized settings',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'Task Log',
        Toolbar: {
            buttons: {
                groupTitle: 'Buttons',
                allTasks: 'All tasks',
                failed: 'Failed',
            },
            filters: {
                groupTitle: 'Filters',
                typeUpdate: 'Install updates',
                refresh: 'Refresh data',
                typeFindNewSites: 'Find new sites',
                typeConfigureUpdates: 'Configure updates',
                typeFixVulnerabilitiesViaUpdate: 'Fix vulnerabilities via update',
                mitigationDeactivateAsset: 'Mitigate vulnerabilities via plugin deactivation',
                mitigationActivateAsset: 'Reactivate plugins deactivated due to vulnerabilities',
                ignoreVulnerabilities: 'Ignore vulnerabilities',
                cancelIgnoreVulnerabilities: 'Unignore vulnerabilities',
                setDailyTaskTime: 'Change security check schedule',
            },
            search: {
                title: 'Search',
                placeholder: 'Search',
            },
        },
        TasksList: {
            columns: {
                task: 'Task',
                status: 'Status',
                started: 'Started',
                finished: 'Finished',
            },
            taskTypes: {
                fallBack: "Operation with code ''{type}''",
                demo: 'Operation on a demo server',
                update: 'Install updates',
                refresh: 'Refresh data',
                detach: 'Detach',
                findNewSites: 'Find new sites',
                configureUpdates: 'Configure updates',
                setDailyTaskTime: 'Change security check schedule',
                protect: 'Enable vulnerability protection',
                unprotect: 'Disable vulnerability protection',
                disconnect: 'Disconnect',
                fixVulnerabilitiesViaUpdate: 'Fix vulnerabilities via update',
                changeInstallationPluginStatus: 'Change plugin state',
                mitigationDeactivateAsset: 'Mitigate vulnerabilities via plugin deactivation',
                mitigationActivateAsset: 'Reactivate plugins deactivated due to vulnerabilities',
                ignoreVulnerabilities: 'Ignore vulnerabilities',
                cancelIgnoreVulnerabilities: 'Unignore vulnerabilities',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, one {# server} other {# servers}}',
                sites: '{count, plural, one {# site} other {# sites}}',
                vulnerabilities: '{count, plural, one {# vulnerability} other {# vulnerabilities}}',
            },
            statuses: {
                success: 'Success',
                inProgress: 'In progress',
                failed: 'Failed',
                errors: 'Errors',
                unknown: 'Unknown',
            },
            emptyList: {
                title: 'No tasks',
                description: 'No tasks have been executed yet.',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: 'Sites with disabled protection',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {Re-enable protection on this site} other {Re-enable protection on these sites}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "You don't have a license",
        trialDaysLeft: '{days, plural, one {Trial (# day left)} other {Trial (# days left)}}',
        trialUntil: 'Trial',
    },
    PromoCodeForm: {
        promoCode: 'Your promo code',
        apply: 'Apply',
        activationFailure: 'Failed to activate the promo code',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: 'Apply',
            trialTitle: '14-day trial',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial: 'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'Choose a License',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: 'Your trial has started!',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'See Details',
                },
                trialExpired: {
                    title: 'Your trial expired',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Your license has been terminated',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'No license',
            expired: '(expired)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'Leave your feedback',
            },
        },
        tabs: {
            servers: 'Servers',
            installations: 'WordPress Sites',
            vulnerabilities: 'Vulnerabilities',
        },
        Footer: {
            Links: {
                followUs: 'Follow us:',
                company: 'Company',
                aboutWebpros: 'About WebPros',
                cookiehub: 'Cookie Settings',
                privacyPolicy: 'Privacy Policy',
                knowledgeBase: 'Knowledge Base',
                documentation: 'Documentation',
                helpCenter: 'Help Center',
                contactUs: 'Contact Us',
                legal: 'Legal',
            },
            copyright: '© {year} WebPros International GmbH. All rights reserved. WP Guardian and the WP Guardian logo are trademarks of WebPros International GmbH.',
        },
        Header: {
            UpdateSettingsCard: {
                title: 'Autoupdate settings',
            },
            CardLoader: {
                loading: 'Loading...',
            },
            InstallationsCard: {
                title: 'WordPress sites',
                vulnerable: '{count} Vulnerable',
                outdated: '{count} Outdated',
                protected: '{count} Protected',
                safe: '{count} OK',
                notOperable: '{count} Inoperable',
                poweredBy: '<whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: 'Vulnerabilities',
                databaseUpdate: 'Database update',
                momentAgo: 'a moment ago',
                noData: 'No data',
                lastUpdateMinutes: '{minutes, plural, one {# min ago} other {# min ago}}',
                lastUpdateHours: '{hours, plural, one {# hour ago} other {# hrs ago}}',
                lastUpdateDays: '{days, plural, one {# day ago} other {# days ago}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: 'Highest CVSS score',
                needsProtection: '{count, plural, one {# needs protection} other {# need protection}}',
            },
            QuickStartCard: {
                title: 'Quick Start',
                ctaBlockTitle: 'Set up security',
                ctaBlockText: 'in a few clicks',
            },
        },
        UserMenuAddon: {
            quickStart: 'Quick start',
            license: 'License details',
        },
    },
    Vulnerabilities: {
        updateByProtect: 'There are no updates that fix this vulnerability now.',
        columns: {
            position: 'Where',
        },
        Onboarding: {
            title: 'No Vulnerabilities Found',
            description: 'Connect servers to find WordPress sites and check them for vulnerabilities and updates.',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: 'Mitigated',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'Mitigated by deactivating plugin',
                tooltip: {
                    'singleSiteMitigatedByOther': 'Website affected by this vulnerability is currently secured by means outside of WP Guardian.',
                    'mitigatedByOther': 'Websites affected by this vulnerability are currently secured by means outside of WP Guardian',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'Source:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'This record contains material that is subject to copyright.',
            wordfenceCopyright: 'Copyright 2012-2024 Defiant Inc. License: Defiant hereby grants you a perpetual, worldwide, non-exclusive, no-charge, royalty-free, irrevocable copyright license to reproduce, prepare derivative works of, publicly display, publicly perform, sublicense, and distribute this software vulnerability information. Any copy of the software vulnerability information you make for such purposes is authorized provided that you include a hyperlink to this vulnerability record and reproduce Defiant’s copyright designation and this license in any such copy.',
            readMore: 'Read more',
        },
        Toolbar: {
            groupFilterMenu: 'Filter',
            groupSearch: 'Search',
            manage: 'Manage',
            patch: 'Protection',
            searchPlaceholder: 'Find vulnerability...',
            UpdateButton: {
                update: 'Install Updates',
                cancel: 'Cancel',
                noSelection: 'Select at least one vulnerability to address via updating.',
                updateConfirmation: '{totalVulnerabilities, plural, one {You are about to update multiple websites to address # vulnerability} other {You are about to update multiple websites to address # vulnerabilities}}. The update process cannot be interrupted after it starts.',
                updateConfirmationPluginTitle: 'Several plugins were disabled',
                updateConfirmationPluginCheckbox: 'Reactivate plugins disabled by security policies',
                updateConfirmationPluginCheckboxHint: 'Vulnerable plugins disabled by security policies will be automatically reactivated if their vulnerabilities are fixed in the updates.',
                numberOfSites: 'Number of sites to update:',
                numberOfSitesUnmanaged: 'Number of sites to update (unmanaged sites will be skipped):',
                sitesCalculating: 'Calculating...',
                sitesCalculatingError: 'Failed to count',
                affectedSites: '{count, plural, one {{count} site} other {{count} sites}}',
                unmanagedLabel: '{count, plural, one {# Unmanaged} other {# Unmanaged}}',
                updateByProtect: 'There are no updates that fix this vulnerability now.',
                upgradeLicense: 'Upgrade License',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: 'Ignore',
                noSelection: 'Select at least one vulnerability to ignore or unignore',
            },
            PatchButton: {
                affectedSites: '{count, plural, one {{count} site} other {{count} sites}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'Cancel',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Enable Protection',
                providerLogo: '<whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: 'Calculating...',
                sitesCalculatingError: 'Failed to calculate the number of affected sites',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, one {# Unmanaged} other {# Unmanaged}}',
                update: 'Enable Protection',
                upgradeLicense: 'Upgrade License',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: 'Needs protection',
            filterStateIgnored: 'Ignored',
            filterComponentCore: 'Core',
            filterComponentPlugin: 'Plugin',
            filterComponentTheme: 'Theme',
            everywhere: 'Everywhere',
        },
        UpdateConfirmation: {
            description: '{count, plural, one {You are about to update # website.} other {You are about to update # websites.}} The update process cannot be interrupted after it starts.',
        },
        Widgets: {
            disablePlugin: {
                title: 'Plugins',
                installations: '{enabledCount, plural, one {# active} other {# active}}',
                installationsDeactivated: '{count, plural, one {Deactivated on # website} other {Deactivated on # websites}}',
                confirmationDeactivation: '{count, plural, one {You are about to deactivate vulnerable plugin on # website.} other {You are about to deactivate vulnerable plugin on # websites.}} This process cannot be interrupted after it starts.',
                confirmationReactivation: '{count, plural, one {You are about to reactivate plugin previously deactivated for security reasons on # website. This action will expose the website to potential exploits.} other {You are about to reactivate plugin previously deactivated for security reasons on # websites. This action will expose these website to potential exploits.}} This process cannot be interrupted after it starts.',
                dropdownDeactivate: 'Deactivate',
                buttonDeactivateWithCount: 'Deactivate {count}',
                buttonReactivateWithCount: 'Reactivate {count}',
                buttonDeactivateAll: 'Deactivate all',
                buttonReactivate: 'Reactivate',
                tooltipAllPluginsDeactivatedByUser: 'WP Guardian does not reactivate plugins manually deactivated by site administrators.',
            },
            updates: {
                title: 'Install updates',
                installations: '{count, plural, one {# site} other {# sites}}',
                waitingForUpdatesTitle: 'No updates yet',
                buttonName: 'Update all',
            },
            patches: {
                allProtected: '{count, plural, one {# site} other {# sites}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'Cancel',
                enableButton: 'Enable',
                installations: '{count} from {total}',
                protectAllSites: 'Protect all sites',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: 'Protected sites',
                titleAllProtected: 'All protected',
            },
        },
        installations: '{count, plural, one {# site} other {# sites}}',
        servers: '{count, plural, one {# server} other {# servers}}',
    },
    Onboarding: {
        title: 'No Servers Connected',
        description: 'Connect servers to find WordPress sites and check them for vulnerabilities and updates.',
        demoServerWasAddedSuccessfully: 'Demo server was added.',
        connectServers: '<addServerButton>Connect Your Own Server</addServerButton> <delimiter>or</delimiter> <addDemoServerButton>Use Our Demo Server</addDemoServerButton>',
        hint: 'The demo server is provided by the WP Guardian team. It contains multiple WordPress sites, so you can easily explore the product\'s functionality before connecting your own server.',
        feedbackButton: 'Leave feedback',
        demoServerPopover: {
            description: 'The demo server works in read-only mode. Invasive operations like installing updates will not change the state of websites on the demo server.',
            connectBtn: 'Connect',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: 'Search: {searchValue}',
        searchByServer: 'Server: {searchValue}',
        searchByVulnerability: 'Vulnerability: {searchValue}',
        showAll: 'Show all',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, one {# of the sites on selected servers is currently inoperable} other {# of the sites on selected servers are currently inoperable}}. The operation will not be performed on such sites because they are either broken or otherwise unavailable.',
        state: {
            installingAgent: 'Installing agent',
            connectionError: 'Connection error',
            agentInstallationError: 'Agent installation not completed',
            connectionBlocked: 'Connection blocked',
            synchronizationInProgress: 'Synchronization in progress',
            connectionErrorStatusMessage: 'The connection to the server cannot be established correctly.',
            connectionErrorFixDescription: 'Please check logs and settings on your server ({ipAddress}) and wait for recheck.',
            connectionBlockedStatusMessage: 'The connection to the server is blocked by firewall.',
            connectionBlockedFixDescription: 'Please check that the port 878 on the {ipAddress} server is open and wait for recheck.',
            agentInstallationErrorMessage: 'Agent installation not completed',
            agentInstallationErrorFixDescription: "We did not receive a response from WP Guardian agent about successful installation completion within 5 minutes. This means that either the installation is still ongoing or it has failed. Check the installation progress in the server console, or view the installation log in the '/root/wp-agent-install.log' file",
        },
        ConnectDrawer: {
            title: 'Connect your server',
            done: 'Done',
            tabs: {
                connectDroplet: 'Connect droplet',
                connectServer: 'Connect server',
            },
        },
        AddDroplet: {
            videoDialogTitle: 'Connecting DigitalOcean droplet to WP Guardian',
            docsLinkText: 'Need help with connecting droplets? Check out the documentation',
            goToDoButton: 'Go to DigitalOcean to connect the droplet',
            head1: '1. Copy the following snippet',
            head2: '2. Activate the snippet in droplet console',
            paragraph1singleDroplet: 'Press the button below to open the droplet console, paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
            paragraph1multipleDroplets: 'Press the button below, choose a droplet you\'d like to connect to WP Guardian. From the detail page, click the Access tab in the left menu, then launch the droplet console and paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
        },
        AddServer: {
            done: 'Done',
            paragraph1: 'Open port 878 for incoming connections from the following IPs on each server you want to connect: 34.254.37.129, 52.51.23.204, and 52.213.169.7',
            paragraph2: 'Copy and paste the following snippet into the servers console and wait for the installation process to complete',
            errors: {
                failedToGenerateToken: 'Failed to generate installation command.',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'Copy',
            copied: 'Snippet was copied',
            refreshTooltip: 'The connection command is valid for an unlimited number of servers during this time. Refresh command if your need more time',
        },
        SettingsDrawer: {
            title: 'Settings',
            subTitle: {
                single: 'Server: {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'Updates',
                dailyTaskTime: 'Schedule',
            },
            loadFailed: 'Failed to load settings.',
            buttonSave: 'Save',
            successToast: 'Settings were saved.',
            Updates: {
                customizedSection: {
                    description: 'Update settings will be applied to all newly added and current sites on this server without customized update settings',
                    checkboxDescription: 'Also apply settings to all sites with customized update settings',
                },
            },
            DailyTaskTime: {
                description: 'WP Guardian checks for new vulnerabilities and available updates every 24 hours. This is a performance-intensive operation, so you can schedule it to run at a particular time',
                unsetOption: 'Defined by global settings',
            },
        },
        RemoveServersButton: {
            remove: 'Disconnect',
            noSelectedServers: 'Select at least one server to disconnect.',
            removeDescription: 'WP Guardian agent is not removed automatically upon server disconnect. Use uninstaller script to remove WP Guardian agent (<a>instruction</a>).',
            removeSingleServerConfirmation: 'Disconnect {selectedServerTitle}?',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, one {Disconnect <b>#</b> server?} other {Disconnect <b>#</b> servers?}}',
        },
        ListActions: {
            remove: 'Disconnect',
            refresh: 'Refresh',
            findNewSites: 'Find New Sites',
            settings: 'Settings',
            disablePatch: 'Unprotect',
            update: 'Update All Sites',
            manageLabels: 'Apply Labels',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'Cancel',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, one {# WP site} other {# WP sites}}',
            notOperable: '{count} Inoperable',
            vulnerable: '{count} Vulnerable',
            vulnerabilities: '{count} Vulnerabilities',
            outdated: '{count} Outdated',
            lastCheck: 'Last check: {dateTime}',
            lastCheckToday: 'Last check: today at {time}',
            unsupportedAgent: 'Unsupported agent version',
            coreOutdated: 'Outdated',
        },
        columns: {
            server: 'Server',
        },
        OutdatedAgentError: {
            message: 'WP Guardian agent (wp-agent) version on the server is outdated',
            agentVersion: '{version, select, _ {Your WP Guardian agent version is N/A.} other {Your WP Guardian agent version is {version}.}}',
            updateDescription: 'Update wp-agent package to version {wpAgentMinSupportedVersion} or higher.',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'Update Settings',
            ipAddress: '<b>Server IP:</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'Filter',
            manage: 'Manage',
            remove: 'Disconnect',
            add: 'Connect',
            groupFilter: 'Filter',
            groupSearch: 'Search',
            searchPlaceholder: 'Find server...',
            noSelectedServers: 'Select at least one server to disconnect.',
            FilterPanel: {
                filterAlerts: 'Alerts',
                labelsPlaceholder: 'All labels',
            },
            RefreshButton: {
                buttonText: 'Refresh',
                noSelectedServers: 'Select at least one server to refresh.',
            },
            FindNewSitesButton: {
                buttonText: 'Find New Sites',
                noSelectedServers: 'Select at least one server to find new WordPress sites.',
            },
            UpdateButton: {
                buttonText: 'Install Updates',
                confirmButtonText: 'Update All',
                noSelectedServers: 'Select at least one server to install all available updates on all WordPress sites.',
                updateSingleServerConfirmation: 'All available updates will be installed on all WordPress sites located on the server <b>{selectedServerTitle}</b>.',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, one {All available updates will be installed on all WordPress sites located on <b>#</b> selected server.} other {All available updates will be installed on all WordPress sites located on <b>#</b> selected servers.}}',
            },
            ProtectButton: {
                buttonText: 'Protect',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'Apply Labels',
            },
            SettingsButton: {
                buttonText: 'Settings',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: 'Connect',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: 'Auto-ignoring low-risk vulnerabilities',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: 'Automatically ignore low-risk vulnerabilities',
            warningMessage: 'If this setting is switched off, all "ignored automatically" labels will be removed and all auto-ignored vulnerabilities will be fully visible again',
        },
    },
    Installations: {
        yes: 'Yes',
        no: 'No',
        update: 'Install Updates',
        refresh: 'Refresh',
        detach: 'Detach',
        updateSettings: 'Update Settings',
        sitesAreNotOperable: '{notOperable, plural, one {# of the selected sites is currently inoperable} other {# of the selected sites are currently inoperable}}. The operation will not be performed on such sites because they are either broken or otherwise unavailable.',

        DetachInstallationsButton: {
            detach: 'Detach',
            detachSingleInstallationConfirmation: 'Detach <b>{selectedInstallationTitle}</b> from WP Guardian? Detached installations will be ignored during the subsequent server scans.',
            detachSeveralInstallationsConfirmation: 'Detach <b>{selectedInstallationsCount}</b> installations from WP Guardian? Detached installations will be ignored during the subsequent server scans.',
        },
        Onboarding: {
            title: 'No WordPress Sites Found',
            description: 'Connect servers to find WordPress sites and check them for vulnerabilities and outdated components.',
        },
        columns: {
            website: 'Site',
            server: 'Server',
            synchronized: 'Connected',
            lastCheck: 'Last Check',
            protect: 'Protect',
            siteHealth: 'Site Health',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'Filter Menu',
                filterAll: 'All',
                filterVulnerable: 'Vulnerable',
                filterOutdated: 'Outdated',
                filterNotOperable: 'Inoperable',
                filterProtected: 'Protected',
                filterUnmanaged: 'Unmanaged',
                filterNotProtected: 'Unprotected',
                filterSafe: 'No issues',
            },
            labelsPlaceholder: 'All labels',
        },
        Toolbar: {
            groupFilterMenu: 'Filter',
            groupSearch: 'Search',
            searchPlaceholder: 'Find website...',
            groupActions: 'Actions',
            groupUpdate: 'Install Updates',
            groupProtect: 'Protect',
            subGroupActions: 'Actions',
            buttons: {
                update: {
                    text: 'Install Updates',
                    noSelection: 'Select at least one site to install all available updates.',
                },
                refresh: {
                    text: 'Refresh',
                    noSelection: 'Select at least one site to refresh.',
                },
                protect: {
                    text: 'Protect',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'Apply Labels',
                },
                settings: {
                    text: 'Settings',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: 'Update <b>{selectedInstallationTitle}</b>?',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to update <b>#</b> WordPress site} other {You are about to update <b>#</b> WordPress sites}}',
                confirmButton: 'Update',
                cancelButton: 'Cancel',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: 'Protect',
                cancelButton: 'Cancel',
            },
        },
        SiteHealthColumn: {
            outdated: '{count} Outdated',
            safe: 'OK',
            notOperable: 'Inoperable',
            unsupportedAgent: 'Unsupported agent version',
            vulnerabilities: '{count} Vulnerabilities',
            lastCheckAtTooltip: 'Last check: {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: 'Settings were saved.',
            title: 'Update Settings',
            subTitle: {
                single: 'Website: {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: '{notOperableCount, plural, one {# inoperable site} other {# inoperable sites}} and {unmanagedCount, plural, one {# unmanaged site} other {# unmanaged sites}} will be skipped.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, one {# unmanaged site will be skipped} other {# unmanaged sites will be skipped} }.',
            },
            buttonSave: 'Save',
            confirmationPopover: {
                confirmBtn: 'Save',
                description: {
                    single: 'You are about to modify update settings for WordPress site {installationName}. Continue?',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'Updates',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Enable Vulnerability Protection',
        },
        InstallationSummary: {
            updateAll: 'Update All',
            updateSettings: 'Update Settings',
            upToDate: 'Up-to-date',
            AssetSummary: {
                plugin: {
                    title: 'Plugins [{count}]',
                    update: 'Install plugin updates',
                },
                theme: {
                    title: 'Themes [{count}]',
                    update: 'Install theme updates',
                },
                safe: 'OK',
                vulnerable: '{count} Vulnerable',
                outdated: '{count} Outdated',
                vulnerabilities: '{count} Vulnerabilities',
                isOutdated: 'Outdated',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: 'Update to {version}',
                updateSettingsTitle: 'Update Settings',
                vulnerabilities: '{count, plural, one {# Vulnerability} other {# Vulnerabilities}}',
                isOutdated: 'Outdated',
            },
            unsupportedAgentError: 'The version of wp-agent on a server is outdated. Minimal required version is {wpAgentMinSupportedVersion}',
            notOperableUnknownError: 'An unknown error occurred while scanning the site for vulnerabilities.',
            hostname: 'Server hostname:',
            ipAddress: 'Server IP:',
            UpdateConfirmation: {
                title: 'Confirm Update',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'Cancel',
                continueButton: 'Update',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Vulnerability protection by <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: 'Vulnerability protection is disabled',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: 'Enable protection',
            },
            ProtectInfoPopover: {
                label: 'Vulnerability still active?',
                message: 'Neutralizing a vulnerability requires specific protection rules. Here\'s why protection rules might not be available for a particular vulnerability:',
                noPatch: {
                    title: 'Work in progress',
                    description: 'Rules for high-impact vulnerabilities are usually made available within hours of disclosure. Rules for lower-impact vulnerabilities might take longer to be created.',
                },
                unlikelyExploited: {
                    title: 'Low risk',
                    description: 'Some vulnerabilities have minimal impact or lack real exploit methods. Since they do not present a real threat to websites, protection rules for them are not necessary.',
                },
                dbMatch: {
                    title: 'Missing in database',
                    description: 'Vulnerabilities that are present only in Wordfence database or not matched with corresponding entries from Patchstack database do not receive protection rules.',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'let us know',
            },
        },
        WebsiteDrawer: {
            title: 'WordPress Site Details',
            titleUpdateInProgress: 'Updating {title}',
            subtitleUpdateInProgress: 'Updating selected items',
            errors: {
                installationNotFound: "Couldn't load information for installation with id {id}",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'Cancel',
                        updateButton: 'Update',
                        description: '{count, plural, one {You are about to fix # vulnerability via update on <b>{website}</b>.} other {You are about to fix # vulnerabilities via update on <b>{website}</b>.}} The update process cannot be interrupted after it starts.',
                        updateConfirmationPluginCheckbox: '{count, plural, one {Reactivate plugin disabled by security policy} other {Reactivate plugins disabled by security policy}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, one {Vulnerable plugin disabled by your security policy will be automatically reactivated if its vulnerability is fixed in the update.} other {Vulnerable plugins disabled by your security policy will be automatically reactivated if their vulnerabilities are fixed in the updates.}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'Actions',
                            buttons: {
                                update: 'Update',
                                processing: 'Processing',
                            },
                        },
                        filters: {
                            title: 'Filters',
                            groupFiltersTitle: 'Filter',
                            search: {
                                title: 'Search',
                                placeholder: 'Find vulnerability...',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: 'Need protection',
                                filterStateIgnored: 'Ignored',
                                filterComponentHeader: 'Where',
                            },
                        },
                    },
                    title: 'Vulnerabilities',
                    columns: {
                        component: 'Where',
                    },
                    emptyList: {
                        title: 'Everything is secure',
                        description: 'No known vulnerabilities found on this website.',
                    },
                    waitingForUpdate: 'No updates available',
                    updateTo: 'Update to {availableVersion}',
                    update: 'Update',
                    disablePlugin: 'Deactivate plugin',
                    enablePlugin: 'Activate plugin',
                    updateByProtect: 'There are no updates that fix this vulnerability now.',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, one {You are about to update # asset on <b>{website}</b>.} other {You are about to update # assets on <b>{website}</b>.}} The update process cannot be interrupted after it starts.',
                        cancelButton: 'Cancel',
                        updateButton: 'Update',
                    },
                    title: 'Outdated',
                    toolbar: {
                        actions: {
                            title: 'Actions',
                            buttons: {
                                update: 'Update',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'Filters',
                            groupSearchTitle: 'Search',
                            search: {
                                title: 'Search',
                                placeholder: 'Find outdated component...',
                            },
                        },
                    },
                    columns: {
                        name: 'Name',
                        type: 'Where',
                    },
                    emptyList: {
                        title: 'Everything is up-to-date',
                        description: 'Latest versions of WordPress core, plugins, and themes are installed on this site.',
                    },
                    updateTo: 'Update to {version}',
                },
            },
        },
        Actions: {
            enableProtectButton: 'Protect',
            disableProtectButton: 'Unprotect',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'Apply Labels',
        },
        DisableProtectionButton: {
            confirmButton: 'Unprotect',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: 'Notifications',
        listEmptyTitle: 'Keep up to date with the most important events',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'Mark All As Read',
        deleteNotification: 'Delete this notification',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: 'New license active',
                licenseLimitReached: 'Website limit reached',
                licenseExpired: 'License expired',
                licenseTerminated: 'License terminated',
                trialCreated: 'Trial started',
                trialExpired: 'Trial expired',
                trialExpiredSoon: 'Trial will expire on {expirationDate}',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: 'Your new {licenseName} license key #{licenseKey} is now active.',
                licenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Upgrade your license to manage all your sites.',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'Your WP Guardian trial period has started. You have full access to all WP Guardian features for up to {sitesLimit, plural, one {# website} other {# websites}} for the duration of the trial.',
                trialExpired: "Your WP Guardian trial period has ended. Select and purchase a license to continue using the product, or tell us why you're not convinced via feedback form above.",
                trialExpiredSoon: 'Your WP Guardian trial period expires on {expirationDate}. Consider purchasing a license to continue using the product.',
            },
            markAsRead: 'Mark as read',
            markAsUnread: 'Mark as unread',
        },
        settings: 'Settings',
    },
    ConfirmNotificationsEmail: {
        title: 'Notification e-mail confirmation',
        error: 'Link has expired',
        successMessage: 'Notification e-mail was changed.',
    },
    NotificationsSettingsDrawer: {
        title: 'Notification Settings',
        Content: {
            sendSectionTitle: 'Where to notify me',
            email: 'E-mail',
        },
    },
    NotificationSettingsEventSection: {
        title: 'Which out-of-product notifications to send',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'License notifications',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: 'Change notification e-mail',
        buttonSave: 'Send e-mail',
        buttonCancel: 'Cancel',
        buttonEdit: 'Edit',
        email: 'E-mail',
        description: 'We will send you an e-mail with instructions on how to update the e-mail address used for receiving notifications.',
        successMessage: 'An e-mail was sent to <b>{email}</b>. Follow the instructions in that e-mail to verify your new e-mail address.',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: 'Vulnerability Detection',
            description: 'Real-time updates about new WordPress vulnerabilities on connected sites',
        },
        secureSites: {
            title: 'Keeping WordPress sites secure',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: 'Automatic updates',
            description: 'Flexible autoupdate settings available for servers and websites',
        },
        safeUpdates: {
            title: 'Safe updates',
            description: 'Pre-update backups provide automatic rollback if update goes wrong',
        },
        notifications: {
            title: 'Health status & notifications',
            description: 'Always know how your WordPress sites are doing',
        },
    },
    LicensePage: {
        benefits: {
            title: 'Ensuring the security of WordPress sites',
        },
        ExpirationLabel: {
            noLicense: "You don't have a license",
            expiredLicense: 'Your license has expired',
        },
        LicenseHeading: {
            welcome: 'Welcome To WP Guardian',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'Choose your license',
            trial: 'Choose your license',
            trialLicenseExpired: 'Your trial period has ended',
        },
        LicenseDescription: {
            licenseKey: 'License key #{number}',
            nextUpdate: 'Next license key update: {date}',
            expirationDate: 'Expiration date: {date}',
            noLicense: 'Free version of WP Guardian includes only vulnerability information and manual site updates. Purchase a license to access all WP Guardian features.',
            trialJustStarted: 'You have access to all WP Guardian features during your trial period. After the trial you will be recommended a license which suits you best. You will also have the option to continue using WP Guardian for free with certain limitations.',
            trial: '{sites, plural, one {Your trial is time-limited and allows managing up to # WordPress site.} other {Your trial is time-limited and allows managing up to # WordPress sites.}} Purchase a license if you want to continue using all current and future WP Guardian features.',
            limit: '{sites, plural, one {Your license allows managing up to # WordPress site.} other {Your license allows managing up to # WordPress sites.}}',
            unlimited: 'Your license allows managing unlimited number of WordPress sites. {sites, plural, one {Base license price includes management of # site.} other {Base license price includes management of # sites.}} All sites above this limit are billed on a Pay-As-You-Go basis.',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: 'See details',
            nextBillingDate: 'Next billing date: {date}',
            subscriptionCancelled: 'Subscription is canceled.',
        },
        CurrentUsage: {
            youAreUsingNow: 'Current usage:',
            sitesTotal: '{sites, plural, one {# website total} other {# websites total}}',
            allowedToManage: '{sites, plural, one {# website allowed to manage} other {# websites allowed to manage}}',
            outOfLimit: '{sites, plural, one {# website over the license limit} other {# websites over the license limit}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Unlimited',
                label: 'For more than 1000 sites',
                feature1: '10k sites included',
                feature2: 'Pay as you go for sites above 10k',
                feature3: 'All features included',
                pricePerSite: '{price, number, ::currency/USD} for each site above 10k',
            },
            standard: {
                title: 'Standard',
                label: 'For up to 1000 sites',
                feature1: 'Pay before use',
                feature2: 'All features included',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'Use this plan',
            changePlan: 'Change plan',
            chooseAnotherOption: 'Choose another option to upgrade your plan',
        },
        LicenseList: {
            columns: {
                license: 'License',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: 'Buy',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: 'Reactivate',
            details: 'Details',
        },
        chooseLicenseNow: 'Choose your license',
        licensesTitle: 'Select the license that suits your needs',
        faq: {
            title: 'Frequently asked questions',
            question1: {
                question: 'What happens after my trial period is over?',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: 'What happens if my license limits are reached?',
                answer: 'Sites added to WP Guardian after the license limit was reached will be marked as unmanaged. Such sites will continue to have up-to-date information about vulnerabilities and can only be updated manually. All other WP Guardian functionality will not be available for them.',
            },
            question4: {
                question: 'How does Pay As You Go work?',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'You can get your license in <kaLink>Key Administrator</kaLink>, <manage2Link>Manage2</manage2Link>, or from your sales representative.',
            standard: {
                title: '{sites, plural, one {You want to choose standard # site license} other {You want to choose standard # sites license}}',
                licenseLimit: '{sites, plural, one {The license includes only # site} other {The license includes only # sites}}',
                feature1: 'Standard license includes a specific number of websites without Pay-As-You-Go capabilities.',
                feature2: 'All websites added after reaching the license limit will be marked as Unmanaged.',
                feature3: 'Unmanaged websites cannot be managed via WP Guardian without upgrading your license.',
                feature4: 'Unmanaged websites will be switched to managed if a license limit is increased or a free slot in current license becomes available.',
                feature5: 'If a website was connected to WP Guardian for more than several days, it will be counted towards your license usage.',
                feature6: 'Broken and otherwise unavailable websites are not counted towards your license limit.',
            },
            unlimited: {
                title: 'You choose unlimited license',
                feature1: 'Unlimited license includes 10,000 websites in its baseline price.',
                feature2: 'All websites added after the first 10,000 will be billed on a Pay-As-You-Go basis.',
                feature3: 'If a website was connected to WP Guardian for more than several days, it will be counted towards your license usage.',
                feature4: 'Broken and otherwise unavailable websites are not counted towards your license limit.',
            },
        },
        UpgradeButton: {
            upgrade: 'Upgrade',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'All features included',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'Buy License',
            upgradeLicense: 'Upgrade License',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'Back to Home Screen',
    },
    LabelsDrawer: {
        title: 'Manage Labels',
        LabelsList: {
            create: 'Create Label',
            edit: 'Edit',
            delete: 'Delete',
            noSelectedLabels: 'Select at least one label to delete.',
            columns: {
                title: 'Label',
            },
            search: {
                title: 'Find',
                placeholder: 'Find label',
            },
            emptyList: {
                title: 'Create A Label',
                description: "You don't have any labels for differentiating your servers and sites. Want to make some of them stand out?",
            },
            filteredEmptyList: {
                title: 'Could not find any items matching the filter',
                description: 'Change the label text you are looking for and try again',
            },
        },
        DeleteLabelsPopover: {
            delete: 'Delete',
            confirmation: '{count, select, 1 {Deleting a label will remove it from all objects it was applied to.} other {Deleting labels will remove them from all objects they were applied to.}} Continue?',
        },
        CreateLabelDrawer: {
            title: 'Create Label',
        },
        EditLabelDrawer: {
            title: 'Edit Label',
            notFound: 'Label not found',
        },
        LabelForm: {
            save: 'Save',
            cancel: 'Cancel',
            fields: {
                title: {
                    label: 'Label name',
                    description: 'Maximum 30 symbols',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'LABEL AS',
        noSelection: 'Select at least one item in the list to add or remove labels.',
        buttons: {
            apply: 'Apply',
            create: 'Create New',
            manage: 'Manage Labels',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'all',
            tooltip: 'Safe updates are enabled',
            components: {
                core: 'core',
                themes: 'themes',
                plugins: 'plugins',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'security updates',
                major: 'all updates',
                disabled: "don't install",
                notSet: 'site level',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, one {Your license includes # managed site} other {Your license includes # managed sites}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, one {# unmanaged site will be skipped} other {# unmanaged sites will be skipped}}',
        operableNotManagedSitesMessage: 'Unmanaged sites are ignored by WP Guardian because they exceed your license limits. Such websites are skipped while mass actions are performed. ',
        notOperableNotManagedSitesTitle: '{notOperableCount, plural, one {# inoperable site} other {# inoperable sites}} and {unmanagedCount, plural, one {# unmanaged site} other {# unmanaged sites}} will be skipped',
        notOperableNotManagedSitesMessage: 'The operation you\'ve selected will not be performed for such sites because they are either inoperable or exceed your license limits. Such websites are skipped while mass actions are performed.',
        managedSitesWillBePerformed: 'For all managed sites the operation you\'ve selected will be performed without restrictions.',
        chooseLicenseButton: 'Choose A License',
        LicenseTerminatedDialog: {
            title: 'Your license is terminated',
            message: 'Please get a license if you wish to continue using WP Guardian advanced functionality.',
            chooseLicenseButton: 'Choose A License',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'Site-defined updates',
            autoupdate: 'Autoupdates',
            manual: 'Manual updates',
            custom: 'Customized updates',
        },
        unmanaged: {
            title: 'Unmanaged',
            tooltip: 'Unmanaged sites are ignored by WP Guardian because they exceed your license limits. Sites marked as unmanaged due to license limits will continue to have up-to-date information about their vulnerabilities, and can only be updated manually. Such sites will be switched to managed if a license limit is increased or a free slot in current license becomes available.',
        },
        menu: {
            remove: 'Remove',
            filter: 'Filter by this label',
        },
    },
    Cuttable: {
        showMore: 'Show more',
        showLess: 'Show less',
    },
};
