// Copyright 2024. WebPros International GmbH. All rights reserved.

/* eslint comma-dangle: ["error", "always"], operator-linebreak: ["error", "none"], no-useless-concat: ["error"], object-curly-newline: ["error", "always"], object-property-newline: ["error"] */

export default {
    errors: {
        labelAlreadyExists: 'Já existe uma tag com esse nome',
        invitationNotExist: 'O convite não existe.',
        wpApiClientUnreachable: 'Este servidor ou website parece estar off-line no momento. Verifique se o seu servidor ou website está acessível e tente novamente. Se este servidor ou website estiver funcionando corretamente e não houver problemas com sua conexão com o WP Guardian, entre em contato com nosso suporte.',
    },
    termsOfUseLink: 'Termos de uso do WP Guardian',
    NotFoundPage: {
        title: 'Página não encontrada',
        description: "A página solicitada não pode ser encontrada. É possível que você tenha seguido um link incorreto ou que a página tenha sido removida.",
    },
    BuyLicenseDrawer: {
        title: 'Purchase Your License',
    },
    ActivateLicense: {
        CancelConfirmation: {
            title: 'Confirmar cancelamento da ativação da licença',
            description: 'Você está prestes a cancelar a ativação da licença do {product}. Você pode ativar essa licença mais tarde clicando no link de ativação novamente. Se a licença for comprada para outra conta, faça login nessa conta e clique no link de ativação.',
            cancelButton: 'Cancelar',
            continueButton: 'Continuar ativação',
        },
        SecurityDashboardActivateLicense: {
            title: 'Confirmar ativação da licença do WP Guardian',
            description: 'Confirme a ativação da licença do <b>{product}</b> para a seguinte conta: <mailto>{userEmail}</mailto>. A licença pode ser ativada apenas para uma conta.',
            fixableErrorDescription: 'Algo deu errado durante a ativação da licença do WP Guardian. Tente novamente ou envie uma solicitação de suporte.',
            nonFixableErrorDescription: 'Algo deu errado durante a ativação da licença do WP Guardian. Envie uma solicitação de suporte.',
            loaderText: 'Sua conta do WP Guardian está sendo configurada',
            redirectText: 'Redirecionando você para o WP Guardian',
            confirmButton: 'Confirmar',
            cancelButton: 'Cancelar',
            retryButton: 'Tentar novamente',
            gotItButton: 'Entendi',
            errors: {
                linkError: 'O link de ativação é inválido.',
                unknownError: 'Erro interno do serviço de ativação.',
            },
        },
    },
    Filters: {
        all: 'Todos',
    },
    FiltersForm: {
        title: 'Filtrar',
        clear: 'Limpar',
        apply: 'Aplicar filtro',
    },
    Invitation: {
        activationFailure: 'Falha ao ativar o link do convite',
    },
    UnderConstructionPopover: {
        title: 'Em construção',
        description: "Desculpe, ainda estamos trabalhando nisso!",
    },
    RiskRankTitle: {
        title: 'Risco',
        description: 'A classificação de risco é uma classificação agregada do impacto da vulnerabilidade. Ela é baseada na classificação CVSS, na classificação EPSS, na Prioridade de Patch do Patchstack e em outros fatores.',
    },
    RiskRank: {
        label: {
            'critical': 'CRIT',
            'high': 'HIGH',
            'medium': 'MED',
            'low': 'LOW',
        },
        criticalRisk: 'Esta vulnerabilidade já é explorada ativamente, sendo altamente recomendável uma reação imediata.',
        highRisk: 'Esta é uma vulnerabilidade de alto risco que deve ser resolvida o mais rápido possível',
        mediumRisk: 'Esta é uma vulnerabilidade de risco Médio que não requer intervenção urgente, mas deve ser abordada eventualmente',
        lowRisk: 'Esta é uma vulnerabilidade de Baixo risco que pode ser ignorada com segurança',
        cvss: 'CVSS: {score}',
        emptyCvss: 'N/A',
    },
    labelTypes: {
        doNotProtect: {
            title: 'Proteção desativada',
            tooltip: 'Vulnerability protection is disabled on this site because you have overridden the server-wide protection settings for this particular site.',
        },
        ignore: {
            title: 'Ignorada',
        },
        ignoredDueToLowRisk: {
            title: 'Ignorado automaticamente',
        },
        doNotIgnoreAutomatically: {
            title: 'Não ignore automaticamente',
        },
    },
    licenseProducts: {
        limit1: 'WP Guardian 1 Site',
        limit1_retail: 'WP Guardian 1 Site',
        limit5: 'WP Guardian 5 Sites',
        limit5_retail: 'WP Guardian 5 Sites',
        limit10: 'WP Guardian 10 Sites',
        limit10_retail: 'WP Guardian 10 Sites',
        limit20: 'WP Guardian 20 Sites',
        limit20_retail: 'WP Guardian 20 Sites',
        limit30: 'WP Guardian 30 Sites',
        limit30_retail: 'WP Guardian 30 Sites',
        limit50: 'WP Guardian 50 Sites',
        limit50_retail: 'WP Guardian 50 Sites',
        limit100: 'WP Guardian 100 Sites',
        limit500: 'WP Guardian 500 Sites',
        limit1000: 'WP Guardian 1000 Sites',
        limit10000: 'WP Guardian 10000 Sites',
        unlimited: 'Unlimited',
    },
    taskTypes: {
        scanForNewSites: 'Encontrando novos websites WordPress',
        refreshInstallations: 'Atualizando',
        detachInstallations: 'Separando',
        updateInstallation: 'Atualizando',
        massUpdateInstallations: 'Atualizando',
        applyVirtualPatches: 'Habilite a proteção contra vulnerabilidade',
        disableVirtualPatches: 'Disable vulnerability protection',
        saveUpdateSettings: 'Salvando as configurações de atualização',
        clearInstallationCache: 'Apagando o cache de instalação',
        checkVulnerability: 'Verificando existência de vulnerabilidades',
        mitigateVulnerabilities: 'Mitigando vulnerabilidades',
        setDailyTaskStartTime: 'Alteração do cronograma de verificação de segurança',
    },
    installationComponents: {
        core: 'Core',
        plugin: 'Plugin',
        theme: 'Tema',
    },
    useAssignInstallationLabelsMutation: {
        success: '{count, select, 1 {A etiqueta foi atribuída} other {As etiquetas foram atribuídas}}',
        failed: '{count, select, 1 {Falha ao atribuir a tag} other {Falha ao atribuir as tags}}',
    },
    useRemoveInstallationLabelsMutation: {
        success: '{count, select, 1 {A etiqueta foi removida} other {As etiquetas foram removidas}}',
        failed: '{count, select, 1 {Falha ao remover a tag} other {Falha ao remover as tags}}',
    },
    useAssignServerLabelsMutation: {
        success: '{count, select, 1 {A etiqueta foi atribuída} other {As etiquetas foram atribuídas}}',
        failed: '{count, select, 1 {Falha ao atribuir a tag} other {Falha ao atribuir as tags}}',
    },
    useRemoveServerLabelsMutation: {
        success: '{count, select, 1 {A etiqueta foi removida} other {As etiquetas foram removidas}}',
        failed: '{count, select, 1 {Falha ao remover a tag} other {Falha ao remover as tags}}',
    },
    useCreateLabelMutation: {
        success: 'A tag foi criada',
        failed: 'Falha ao criar a tag',
    },
    useEditLabelMutation: {
        success: 'A tag foi modificado',
        failed: 'Falha ao modificar a tag',
    },
    useDeleteLabelsMutation: {
        success: '{count, select, 1 {A etiqueta foi excluída} other {As etiquetas foram excluídas}}',
        failed: '{count, select, 1 {Falha ao apagar a tag} other {Falha ao apagar as tags}}',
    },
    useUpdateNotificationsSettingsMutation: {
        successMessage: 'As configurações de notificação foram atualizadas.',
    },
    useRefreshMutation: {
        notifySingleServerFail: 'Não foi possível agendar a atualização dos dados do site no servidor <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Não foi possível agendar a atualização dos dados do site em <b>#</b> servidor.} other {Não foi possível agendar a atualização dos dados do site em <b>#</b> servidores.}}',
    },
    useRunServersScannerMutation: {
        notifySingleServerFail: 'Não foi possível agendar a pesquisa por novos sites WordPress no servidor <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Não foi possível agendar a pesquisa por novos sites WordPress em <b>#</b> servidor.} other {Não foi possível agendar a pesquisa por novos sites WordPress em <b>#</b> servidores.}}',
    },
    useRemoveServersMutation: {
        notifySingleServer: 'O servidor <b>{selectedServerTitle}</b> foi desconectado.',
        notifySeveralServers: '{selectedServersCount, plural, one {<b>#</b> servidor foi desconectado.} other {<b>#</b> servidores foram desconectados.}}',
        notifySingleServerFail: 'O servidor <b>{selectedServerTitle}</b> não foi desconectado.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {<b>#</b> servidor não foi desconectado.} other {<b>#</b> servidores não foram desconectados.}}',
    },
    useServerUpdaterMutation: {
        notifySingleServerFail: 'Não foi possível agendar as atualizações do site no servidor <b>{selectedServerTitle}</b>.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Não foi possível agendar as atualizações do site em <b>#</b> servidor.} other {Não foi possível agendar as atualizações do site em <b>#</b> servidores.}}',
    },
    useServersSitesProtectMutation: {
        notifySingleServerFail: 'Unable to schedule enabling of vulnerability protection on <b>{selectedServerTitle}</b> server.',
        notifySeveralServersFail: '{selectedServersCount, plural, one {Unable to schedule enabling of vulnerability protection on <b>#</b> server.} other {Unable to schedule enabling of vulnerability protection on <b>#</b> servers.}}',
    },
    UpdatesTab: {
        settingLevel: {
            global: 'Global',
            server: 'Servidor',
        },
        coreLabels: {
            disabled: 'Não instalar nenhuma atualização automaticamente',
            major: 'Instalar todas as atualizações automaticamente',
            minor: 'Instalar atualizações menores (de segurança) automaticamente',
            notSet: 'Definido no nível do website',
            minorDescription: 'Por exemplo, instalar automaticamente o 4.7.1, mas não 4.8.',
            doNotTouch: 'Não alterar',
        },
        pluginsLabels: {
            disabled: 'Deixar o Admin do website decidir quais plugins atualizar',
            disabledDescription: 'O WordPress permite que os admins do site habilitem atualizações automáticas para cada plugin separadamente',
            major: 'Instalar todas as atualizações de plugin automaticamente',
            majorDescription: 'Ignorar configurações individuais de atualização automática do plugin para cada plugin',
            minor: 'Instalar apenas atualizações de segurança automaticamente',
            minorDescription: 'Usar configurações individuais de atualização automática de plugin, mas instalar automaticamente as atualizações se elas corrigirem uma vulnerabilidade conhecida',
            notSet: 'Definido no nível do website',
            doNotTouch: 'Não alterar',
        },
        themesLabels: {
            disabled: 'Deixar o Admin do website decidir quais temas atualizar',
            disabledDescription: 'O WordPress permite que os admins do site habilitem atualizações automáticas para cada tema separadamente',
            major: 'Instalar todas as atualizações de tema automaticamente',
            majorDescription: 'Ignorar configurações individuais de atualização automática de tema para cada tema',
            minor: 'Instalar apenas atualizações de segurança automaticamente',
            minorDescription: 'Usar configurações individuais de atualização automática de tema, mas instalar automaticamente as atualizações se elas corrigirem uma vulnerabilidade conhecida',
            notSet: 'Definido no nível do website',
            doNotTouch: 'Não alterar',
        },
        safeUpdatesLabels: {
            notSet: 'Definido no nível do website',
            enabled: 'Habilitar atualizações seguras',
            disabled: 'Desabilitar atualizações seguras',
            doNotTouch: 'Não alterar',
        },
        safeUpdates: {
            title: 'Atualizações Seguras',
            description: 'Faça backup do site antes de atualizá-lo. Se uma atualização quebrar o site, o backup será restaurado automaticamente',
            checkbox: 'Habilitar atualizações seguras',
        },
        labels: {
            core: 'WordPress Core',
            plugins: 'Plugins',
            themes: 'Temas',
        },
        doNotTouch: 'Não alterar',
        serverLevel: 'Use server level policy',
        globalLevel: 'Use global level policy',
    },
    UpdateSettingsCustomizedCheckbox: {
        description: 'As configurações de atualização serão aplicadas a todos os servidores atuais e adicionados recentemente sem configurações de atualização personalizadas',
        checkboxDescription: 'Aplique também configurações a todos os servidores e websites com configurações de atualização personalizadas',
    },
    DailyTaskTimeSection: {
        typeLabel: 'Cronograma de verificação de segurança',
        typeOptions: {
            doNotTouch: 'Não alterar',
            unset: 'Definido pelo servidor',
            custom: 'Horário personalizado',
        },
        timeLabel: 'Hora (24 horas)',
        timezoneDescription: 'Fuso horário: fuso horário padrão do servidor',
    },
    SettingsDrawer: {
        title: 'Configurações',
        subTitle: 'Configurações para todos os servidores e websites conectados',
        loadFailed: 'Falha ao carregar as configurações.',
        tabs: {
            updates: 'Atualizações',
            dailyTaskTime: 'Cronograma',
            vulnerabilities: 'Vulnerabilidades',
        },
        DailyTaskTime: {
            description: 'O WP Guardian verifica todos os servidores em busca de novas vulnerabilidades e atualizações disponíveis a cada 24 horas. Esta é uma operação que exige bastante desempenho, então você pode programá-la para ser executada em um horário específico em todos os servidores por padrão.',
        },
        successToast: 'As configurações foram salvas.',
        buttonSave: 'Salvar',
    },
    Operations: {
        openTaskManagerLinkText: 'Saiba mais',
        operationsFailed: '{count, plural, one {Você tem # operação com falha} other {Você tem # operações com falha}}',
        fallback: {
            success: 'A operação foi executada',
            failed: 'Falha ao executar a operação',
        },
        servers: {
            demo: {
                success: 'Foi realizada uma operação em um servidor de demonstração.',
                failed: 'A operação em um servidor de demonstração falhou.',
            },
            protect: {
                success: 'Vulnerability protection was enabled',
                failed: 'Falha ao habilitar a proteção contra vulnerabilidade',
            },
            update: {
                success: '{count, plural, one {As instalações em # servidor foram atualizadas} other {As instalações em # servidores foram atualizadas}}',
                failed: '{count, plural, one {Falha ao atualizar as instalações em # servidor} other {Falha ao atualizar as instalações em # servidores}}',
            },
            refresh: {
                success: 'Atualização dos dados do site finalizada',
                failed: 'Falha ao atualizar os dados do site',
            },
            findNewSites: {
                success: '{count, plural, one {Pesquisa por novas instalações WordPress em # servidor concluída} other {Pesquisa por novas instalações WordPress em # servidores concluída}}',
                failed: '{count, plural, one {Falha ao pesquisar por novas instalações WordPress em # servidor} other {Falha ao pesquisar por novas instalações WordPress em # servidores}}',
            },
            configureUpdates: {
                success: 'As configurações de atualização foram realizadas',
                failed: 'Falha ao realizar as Configurações de atualização',
            },
            disconnect: {
                success: '{count, plural, one {# servidor foi desconectado} other {# servidores foram desconectados}}',
                failed: '{count, plural, one {Falha ao desconectar # servidor} other {Falha ao desconectar # servidores}}',
            },
        },
        installations: {
            demo: {
                success: 'Operação bem-sucedida em um servidor de demonstração.',
                failed: 'A operação em um servidor de demonstração falhou.',
            },
            protect: {
                success: 'Vulnerability protection was enabled on all selected WordPress installations',
                failed: 'Failed to enable vulnerability protection on selected WordPress installations',
            },
            update: {
                success: 'Todas as instalações selecionadas do WordPress foram atualizadas',
                failed: 'Falha ao atualizar instalações selecionadas do WordPress',
            },
            configureUpdates: {
                success: 'As configurações de atualização foram alteradas',
                failed: 'Falha ao alterar as configurações de atualização',
            },
            detach: {
                success: 'A instalação do WordPress foi separada do WP Guardian. Ela será ignorada durante as verificações subsequentes do servidor. Para que a instalação fique visível para o WP Guardian, remova o arquivo .wp-toolkit-ignore do diretório de instalação',
                failed: 'Falha ao desanexar a instalação do WordPress',
            },
            fixVulnerabilitiesViaUpdate: {
                success: 'As vulnerabilidades foram corrigidas ao instalar as atualizações',
                failed: '{count, plural, one {Falha ao corrigir vulnerabilidades em # instalação} other {Falha ao corrigir vulnerabilidades em # instalações}}',
            },
            mitigationDeactivateAsset: {
                success: 'As vulnerabilidades foram mitigadas através da desativação do plugin',
                failed: '{count, plural, one {Falha ao mitigar a vulnerabilidade por meio da desativação do plugin em # instalação} other {Falha ao mitigar a vulnerabilidade por meio da desativação do plugin em # instalações}}',
            },
            mitigationActivateAsset: {
                success: 'Os plugins desativados devido a vulnerabilidades foram reativados',
                failed: '{count, plural, one {Falha ao reativar plugins vulneráveis anteriormente em # instalação} other {Falha ao reativar plugins vulneráveis anteriormente em # instalações}}',
            },
        },
        vulnerabilities: {
            fixVulnerabilitiesViaUpdate: {
                success: 'As vulnerabilidades foram corrigidas ao instalar as atualizações',
                failed: '{count, plural, one {Falha ao corrigir # vulnerabilidade} other {Falha ao corrigir # vulnerabilidades}}',
            },
            mitigationDeactivateAsset: {
                success: 'As vulnerabilidades foram mitigadas através da desativação do plugin',
                failed: '{count, plural, one {Falha ao mitigar # vulnerabilidade através da desativação do plugin} other {Falha ao mitigar # vulnerabilidades através da desativação do plugin}}',
            },
            mitigationActivateAsset: {
                success: 'Os plugins desativados devido a vulnerabilidades foram reativados',
                failed: '{count, plural, one {Falha ao reativar os plugins afetados por # vulnerabilidade} other {Falha ao reativar os plugins afetados por # vulnerabilidades}}',
            },
            ignoreVulnerabilities: {
                success: 'As vulnerabilidades foram ignoradas',
                failed: '{count, plural, one {Falha ao ignorar # vulnerabilidade} other {Falha ao ignorar # vulnerabilidades}}',
            },
            cancelIgnoreVulnerabilities: {
                success: 'As vulnerabilidades não estão mais sendo ignoradas',
                failed: '{count, plural, one {Falha ao deixar de ignorar # vulnerabilidade} other {Falha ao deixar de ignorar # vulnerabilidades}}',
            },
        },
    },
    QuickStartDialog: {
        title: 'Início Rápido',
        QuickStartWizard: {
            startStepName: 'Início',
            virtualPatchesStepName: 'Vulnerability protection',
            notificationsStepName: 'Notificações',
            securityCheckScheduleStepName: 'Verificação de segurança',
            updateStepName: 'Atualizações',
        },
        QuickStartStep: {
            pagination: '{current} de {total}',
            ok: 'OK',
            next: 'Próximo',
            back: 'Voltar',
        },
        StartStep: {
            title: 'Boas-vindas ao WP Guardian',
            text: 'Configure tudo o que é necessário para manter seus websites WordPress seguros',
        },
        SecurityCheckScheduleStep: {
            checkingTimeTitle: 'Tempo de verificação de segurança',
            checkingTimeText: 'O WP Guardian verifica todos os servidores e websites em busca de alterações a cada 24 horas. Esta é uma operação que exige bastante desempenho, consome tempo e utiliza recursos do servidor.',
            vulnerabilitiesUpdateTitle: 'Atualizações do banco de dados de vulnerabilidades',
            vulnerabilitiesUpdateText: 'O banco de dados de vulnerabilidades é atualizado automaticamente a cada hora. Esta operação não afeta o desempenho do servidor e do website',
        },
        VirtualPatchesStep: {
            automatedProtection: 'Automated vulnerability protection',
            automatedProtectionText: 'Once enabled, vulnerability protection works automatically, protecting sites as soon as vulnerabilities are discovered and protection rules are available.',
            howItWorks: 'Como funciona',
            howItWorksText: 'Um plugin é instalado dentro do WordPress, atuando como um Firewall de Aplicação Web. Ele não modifica os arquivos do WordPress ou o conteúdo do website, mas aplica regras de firewall visando apenas as vulnerabilidades presentes em um website.',
            poweredBy: 'desenvolvido por {providerLogo}',
        },
        UpdatesStep: {
            next: "Próximo",
            updateSettings: 'Configurações de Atualização',
            updateSettingsText: 'Você pode forçar atualizações automáticas em todos os websites conectados ao WP Guardian, incluindo websites adicionados posteriormente. Isso pode ser configurado posteriormente através do website ou servidor.',
            backup: 'Faça backup antes da atualização',
            backupText: 'Habilitar atualizações seguras fará com que o WP Guardian faça backup de um website antes de atualizá-lo. Se algo der errado, o website será restaurado automaticamente do backup.',
            safeUpdates: 'Atualizações seguras',
            customizedSettings: 'Configurações personalizadas',
        },
    },
    GlobalTaskManagerDrawer: {
        title: 'Registro de tarefas',
        Toolbar: {
            buttons: {
                groupTitle: 'Botões',
                allTasks: 'Todas tarefas',
                failed: 'Com falha',
            },
            filters: {
                groupTitle: 'Filtros',
                typeUpdate: 'Instalar atualizações',
                refresh: 'Atualizar dados',
                typeFindNewSites: 'Encontrar novos websites',
                typeConfigureUpdates: 'Configurar atualizações',
                typeFixVulnerabilitiesViaUpdate: 'Corrigir vulnerabilidades com uma atualização',
                mitigationDeactivateAsset: 'Mitigar vulnerabilidades através da desativação do plugin',
                mitigationActivateAsset: 'Reativar plugins desativados devido a vulnerabilidades',
                ignoreVulnerabilities: 'Ignorar vulnerabilidades',
                cancelIgnoreVulnerabilities: 'Deixar de ignorar vulnerabilidades',
                setDailyTaskTime: 'Alterar cronograma de verificação de segurança',
            },
            search: {
                title: 'Pesquisar',
                placeholder: 'Pesquisar',
            },
        },
        TasksList: {
            columns: {
                task: 'Tarefa',
                status: 'Status',
                started: 'Iniciado',
                finished: 'Concluído',
            },
            taskTypes: {
                fallBack: "Operação com código ''{type}''",
                demo: 'Operação em um servidor de demonstração',
                update: 'Instalar atualizações',
                refresh: 'Atualizar dados',
                detach: 'Separar',
                findNewSites: 'Encontrar novos websites',
                configureUpdates: 'Configurar atualizações',
                setDailyTaskTime: 'Alterar cronograma de verificação de segurança',
                protect: 'Habilite a proteção contra vulnerabilidade',
                unprotect: 'Disable vulnerability protection',
                disconnect: 'Desconectar',
                fixVulnerabilitiesViaUpdate: 'Corrigir vulnerabilidades com uma atualização',
                changeInstallationPluginStatus: 'Alterar estado do plugin',
                mitigationDeactivateAsset: 'Mitigar vulnerabilidades através da desativação do plugin',
                mitigationActivateAsset: 'Reativar plugins desativados devido a vulnerabilidades',
                ignoreVulnerabilities: 'Ignorar vulnerabilidades',
                cancelIgnoreVulnerabilities: 'Deixar de ignorar vulnerabilidades',
            },
            taskSubTypesAffected: {
                servers: '{count, plural, one {# servidor} other {# servidores}}',
                sites: '{count, plural, one {# site} other {# sites}}',
                vulnerabilities: '{count, plural, one {# vulnerabilidade} other {# vulnerabilidades}}',
            },
            statuses: {
                success: 'Sucesso',
                inProgress: 'Em andamento',
                failed: 'Falhou',
                errors: 'Erros',
                unknown: 'Desconhecido',
            },
            emptyList: {
                title: 'Sem tarefas',
                description: 'Nenhuma tarefa foi executada ainda.',
            },
        },
    },
    IgnoreDoNotProtectMessage: {
        title: 'Websites com proteção desativada',
        description: '{count, plural, one {Your selection will affect <a># website</a> where vulnerability protection was disabled before. By default this site will be skipped.} other {Your selection will affect <a># websites</a> where vulnerability protection was disabled before. By default these sites will be skipped.}}',
        ignoreCheckbox: '{count, select, 1 {Reativar a proteção neste website} other {Reativar a proteção nestes websites}}',
    },
    useDateToTrialDisplayInfo: {
        noLicense: "Você não tem uma licença",
        trialDaysLeft: '{days, plural, one {Teste (# dia restante)} other {Teste (# dias restantes)}}',
        trialUntil: 'Teste',
    },
    PromoCodeForm: {
        promoCode: 'Seu código promocional',
        apply: 'Aplicar',
        activationFailure: 'Falha ao ativar o código promocional',
    },
    Layout: {
        ActivateTrial: {
            havePromoCode: 'Do you have a promo code?',
            applyPromoCode: 'Aplicar',
            trialTitle: 'Teste de 14 dias',
            trialText: 'You can enjoy a free trial with a limit of 50 sites to explore all product features. No payment details are required to start the trial.',
            activateTrial: 'Activate trial',
            becomePartner: 'Are you a WebPros partner? Contact your manager to get a special offer or <a>become a partner</a>',
            benefits: {
                benefitsTitle: 'WP Guardian Features',
            },
            activationFailure: 'Failed to activate trial',
        },
        LicenseName: {
            LicenseNotificationDialog: {
                chooseLicenseButton: 'Escolha uma Licença',
                contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
                contactResellerToPurchase: 'To purchase a license contact your service provider.',
            },
            notifications: {
                trialStarted: {
                    title: 'Seu teste começou!',
                    titleRetail: '{days, plural, one {Your # day trial has started!} other {Your # day trial has started!}}',
                    description: 'Welcome to WP Guardian! You can enjoy all WP Guardian features, including vulnerability protection, on up to {sitesLimit, plural, one {# website} other {# websites}}.',
                    descriptionExpiration: 'Your trial period will be active until {expirationDate, date, medium}.',
                    seeDetails: 'See Details',
                },
                trialExpired: {
                    title: 'Seu período de teste terminou',
                    description: 'Your trial has expired. You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled. Please choose a license to continue using WP Guardian.',
                },
                licenseTerminated: {
                    title: 'Your license has been terminated',
                    description: 'You still have access to your sites, but vulnerability protection, mass actions, safe updates, and automatic updates management are disabled.',
                    descriptionContactSupport: 'Please choose a license to continue using WP Guardian. If you believe this license termination is a mistake, reach out to our support via chat or <a>contact form</a>.',
                },
            },
            noLicense: 'Sem licença',
            expired: '(vencido)',
        },
        HeaderAddon: {
            FeedbackButton: {
                feedbackBtn: 'Deixar seus comentários',
            },
        },
        tabs: {
            servers: 'Servidores',
            installations: 'Sites WordPress',
            vulnerabilities: 'Vulnerabilidades',
        },
        Footer: {
            Links: {
                followUs: 'Siga-nos:',
                company: 'Empresa',
                aboutWebpros: 'Sobre o WebPros',
                cookiehub: 'Configurações de Cookies',
                privacyPolicy: 'Política de Privacidade',
                knowledgeBase: 'Base de Conhecimento',
                documentation: 'Documentação',
                helpCenter: 'Central de Ajuda',
                contactUs: 'Contato',
                legal: 'Jurídico',
            },
            copyright: '© {year} WebPros International GmbH. Todos os direitos reservados. WP Guardian e o logotipo WP Guardian são marcas comerciais da WebPros International GmbH.',
        },
        Header: {
            UpdateSettingsCard: {
                title: 'Configurações de atualização automática',
            },
            CardLoader: {
                loading: 'Carregando...',
            },
            InstallationsCard: {
                title: 'Sites WordPress',
                vulnerable: '{count} Vulnerável',
                outdated: '{count} Desatualizado',
                protected: '{count} Protegido',
                safe: '{count} OK',
                notOperable: '{count} Inoperável',
                poweredBy: '<whiteSpaceNoWrap>Desenvolvido por {providerLogo}</whiteSpaceNoWrap>',
            },
            VulnerabilitiesCard: {
                title: 'Vulnerabilidades',
                databaseUpdate: 'Atualização do Banco de Dados',
                momentAgo: 'há instantes atrás',
                noData: 'Sem dados',
                lastUpdateMinutes: '{minutes, plural, one {há # minuto} other {há # minutos}}',
                lastUpdateHours: '{hours, plural, one {há # hora} other {há # horas}}',
                lastUpdateDays: '{days, plural, one {há # dia} other {há # dias}}',
                exploited: '{count, plural, one {# critical} other {# critical}}',
                highestScore: 'Maior pontuação CVSS',
                needsProtection: '{count, plural, one {# precisa de proteção} other {# precisam de proteção}}',
            },
            QuickStartCard: {
                title: 'Início Rápido',
                ctaBlockTitle: 'Configurar segurança',
                ctaBlockText: 'em alguns cliques',
            },
        },
        UserMenuAddon: {
            quickStart: 'Quick start',
            license: 'Detalhes da licença',
        },
    },
    Vulnerabilities: {
        updateByProtect: 'Não há atualizações que corrijam esta vulnerabilidade agora.',
        columns: {
            position: 'Onde',
        },
        Onboarding: {
            title: 'Nenhuma Vulnerabilidade Encontrada',
            description: 'Conecte servidores para encontrar websites WordPress e verifique se há vulnerabilidades e atualizações.',
        },
        InfoColumn: {
            MitigatedLabel: {
                mitigatedTitle: 'Mitigada',
                mitigatedByProtectionTitle: 'Mitigated by vulnerability protection',
                mitigatedByDeactivationTitle: 'Mitigado pela desativação do plugin',
                tooltip: {
                    'singleSiteMitigatedByOther': 'O website afetado por esta vulnerabilidade está atualmente protegido por meios externos ao WP Guardian.',
                    'mitigatedByOther': 'Os websites afetados por esta vulnerabilidade estão atualmente protegidos por meios externos ao WP Guardian',
                    'mitigatedBySeveral': 'Some websites affected by this vulnerability are currently secured by vulnerability protection, and some by plugin deactivation.',
                    'mitigatedByOtherAndSeveral': 'Websites affected by this vulnerability are currently secured by various means, including vulnerability protection, plugin deactivation, and means outside of WP Guardian',
                },
            },
        },
        VulnerabilitySources: {
            discoveryDate: '{date}',
            source: 'Fonte:',
        },
        VulnerabilityDescription: {
            copyrightNotice: 'Este registro contém material sujeito a direitos autorais.',
            wordfenceCopyright: 'Copyright 2012-2024 Defiant Inc. Licença: a Defiant, pela presente, concede a você uma licença de copyright perpétua, mundial, não exclusiva, isenta de cobranças, isenta de royalties e irrevogável para reproduzir, preparar trabalhos derivados de, exibir publicamente, performar publicamente, sublicenciar e distribuir essas informações de vulnerabilidade de software. Qualquer cópia das informações de vulnerabilidade de software que você fizer para tais propósitos está autorizada, contanto que você inclua um hyperlink a tal registro de vulnerabilidade e reproduza a designação de copyright da Defiant e essa licença na determinada cópia.',
            readMore: 'Leia mais',
        },
        Toolbar: {
            groupFilterMenu: 'Filtrar',
            groupSearch: 'Pesquisar',
            manage: 'Gerenciar',
            patch: 'Protection',
            searchPlaceholder: 'Encontrar vulnerabilidade...',
            UpdateButton: {
                update: 'Instalar atualizações',
                cancel: 'Cancelar',
                noSelection: 'Selecione pelo menos uma vulnerabilidade para resolver por meio de atualização.',
                updateConfirmation: '{totalVulnerabilities, plural, one {Você está prestes a atualizar vários sites para corrigir # vulnerabilidade} other {Você está prestes a atualizar vários sites para corrigir # vulnerabilidades}}. O processo de atualização não poderá ser interrompido após ser iniciado.',
                updateConfirmationPluginTitle: 'Vários plugins foram desativados',
                updateConfirmationPluginCheckbox: 'Reativar plugins desabilitados por políticas de segurança',
                updateConfirmationPluginCheckboxHint: 'Os plugins vulneráveis desabilitados pelas políticas de segurança serão automaticamente reativados se suas vulnerabilidades forem corrigidas nas atualizações.',
                numberOfSites: 'Número de websites a serem atualizados:',
                numberOfSitesUnmanaged: 'Número de websites a serem atualizados (websites não gerenciados serão ignorados):',
                sitesCalculating: 'Calculando...',
                sitesCalculatingError: 'Falha ao contar',
                affectedSites: '{count, plural, one {{count} website} other {{count} websites}}',
                unmanagedLabel: '{count, plural, one {# não gerenciado} other {# não gerenciados}}',
                updateByProtect: 'Não há atualizações que corrijam esta vulnerabilidade agora.',
                upgradeLicense: 'Faça o Upgrade da Licença',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be updated because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
            IgnoreButton: {
                ignore: 'Ignorar',
                noSelection: 'Selecione pelo menos uma vulnerabilidade para ignorar ou deixar de ignorar',
            },
            PatchButton: {
                affectedSites: '{count, plural, one {{count} website} other {{count} websites}}',
                affectedVulnerabilities: 'You are going to enable vulnerability protection to address {count, plural, one {# vulnerability} other {# vulnerabilities}}{total, plural, one { (out of # selected)} other { (out of # selected)}}. A small plugin will be automatically installed for applying protection rules.',
                cancel: 'Cancelar',
                noAffectedSites: 'All selected vulnerabilities are either already addressed or there are no protection rules for them yet.',
                noSelection: 'Select at least one vulnerability to address via protection rules.',
                numberOfSites: 'Number of sites to protect:',
                numberOfSitesUnmanaged: 'Number of sites to protect (unmanaged sites will be skipped):',
                patch: 'Habilitar Proteção',
                providerLogo: '<whiteSpaceNoWrap>Desenvolvido por {providerLogo}</whiteSpaceNoWrap>',
                sitesCalculating: 'Calculando...',
                sitesCalculatingError: 'Falha ao calcular o número de websites afetados',
                skippedVulnerabilities: '{count, plural, one {# vulnerability will be skipped because it is either already addressed or there are no protection rules for it yet.} other {# vulnerabilities will be skipped because they are either already addressed or there are no protection rules for them yet.}}',
                unmanagedLabel: '{count, plural, one {# não gerenciado} other {# não gerenciados}}',
                update: 'Habilitar Proteção',
                upgradeLicense: 'Faça o Upgrade da Licença',
                vulnerabilitiesOnUnmanagedSites: 'Selected vulnerabilities cannot be addressed because they are located on sites unmanaged by WP Guardian. Consider upgrading your license to enable vulnerability protection.',
            },
        },
        Filters: {
            filterStateExploited: 'Critical',
            filterStateCanBeProtected: 'Precisa de proteção',
            filterStateIgnored: 'Ignorada',
            filterComponentCore: 'Core',
            filterComponentPlugin: 'Plugin',
            filterComponentTheme: 'Tema',
            everywhere: 'Em todo lugar',
        },
        UpdateConfirmation: {
            description: '{count, plural, one {Você está prestes a atualizar # site.} other {Você está prestes a atualizar # sites.}} O processo de atualização não poderá ser interrompido após ser iniciado.',
        },
        Widgets: {
            disablePlugin: {
                title: 'Plugins',
                installations: '{enabledCount, plural, one {# ativo} other {# ativos}}',
                installationsDeactivated: '{count, plural, one {Desativado em # site} other {Desativado em # sites}}',
                confirmationDeactivation: '{count, plural, one {Você está prestes a desativar o plugin vulnerável em # site.} other {Você está prestes a desativar o plugin vulnerável em # sites.}} Esse processo não poderá ser interrompido após ser iniciado.',
                confirmationReactivation: '{count, plural, one {Você está prestes a reativar o plugin desativado anteriormente por razões de segurança em # site. Essa ação irá expor o site a possíveis explorações.} other {Você está prestes a reativar o plugin desativado por razões de segurança em # sites. Essa ação irá expor esses sites a possíveis explorações.}} Esse processo não pode ser interrompido após ser iniciado.',
                dropdownDeactivate: 'Desativar',
                buttonDeactivateWithCount: 'Desativar {count}',
                buttonReactivateWithCount: 'Reativar {count}',
                buttonDeactivateAll: 'Desativar tudo',
                buttonReactivate: 'Reativar',
                tooltipAllPluginsDeactivatedByUser: 'O WP Guardian não reativa os plugins desativados manualmente pelos administradores do site.',
            },
            updates: {
                title: 'Instalar atualizações',
                installations: '{count, plural, one {# site} other {# sites}}',
                waitingForUpdatesTitle: 'Ainda não há atualizações',
                buttonName: 'Atualizar tudo',
            },
            patches: {
                allProtected: '{count, plural, one {# site} other {# sites}}',
                allProtectedPopover: 'Sites using vulnerability protection by Patchstack. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                cancelButton: 'Cancelar',
                enableButton: 'Habilitar',
                installations: '{count} de {total}',
                protectAllSites: 'Proteger todos',
                protectAllSitesConfirmation: 'You are going to enable vulnerability protection on {count, plural, one {# site} other {# sites}}. A small plugin will be automatically installed for applying protection rules. This will address all current and future patchable vulnerabilities on affected sites. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                title: 'Proteger todos websites',
                titleAllProtected: 'Tudo protegido',
            },
        },
        installations: '{count, plural, one {# site} other {# sites}}',
        servers: '{count, plural, one {# servidor} other {# servidores}}',
    },
    Onboarding: {
        title: 'Nenhum Servidor Conectado',
        description: 'Conecte servidores para encontrar websites WordPress e verifique se há vulnerabilidades e atualizações.',
        demoServerWasAddedSuccessfully: 'O servidor de demonstração foi adicionado.',
        connectServers: '<addServerButton>Conecte seu próprio servidor</addServerButton> <delimiter>ou</delimiter> <addDemoServerButton>Use nosso servidor de demonstração</addDemoServerButton>',
        hint: 'O servidor de demonstração é fornecido pela equipe WP Guardian. Ele contém vários websites WordPress, para que você possa explorar facilmente a funcionalidade do produto antes de conectar seu próprio servidor.',
        feedbackButton: 'Deixar seus comentários',
        demoServerPopover: {
            description: 'O servidor de demonstração funciona no modo apenas de leitura. Operações invasivas, como instalar atualizações, não mudarão o estado dos websites no servidor de demonstração.',
            connectBtn: 'Conectar',
        },
    },
    FilteredDataListTitle: {
        searchByQuery: 'Procurar: {searchValue}',
        searchByServer: 'Servidor: {searchValue}',
        searchByVulnerability: 'Vulnerabilidade: {searchValue}',
        showAll: 'Exibir todos',
    },
    Servers: {
        sitesAreNotOperable: '{notOperable, plural, one {# dos websites em servidores selecionados está atualmente inoperante} other {# dos websites em servidores selecionados está atualmente inoperante}}. A operação não será realizada nesses websites porque eles estão com problemas ou indisponíveis.',
        state: {
            installingAgent: 'Agente de instalação',
            connectionError: 'Erro de conexão',
            agentInstallationError: 'Instalação do agente não concluída',
            connectionBlocked: 'Conexão bloqueada',
            synchronizationInProgress: 'Sincronização em andamento',
            connectionErrorStatusMessage: 'A conexão ao servidor não pode ser estabelecida corretamente.',
            connectionErrorFixDescription: 'Verifique os registros e as configurações em seu servidor ({ipAddress}) e aguarde a nova verificação.',
            connectionBlockedStatusMessage: 'A conexão ao servidor está bloqueada pelo firewall.',
            connectionBlockedFixDescription: 'Verifique se a porta 878 no servidor {ipAddress} está aberta e aguarde pela nova verificação.',
            agentInstallationErrorMessage: 'Instalação do agente não concluída',
            agentInstallationErrorFixDescription: "Não recebemos uma resposta do agente do WP Guardian sobre a conclusão bem-sucedida da instalação dentro de 5 minutos. Isso significa que a instalação ainda está acontecendo ou que ela falhou. Verifique o progresso da instalação no console do servidor, ou veja o registro de instalação no arquivo \"/root/wp-agent-install.log\"",
        },
        ConnectDrawer: {
            title: 'Conecte seu servidor',
            done: 'Feito',
            tabs: {
                connectDroplet: 'Connect droplet',
                connectServer: 'Conectar servidor',
            },
        },
        AddDroplet: {
            videoDialogTitle: 'Connecting DigitalOcean droplet to WP Guardian',
            docsLinkText: 'Need help with connecting droplets? Check out the documentation',
            goToDoButton: 'Go to DigitalOcean to connect the droplet',
            head1: '1. Copy the following snippet',
            head2: '2. Activate the snippet in droplet console',
            paragraph1singleDroplet: 'Press the button below to open the droplet console, paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
            paragraph1multipleDroplets: 'Press the button below, choose a droplet you\'d like to connect to WP Guardian. From the detail page, click the Access tab in the left menu, then launch the droplet console and paste the snippet and press "Enter". Wait for the installation process to complete and then <bold>return to WP Guardian browser tab.</bold>',
        },
        AddServer: {
            done: 'Feito',
            paragraph1: 'Abra a porta 878 para conexões de entrada dos seguintes IPs em cada servidor que você deseja conectar: 34.254.37.129, 52.51.23.204 e 52.213.169.7',
            paragraph2: 'Copie e cole o seguinte snippet no console de servidores e aguarde o processo de instalação ser concluído',
            errors: {
                failedToGenerateToken: 'Falha ao gerar o comando de instalação.',
            },
            documentationLink: 'Need help with connecting servers? Check out the documentation',
        },
        InstallationSnippet: {
            copy: 'Copiar',
            copied: 'O snippet foi copiado',
            refreshTooltip: 'O comando de conexão é válido para um número ilimitado de servidores durante este tempo. Atualize o comando se precisar de mais tempo',
        },
        SettingsDrawer: {
            title: 'Configurações',
            subTitle: {
                single: 'Servidor: {serverName}',
                mass: '{count, plural, one {Settings will be applied to # selected server} other {Settings will be applied to # selected servers}}',
            },
            tabs: {
                updates: 'Atualizações',
                dailyTaskTime: 'Cronograma',
            },
            loadFailed: 'Falha ao carregar as configurações.',
            buttonSave: 'Salvar',
            successToast: 'As configurações foram salvas.',
            Updates: {
                customizedSection: {
                    description: 'As configurações de atualização serão aplicadas a todos os websites atuais e recém-adicionados neste servidor sem configurações de atualização personalizadas',
                    checkboxDescription: 'Aplique também configurações a todos websites com configurações de atualização personalizadas',
                },
            },
            DailyTaskTime: {
                description: 'O WP Guardian verifica novas vulnerabilidades e atualizações disponíveis a cada 24 horas. Como esta é uma operação que exige bastante desempenho, você pode programá-la para ser executada em um horário específico',
                unsetOption: 'Definido por configurações globais',
            },
        },
        RemoveServersButton: {
            remove: 'Desconectar',
            noSelectedServers: 'Selecione pelo menos um servidor para desconectar.',
            removeDescription: 'O agente do WP Guardian não é removido automaticamente ao desconectar o servidor. Use o script do desinstalador para remover o agente do WP Guardian (<a>instrução</a>).',
            removeSingleServerConfirmation: 'Desconectar {selectedServerTitle}?',
            removeSeveralServersConfirmation: '{selectedServersCount, plural, one {Desconectar <b>#</b> servidor?} other {Desconectar <b>#</b> servidores?}}',
        },
        ListActions: {
            remove: 'Desconectar',
            refresh: 'Atualizar',
            findNewSites: 'Encontrar Novos Websites',
            settings: 'Configurações',
            disablePatch: 'Desproteger',
            update: 'Atualizar Todos os Websites',
            manageLabels: 'Aplicar tag',
            protectionEnabled: 'All existing and new sites on this server will be protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
        },
        DisableProtectionDialog: {
            title: 'Disabling Vulnerability Protection On A Server',
            paragraph1: 'Disabling vulnerability protection can lead to compromising vulnerable sites, which in turn could affect the security of the entire server. It is recommended to keep vulnerability protection enabled at all times.',
            confirm: 'Disable Vulnerability Protection',
            cancel: 'Cancelar',
        },
        SiteHealthColumn: {
            wpSitesCount: '{count, plural, one {# site WP} other {# sites WP}}',
            notOperable: '{count} Inoperável',
            vulnerable: '{count} Vulnerável',
            vulnerabilities: '{count} Vulnerabilidades',
            outdated: '{count} Desatualizado',
            lastCheck: 'Última verificação: {dateTime}',
            lastCheckToday: 'Última verificação: hoje às {time}',
            unsupportedAgent: 'Versão de agente não suportada',
            coreOutdated: 'Desatualizado',
        },
        columns: {
            server: 'Servidor',
        },
        OutdatedAgentError: {
            message: 'A versão do agente do WP Guardian (wp-agent) no servidor está desatualizada',
            agentVersion: '{version, select, _ {A versão do seu agente WP Guardian é N/A.} other {A versão do seu agente WP Guardian é {version}.}}',
            updateDescription: 'Atualizar o pacote do wp-agent para a versão {wpAgentMinSupportedVersion} ou superior.',
        },
        ServerDetails: {
            updateSettingsCardTitle: 'Configurações de Atualização',
            ipAddress: '<b>IP do servidor:</b> {address}',
        },
        Toolbar: {
            groupFilterMenu: 'Filtrar',
            manage: 'Gerenciar',
            remove: 'Desconectar',
            add: 'Conectar',
            groupFilter: 'Filtrar',
            groupSearch: 'Pesquisar',
            searchPlaceholder: 'Localizar servidor...',
            noSelectedServers: 'Selecione pelo menos um servidor para desconectar.',
            FilterPanel: {
                filterAlerts: 'Alertas',
                labelsPlaceholder: 'Todos as tags',
            },
            RefreshButton: {
                buttonText: 'Atualizar',
                noSelectedServers: 'Selecione pelo menos um servidor para atualizar.',
            },
            FindNewSitesButton: {
                buttonText: 'Encontrar Novos Websites',
                noSelectedServers: 'Selecione pelo menos um servidor para encontrar novos websites WordPress.',
            },
            UpdateButton: {
                buttonText: 'Instalar atualizações',
                confirmButtonText: 'Atualizar tudo',
                noSelectedServers: 'Selecione pelo menos um servidor para instalar todas as atualizações disponíveis em todos os websites WordPress.',
                updateSingleServerConfirmation: 'Todas as atualizações disponíveis serão instaladas em todos os websites WordPress localizados no servidor <b>{selectedServerTitle}</b>.',
                updateSeveralServersConfirmation: '{selectedServersCount, plural, one {Todas as atualizações disponíveis serão instaladas em todos os sites WordPress localizados em <b>#</b> servidor selecionado.} other {Todas as atualizações disponíveis serão instaladas em todos os sites WordPress localizados em <b>#</b> servidores selecionados.}}',
            },
            ProtectButton: {
                buttonText: 'Proteger',
                noSelectedServers: 'Select at least one server to enable vulnerability protection for all current and future WordPress sites.',
                singleConfirmation: 'Vulnerability protection will be enabled on all existing and new WordPress sites on the server <b>{selectedServerTitle}</b>. A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                multipleConfirmation: '{selectedServersCount, plural, one {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected server.} other {Vulnerability protection will be enabled on all existing and new WordPress sites on <b>#</b> selected servers.}} A small plugin will be automatically installed on each site for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            },
            LabelButton: {
                buttonText: 'Aplicar tags',
            },
            SettingsButton: {
                buttonText: 'Configurações',
                noSelectedServers: 'Select at least one server to change the settings.',
            },
        },
        ConnectServersButton: {
            buttonText: 'Conectar',
        },
    },
    Settings: {
        IgnoreLowRiskSection: {
            title: 'Ignorando automaticamente vulnerabilidades de baixo risco',
            description: 'Some vulnerabilities have very low risk of being exploited, so they are unlikely to receive a proper fix or a protection rule. To reduce alert fatigue, you can ignore them automatically. You can always review these vulnerabilities on the Vulnerabilities tab and unignore them, if needed.',
            ignoreCheckbox: 'Ignore automaticamente vulnerabilidades de baixo risco',
            warningMessage: 'Se esta configuração estiver desativada, todos os rótulos "ignorados automaticamente" serão removidos e todas as vulnerabilidades ignoradas automaticamente ficarão totalmente visíveis novamente',
        },
    },
    Installations: {
        yes: 'Sim',
        no: 'Não',
        update: 'Instalar atualizações',
        refresh: 'Atualizar',
        detach: 'Separar',
        updateSettings: 'Configurações de Atualização',
        sitesAreNotOperable: '{notOperable, plural, one {# dos websites selecionados está atualmente inoperante} other {# dos websites selecionados estão atualmente inoperantes}}. A operação não será realizada nestes websites porque eles estão com problemas ou indisponíveis.',

        DetachInstallationsButton: {
            detach: 'Separar',
            detachSingleInstallationConfirmation: 'Separar <b>{selectedInstallationTitle}</b> do WP Guardian? Instalações separadas serão ignoradas durante as verificações subsequentes do servidor.',
            detachSeveralInstallationsConfirmation: 'Separar <b>{selectedInstallationsCount}</b> Instalações do WP Guardian? Instalações separadas serão ignoradas durante as verificações subsequentes do servidor.',
        },
        Onboarding: {
            title: 'Nenhum Website WordPress Encontrado',
            description: 'Conecte servidores para encontrar sites WordPress e verifique se há vulnerabilidades e componentes desatualizados.',
        },
        columns: {
            website: 'Website',
            server: 'Servidor',
            synchronized: 'Conectado',
            lastCheck: 'Última Verificação',
            protect: 'Proteger',
            siteHealth: 'Estado do Website',
        },
        FiltersPanel: {
            groupFilterMenu: {
                title: 'Menu de Filtro',
                filterAll: 'Todos',
                filterVulnerable: 'Vulnerável',
                filterOutdated: 'Desatualizado',
                filterNotOperable: 'Inoperável',
                filterProtected: 'Protegido',
                filterUnmanaged: 'Não gerenciado',
                filterNotProtected: 'Desprotegido',
                filterSafe: 'Nenhum problema',
            },
            labelsPlaceholder: 'Todos as tags',
        },
        Toolbar: {
            groupFilterMenu: 'Filtrar',
            groupSearch: 'Pesquisar',
            searchPlaceholder: 'Encontre website...',
            groupActions: 'Ações',
            groupUpdate: 'Instalar atualizações',
            groupProtect: 'Proteger',
            subGroupActions: 'Ações',
            buttons: {
                update: {
                    text: 'Instalar atualizações',
                    noSelection: 'Selecione pelo menos um website para instalar todas as atualizações disponíveis.',
                },
                refresh: {
                    text: 'Atualizar',
                    noSelection: 'Selecione pelo menos um website para atualizar.',
                },
                protect: {
                    text: 'Proteger',
                    noSelection: 'Select at least one site to enable vulnerability protection on.',
                },
                label: {
                    text: 'Aplicar tag',
                },
                settings: {
                    text: 'Configurações',
                    noSelection: 'Select at least one site to change the settings.',
                },
            },
            UpdateInstallationPopover: {
                updateSingleInstallationConfirmation: 'Atualização <b>{selectedInstallationTitle}</b>?',
                updateBatchInstallationsConfirmation: '{selectedInstallationsCount, plural, one {Você está prestes a atualizar <b>#</b> site WordPress} other {Você está prestes a atualizar <b>#</b> sites WordPress}}',
                confirmButton: 'Atualizar',
                cancelButton: 'Cancelar',
            },
            ProtectInstallationsPopover: {
                protectInstallationSingleConfirmation: 'Enable vulnerability protection on <b>{selectedInstallationTitle}</b>? A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                protectInstallationsConfirmation: '{selectedInstallationsCount, plural, one {You are about to enable vulnerability protection on <b>#</b> WordPress site. A small plugin will be automatically installed for applying protection rules.} other {You are about to enable vulnerability protection on <b>#</b> WordPress sites. A small plugin will be automatically installed on each site for applying protection rules.}} <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                confirmButton: 'Proteger',
                cancelButton: 'Cancelar',
            },
        },
        SiteHealthColumn: {
            outdated: '{count} Desatualizado',
            safe: 'OK',
            notOperable: 'Inoperável',
            unsupportedAgent: 'Versão de agente não suportada',
            vulnerabilities: '{count} Vulnerabilidades',
            lastCheckAtTooltip: 'Última verificação: {lastCheckDateTime}',
        },
        SettingsDrawer: {
            successToast: 'As configurações foram salvas.',
            title: 'Configurações de Atualização',
            subTitle: {
                single: 'Website: {installationName}',
                mass: '{count, plural, one {Settings will be applied to # selected website} other {Settings will be applied to # selected websites}}.',
                massUnmanagedAndUnoperableSitesWillBeSkiped: '{notOperableCount, plural, one {# inoperable site} other {# inoperable sites}} and {unmanagedCount, plural, one {# unmanaged site} other {# unmanaged sites}} will be skipped.',
                massUnoperableSitesWillBeSkiped: '{unoperableCount, plural, one {# inoperable site will be skipped} other {# inoperable sites will be skipped} }.',
                massUnmanagedSitesWillBeSkiped: '{unmanagedCount, plural, one {# unmanaged site will be skipped} other {# unmanaged sites will be skipped} }.',
            },
            buttonSave: 'Salvar',
            confirmationPopover: {
                confirmBtn: 'Salvar',
                description: {
                    single: 'Você está prestes a modificar as configurações de atualização do website WordPress {installationName}. Continuar?',
                    mass: '{count, plural, one {You are about to modify update settings for # WordPress site} other {You are about to modify update settings for # WordPress sites} }. Continue?',
                },
            },
            tabs: {
                updates: 'Atualizações',
            },
        },
        InstallationLabelsList: {
            enablePatching: 'Habilite a Proteção Contra Vulnerabilidade',
        },
        InstallationSummary: {
            updateAll: 'Atualizar tudo',
            updateSettings: 'Configurações de Atualização',
            upToDate: 'Atualizado',
            AssetSummary: {
                plugin: {
                    title: 'Plugins [{count}]',
                    update: 'Instalar atualizações de plugins',
                },
                theme: {
                    title: 'Temas [{count}]',
                    update: 'Instale atualizações de tema',
                },
                safe: 'OK',
                vulnerable: '{count} Vulnerável',
                outdated: '{count} Desatualizado',
                vulnerabilities: '{count} Vulnerabilidades',
                isOutdated: 'Desatualizado',
            },
            CoreSummary: {
                title: 'WordPress {version}',
                update: 'Atualizar para {version}',
                updateSettingsTitle: 'Configurações de Atualização',
                vulnerabilities: '{count, plural, one {# vulnerabilidade} other {# vulnerabilidades}}',
                isOutdated: 'Desatualizado',
            },
            unsupportedAgentError: 'A versão do wp-agent em um servidor está desatualizada. A versão mínima necessária é {wpAgentMinSupportedVersion}',
            notOperableUnknownError: 'Ocorreu um erro desconhecido durante a verificação do website em busca de vulnerabilidades.',
            hostname: 'Nome do host do servidor:',
            ipAddress: 'IP do servidor:',
            UpdateConfirmation: {
                title: 'Confirmar Atualização',
                description: '{component, select, plugin {This will install all available updates for plugins on this site. Continue?} theme {This will install all available updates for themes on this site. Continue?} core {This will update WordPress to the latest version. Continue?} other {}}',
                cancelButton: 'Cancelar',
                continueButton: 'Atualizar',
            },
        },
        ProtectControlCompact: {
            protectedLabel: 'Proteção contra vulnerabilidade por <whiteSpaceNoWrap>{providerLogo}</whiteSpaceNoWrap>',
            notProtected: {
                title: 'A proteção contra vulnerabilidade está indisponível',
                message: 'Protection rules address vulnerabilities on the fly to keep your site secure while you are waiting for an update with proper fix to be available. A small plugin will be automatically installed for applying protection rules. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
                patchBtn: 'Enable protection',
            },
            ProtectInfoPopover: {
                label: 'Vulnerabilidade ainda ativa?',
                message: 'A neutralização de um vulnerabilidade segue regras de proteção específicas. E por esse motivo as regras de proteção não estão disponíveis para eliminar uma vulnerabilidade particular.',
                noPatch: {
                    title: 'Tarefa em andamento',
                    description: 'As regras de vulnerabilidade de alto impacto geralmente estão disponíveis algumas horas após a publicação. Para vulnerabilidades com menos impacto, pode levar mais tempo para criar regras.',
                },
                unlikelyExploited: {
                    title: 'Baixo risco',
                    description: 'Algumas vulnerabilidades têm impacto mínimo ou não possuem métodos reais de exploração. Como eles não representam uma ameaça real aos sites, as regras de proteção para eles não são necessárias.',
                },
                dbMatch: {
                    title: 'Ausência no banco de dados',
                    description: 'Vulnerabilidades que estão presentes apenas no banco de dados do Wordfence ou não correspondem às entradas correspondentes do banco de dados do Patchstack não recebem regras de proteção.',
                },
                duplicatesHint: 'If you find duplicate vulnerabilities on your sites or have other feedback about vulnerability protection, please {link}',
                letUsKnow: 'nos informe',
            },
        },
        WebsiteDrawer: {
            title: 'Detalhes do Website WordPress',
            titleUpdateInProgress: 'Atualizando {title}',
            subtitleUpdateInProgress: 'Atualizando itens selecionadas',
            errors: {
                installationNotFound: "Não foi possível carregar informações para instalação com id {id}",
            },
            tabs: {
                vulnerabilities: {
                    updateConfirmation: {
                        cancelButton: 'Cancelar',
                        updateButton: 'Atualizar',
                        description: '{count, plural, one {Você está prestes a corrigir # vulnerabilidade por meio da atualização em <b>{website}</b>.} other {Você está prestes a corrigir # vulnerabilidades por meio da atualização em <b>{website}</b>.}} O processo de atualização não pode ser interrompido após ser iniciado.',
                        updateConfirmationPluginCheckbox: '{count, plural, one {Reativar plugin desabilitado pela política de segurança} other {Reativar plugind desabilitados pela política de segurança}}',
                        updateConfirmationPluginCheckboxHint: '{count, plural, one {O plugin vulnerável desativado por sua política de segurança será reativado automaticamente se sua vulnerabilidade for corrigida na atualização.} other {Os plugins vulneráveis desativados por sua política de segurança será reativado automaticamente se suas vulnerabilidades forem corrigidas nas atualizações.}}',
                    },
                    toolbar: {
                        actions: {
                            title: 'Ações',
                            buttons: {
                                update: 'Atualizar',
                                processing: 'Em processamento',
                            },
                        },
                        filters: {
                            title: 'Filtros',
                            groupFiltersTitle: 'Filtrar',
                            search: {
                                title: 'Pesquisar',
                                placeholder: 'Encontrar vulnerabilidade...',
                            },
                            groupFilterMenu: {
                                filterStateExploited: 'Critical',
                                filterStateCanBeProtected: 'Precisa de proteção',
                                filterStateIgnored: 'Ignorado',
                                filterComponentHeader: 'Onde',
                            },
                        },
                    },
                    title: 'Vulnerabilidades',
                    columns: {
                        component: 'Onde',
                    },
                    emptyList: {
                        title: 'Tudo está seguro',
                        description: 'Nenhuma vulnerabilidade conhecida encontrada neste site.',
                    },
                    waitingForUpdate: 'Nenhuma atualização disponível',
                    updateTo: 'Atualizar para {availableVersion}',
                    update: 'Atualizar',
                    disablePlugin: 'Desativar plugin',
                    enablePlugin: 'Ativar plugin',
                    updateByProtect: 'Não há atualizações que corrijam esta vulnerabilidade agora.',
                },
                outdated: {
                    updateConfirmation: {
                        description: '{count, plural, one {Você está prestes a atualizar # recurso em <b>{website}</b>.} other {Você está prestes a atualizar # recursos em <b>{website}</b>.}} O processo de atualização não pode ser interrompido após ser iniciado.',
                        cancelButton: 'Cancelar',
                        updateButton: 'Atualizar',
                    },
                    title: 'Desatualizado',
                    toolbar: {
                        actions: {
                            title: 'Ações',
                            buttons: {
                                update: 'Atualizar',
                            },
                        },
                        filters: {
                            groupFiltersTitle: 'Filtros',
                            groupSearchTitle: 'Pesquisar',
                            search: {
                                title: 'Pesquisar',
                                placeholder: 'Encontrar componente desatualizado...',
                            },
                        },
                    },
                    columns: {
                        name: 'Nome',
                        type: 'Onde',
                    },
                    emptyList: {
                        title: 'Tudo está atualizado',
                        description: 'As versões mais recentes do core, plugins e temas do WordPress estão instaladas neste website.',
                    },
                    updateTo: 'Atualizar para {version}',
                },
            },
        },
        Actions: {
            enableProtectButton: 'Proteger',
            disableProtectButton: 'Desproteger',
            protectionEnabled: 'This site is protected by vulnerability protection. <whiteSpaceNoWrap>Powered by {providerLogo}</whiteSpaceNoWrap>',
            openLabelsPopover: 'Aplicar tag',
        },
        DisableProtectionButton: {
            confirmButton: 'Desproteger',
            confirmationText: 'This site is protected by vulnerability protection due to the server-wide protection settings. Disabling protection on this site will prevent it from using all current and future protection rules. This will not affect protection settings for the corresponding server, but the site will receive a special label for identification.',
        },
    },
    Notifications: {
        title: 'Notificações',
        listEmptyTitle: 'Mantenha-se atualizado com os eventos mais importantes',
        listEmptyDescription: 'We will send you notifications when we find new critical vulnerabilities, a server is down, and so on. Stay tuned!',
        markAllAsRead: 'Marcar tudo como lido',
        deleteNotification: 'Apagar essa notificação',
        TitleColumn: {
            notificationContext: {
                newExploitedVulnerabilities: 'Critical vulnerabilities',
                licenseCreated: 'Nova licença ativa',
                licenseLimitReached: 'Limite de sites atingidos',
                licenseExpired: 'Licença vencida',
                licenseTerminated: 'Licença encerrada',
                trialCreated: 'Teste iniciado',
                trialExpired: 'Teste vencido',
                trialExpiredSoon: 'O teste expirará em {expirationDate}',
            },
            notificationTitle: {
                newExploitedVulnerabilities: 'New critical vulnerabilities detected: {count}.',
                licenseCreated: 'Sua nova chave de licença {licenseName} #{licenseKey} agora está ativa.',
                licenseLimitReached: '{limit, plural, one {Sua licença do WP Guardian permite gerenciar # website,} other {Sua licença do WP Guardian permite gerenciar # websites,}} {total, plural, one {mas atualmente, você tem # website conectado.} other {mas atualmente, você tem # websites conectados.}} Faça o upgrade da sua licença para gerenciar todos os seus websites.',
                vpsLicenseLimitReached: '{limit, plural, one {Your WP Guardian license allows managing # site,} other {Your WP Guardian license allows managing # sites,}} {total, plural, one {but you currently have # site connected.} other {but you currently have # sites connected.}} Please reach out to your service provider to upgrade your license.',
                licenseExpired: 'Your WP Guardian license has expired. Please renew your license to continue using all WP Guardian features.',
                vpsLicenseExpired: 'Your WP Guardian license has expired. Contact your service provider to renew your license.',
                licenseTerminated: 'Your WP Guardian license has been terminated. Contact your sales representative or partner success team to resolve this issue.',
                vpsLicenseTerminated: 'Your WP Guardian license has been terminated. Contact your service provider to resolve this issue.',
                retailLicenseTerminated: 'Your WP Guardian license has been terminated. You can purchase a new license. If you believe this termination is a mistake, please contact our support team via chat.',
                trialCreated: 'Seu período de teste do WP Guardian começou. Você tem acesso total a todos os recursos do WP Guardian por até {sitesLimit, plural, one {# website} other {# websites}} durante o período de teste.',
                trialExpired: "Seu período de teste do WP Guardian acabou. Escolha e compre uma licença para continuar usando o produto, ou diga porquê não ficou convencido no formulário de feedback acima.",
                trialExpiredSoon: 'Seu período de teste do WP Guardian expira em {expirationDate}. Considere comprar uma licença para continuar usando o produto.',
            },
            markAsRead: 'Marcar como lida',
            markAsUnread: 'Marcar como não lida',
        },
        settings: 'Configurações',
    },
    ConfirmNotificationsEmail: {
        title: 'Confirmação do e-mail de notificação',
        error: 'O link expirou',
        successMessage: 'O e-mail de notificação foi alterado.',
    },
    NotificationsSettingsDrawer: {
        title: 'Configurações de notificação',
        Content: {
            sendSectionTitle: 'Onde me notificar',
            email: 'E-mail',
        },
    },
    NotificationSettingsEventSection: {
        title: 'Notificações a serem enviadas fora do produto',
        newExploitedVulnerabilities: 'New critical vulnerabilities',
        licenseNotifications: 'Notificações de licença',
    },
    ChangeableNotificationsEmail: {
        dialogTitle: 'Alterar e-mail de notificação',
        buttonSave: 'Enviar e-mail',
        buttonCancel: 'Cancelar',
        buttonEdit: 'Editar',
        email: 'E-mail',
        description: 'Enviaremos um e-mail com instruções sobre como atualizar o endereço de e-mail usado para receber notificações.',
        successMessage: 'Um e-mail foi enviado para <b>{email}</b>. Siga as instruções neste e-mail para verificar seu novo endereço de e-mail.',
    },
    LicenseBenefit: {
        unlimitedRange: {
            title: 'Unlimited Scale',
            description: 'No limit on the number of connected servers and WordPress sites',
        },
        vulnerabilityDetection: {
            title: 'Detecção de vulnerabilidade',
            description: 'Atualizações em tempo real sobre novas vulnerabilidades do WordPress em websites conectados',
        },
        secureSites: {
            title: 'Mantendo websites WordPress seguros',
            description: 'We use Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) to proactively safeguard your WordPress sites in real-time',
        },
        autoUpdates: {
            title: 'Atualizações automáticas',
            description: 'Configurações de atualizações automáticas flexíveis disponíveis para servidores e websites',
        },
        safeUpdates: {
            title: 'Atualizações seguras',
            description: 'Os backups de pré-atualização fornecem reversão automática caso a atualização dê errado',
        },
        notifications: {
            title: 'Estado de saúde e notificações',
            description: 'Sempre saiba como seus websites WordPress estão indo',
        },
    },
    LicensePage: {
        benefits: {
            title: 'Garantindo a segurança dos websites do WordPress',
        },
        ExpirationLabel: {
            noLicense: "Você não tem uma licença",
            expiredLicense: 'Sua licença expirou',
        },
        LicenseHeading: {
            welcome: 'Boas-vindas ao WP Guardian',
            welcomeRetailTrial: '{days, plural, one {# day of trial left} other {# days of trial left}}',
            noLicense: 'Escolha sua licença',
            trial: 'Escolha sua licença',
            trialLicenseExpired: 'Seu período de teste terminou',
        },
        LicenseDescription: {
            licenseKey: 'Chave de licença #{number}',
            nextUpdate: 'Próxima atualização da chave de licença: {date}',
            expirationDate: 'Data de expiração: {date}',
            noLicense: 'A versão gratuita do WP Guardian inclui apenas informações de vulnerabilidade e atualizações manuais do website. Compre uma licença para acessar todos os recursos do WP Guardian.',
            trialJustStarted: 'Você tem acesso a todos os recursos do WP Guardian durante o período de teste. Após o teste, recomendaremos a licença mais adequada para você. Você também terá a opção de continuar usando o WP Guardian gratuitamente com algumas limitações.',
            trial: '{sites, plural, one {Sua avaliação é por tempo limitado e permite gerenciar até # website WordPress.} other {Sua avaliação é por tempo limitado e permite gerenciar até # websites WordPress.}} Compre uma licença caso opte por continuar usando todos os recursos atuais e futuros do WP Guardian.',
            limit: '{sites, plural, one {Sua licença permite gerenciar até # site WordPress.} other {Sua licença permite gerenciar até # sites WordPress.}}',
            unlimited: 'Sua licença permite gerenciar um número ilimitado de sites WordPress. {sites, plural, one {O preço básico da licença inclui o gerenciamento de # site.} other {O preço básico da licença inclui o gerenciamento de # sites.}} Todos os sites acima desse limite são faturáveis no formato de Pós-pago.',
            contactResellerToUpdate: 'To upgrade or cancel your license contact your service provider.',
            contactResellerToPurchase: 'To purchase a license contact your service provider.',
        },
        RetailLicenseInfo: {
            seeDetails: 'Veja os detalhes',
            nextBillingDate: 'Próxima data de cobrança: {date}',
            subscriptionCancelled: 'Subscription is canceled.',
        },
        CurrentUsage: {
            youAreUsingNow: 'Uso atual:',
            sitesTotal: '{sites, plural, one {total de # website} other {total de # websites}}',
            allowedToManage: '{sites, plural, one {permitido gerenciar # website} other {permitido gerenciar # websites}}',
            outOfLimit: '{sites, plural, one {# site acima do limite da licença} other {# sites acima do limite da licença}}',
            notOperable: '{sites, plural, one {# website inoperable} other {# websites inoperable}}',
            notOperableTooltip: '{sites, plural, one {Inoperable website does not count towards the license limit} other {Inoperable websites do not count towards the license limits}}',
        },
        LicenseInfo: {
            unlimited: {
                title: 'Unlimited',
                label: 'Para mais de 1000 websites',
                feature1: '10 mil sites incluídos',
                feature2: 'Pague conforme o uso, acima de 10 mil websites',
                feature3: 'Todos os recursos incluídos',
                pricePerSite: '{price, number, ::currency/USD} for each site above 10k',
            },
            standard: {
                title: 'Standard',
                label: 'Para até 1.000 websites',
                feature1: 'Pré-pago',
                feature2: 'Todos os recursos incluídos',
            },
            gotMoreSites: 'Got more sites to manage?',
            recommendedToYou: 'Recommended to you',
            useThisPlan: 'Usar esse plano',
            changePlan: 'Trocar plano',
            chooseAnotherOption: 'Escolha outra opção para fazer o upgrade do seu plano',
        },
        LicenseList: {
            columns: {
                license: 'Licença',
                sitesIncluded: 'Sites included',
                pricePerSite: 'Price per site',
                pricePerMonth: 'Per month',
            },
            sites: '{sites, plural, one {# website} other {# websites}}',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            licenseOffer: 'Need more than 50 sites?',
            pricingLink: '<partnerLink>Become our partner</partnerLink> or <faqLink>check below</faqLink> if you are already our pratner',
            buyButton: 'Comprar',
            recommendedToYou: 'Recommended to you',
            activeLicense: 'Active license',
            canceled: 'Canceled',
            reactivate: 'Reativar',
            details: 'Details',
        },
        chooseLicenseNow: 'Escolha sua licença',
        licensesTitle: 'Selecione a licença que atende às suas necessidades',
        faq: {
            title: 'Perguntas frequentes',
            question1: {
                question: 'O que acontece depois que o meu período de teste acabar?',
                answer: "If the trial period is over and you haven't bought a license yet, all WordPress sites connected to WP Guardian will continue to work as usual, but you will not be able to configure autoupdates, apply safe updates, enable vulnerability protection, and use other WP Guardian functionality that helps the sites stay secure.",
            },
            question2: {
                question: 'How do I get a license for WP Guardian as a WebPros partner?',
                answer: 'If you are a partner that uses Plesk, you can create a license key in <kaLink>Key Administrator</kaLink> directly, or ask your sales representative to create a key for you.{br} If you are a partner that only uses cPanel, you can buy a license key in the <manage2Link>Manage2</manage2Link> system, or ask your sales representative to create a key for you.{br} If you are not our partner yet, you can <partnerLink>become one</partnerLink> by contacting our sales team to get access to WP Guardian and a range of partner discounts.',
            },
            question3: {
                question: 'O que acontece se os limites da minha licença forem atingidos?',
                answer: 'Websites adicionados ao WP Guardian após atingir o limite da licença ser atingido serão marcados como não gerenciados. Tais websites continuarão a ter informações atualizadas sobre vulnerabilidades e só podem ser atualizados manualmente. Todas as outras funcionalidades do WP Guardian não estarão disponíveis para estes websites.',
            },
            question4: {
                question: 'Como a opção de Pós-pago funciona?',
                answer: "Pay As You Go means your payment will be calculated at the end of the billing period (1 month) based on how many sites are managed by WP Guardian this billing period. Sites with 'inoperable' status will not be counted as managed. After the end of the billing period, Key Administrator or Manage2 will send you the bill for that period together with usage report.",
            },
            question5: {
                question: 'What is Vulnerability Protection?',
                answer: "Vulnerability Protection (<whiteSpaceNoWrap>powered by {providerLogo}</whiteSpaceNoWrap>) installs a WordPress plugin that uses Web Application Firewall rules to mitigate vulnerabilities without changing the vulnerable code itself. The plugin applies only relevant firewall rules which target specific vulnerabilities found on a site, and does not modify WordPress files or site content.",
            },
        },
        licenseDialog: {
            getLicenseInKa: 'Você pode obter sua licença em <kaLink>Key Administrator</kaLink>, <manage2Link>Manage2</manage2Link> ou com seu representante de vendas.',
            standard: {
                title: '{sites, plural, one {Você deseja escolher uma licença padrão para # site} other {Você deseja escolher uma licença padrão para # sites}}',
                licenseLimit: '{sites, plural, one {A licença inclui apenas # site} other {A licença inclui apenas # sites}}',
                feature1: 'A licença padrão inclui um número específico de websites sem as capacidades de Pagamento Conforme o Uso.',
                feature2: 'Todos os websites adicionados após atingir o limite da licença serão marcados como Não gerenciados.',
                feature3: 'Websites não gerenciados não podem ser gerenciados usando o WP Guardian sem atualizar sua licença.',
                feature4: 'Websites não gerenciados serão mudados para gerenciados caso o limite de licença for aumentado ou um espaço livre na licença atual ficar disponível.',
                feature5: 'Se um website foi conectado ao WP Guardian por mais do que alguns dias, ele será contado no uso da sua licença.',
                feature6: 'Websites quebrados ou de outra forma indisponíveis não são contados no limite da sua licença.',
            },
            unlimited: {
                title: 'Você escolheu a licença ilimitada',
                feature1: 'A licença ilimitada inclui 10.000 websites em seu preço base.',
                feature2: 'Todos os websites adicionados após os primeiros 10.000 serão cobrados no método de Pós-pago.',
                feature3: 'Se um website foi conectado ao WP Guardian por mais do que alguns dias, ele será contado no uso da sua licença.',
                feature4: 'Websites quebrados ou de outra forma indisponíveis não são contados no limite da sua licença.',
            },
        },
        UpgradeButton: {
            upgrade: 'Upgrade',
        },
        LinkEmailPopover: {
            linkEmailDescription: 'Could not fetch your license information. This is often caused by a mismatch between the email used for purchases and your WP Guardian account email.',
            linkEmail: 'Please link the email used for purchases to your account.',
        },
        RecommendedLicense: {
            recommendedToYou: 'Recommended to you',
            allFeaturesIncluded: 'Todos os recursos incluídos',
            monthlySubscription: 'Monthly subscription',
            pricePerSite: '{price, number, :: currency/EUR} per site',
            pricePerMonth: '{price, number, ::. currency/EUR}',
            buyLicense: 'Compre uma Licença',
            upgradeLicense: 'Faça o Upgrade da Licença',
            needMoreSites: 'Got more sites to manage?',
            upgradeTo: 'Upgrade to {sites} sites',
        },
        goBack: 'Voltar para a Tela Inicial',
    },
    LabelsDrawer: {
        title: 'Gerenciar tags',
        LabelsList: {
            create: 'Criar tag',
            edit: 'Editar',
            delete: 'Apagar',
            noSelectedLabels: 'Selecione ao menos uma tag para apagar.',
            columns: {
                title: 'Tag',
            },
            search: {
                title: 'Encontrar',
                placeholder: 'Encontrar rótulo',
            },
            emptyList: {
                title: 'Criar um rótulo',
                description: "Você não tem nenhum rótulo para diferenciar seus servidores e sites. Deseja destacar alguns deles?",
            },
            filteredEmptyList: {
                title: 'Não foi possível encontrar nenhum item que corresponda ao filtro',
                description: 'Mude o texto do rótulo que está buscando e tente novamente',
            },
        },
        DeleteLabelsPopover: {
            delete: 'Apagar',
            confirmation: '{count, select, 1 {Remover um rótulo irá removê-lo de todos os objetos aos quais ele foi aplicado.} other {Remover rótulos irá removê-los de todos os objetos aos quais eles foram aplicados.}} Continuar?',
        },
        CreateLabelDrawer: {
            title: 'Criar tag',
        },
        EditLabelDrawer: {
            title: 'Editar rótulo',
            notFound: 'Rótulo não encontrado',
        },
        LabelForm: {
            save: 'Salvar',
            cancel: 'Cancelar',
            fields: {
                title: {
                    label: 'Nome da etiqueta',
                    description: 'Máximo de 30 símbolos/caracteres',
                },
            },
        },
    },
    LabelsPopover: {
        title: 'ROTULAR COMO',
        noSelection: 'Selecione ao menos um item na lista para adicionar ou remover rótulos.',
        buttons: {
            apply: 'Aplicar',
            create: 'Criar novo',
            manage: 'Gerenciar tags',
        },
    },
    UpdateSettingsCard: {
        Label: {
            labelPlaceholder: '{components}:',
            allComponents: 'todos',
            tooltip: 'As atualizações seguras estão habilitadas',
            components: {
                core: 'core',
                themes: 'temas',
                plugins: 'plugins',
            },
            useUpdateSettingsOptionTranslate: {
                minor: 'atualizações de segurança',
                major: 'todas as atualizações',
                disabled: "não instalar",
                notSet: 'nível do site',
                doNotTouch: "don't touch",
            },
        },
    },
    UnprocessableInstallationsDialog: {
        notManagedSitesLicenseMessage: '{count, plural, one {Sua licença inclui # website gerenciado} other {Sua licença inclui # websites gerenciados}}',
        operableNotManagedSitesTitle: '{unmanagedCount, plural, one {# website não gerenciado será ignorado} other {# sites não gerenciados serão ignorados}}',
        operableNotManagedSitesMessage: 'Websites não gerenciados são ignorados pelo WP Guardian porque excedem os limites da sua licença. Esses websites são ignorados enquanto ações em massa são realizadas. ',
        notOperableNotManagedSitesTitle: '{notOperableCount, plural, one {# website inoperável} other {# websites inoperávelis}} and {unmanagedCount, plural, one {# website não gerenciado} other {# websites não gerenciados}} serão ignorados',
        notOperableNotManagedSitesMessage: 'A operação que você selecionou não será realizada para tais websites porque eles estão inoperáveis ou excedem os limites da sua licença. Tais websites são ignorados enquanto ações em massa são realizadas.',
        managedSitesWillBePerformed: 'Para todos os websites gerenciados, a operação selecionada será realizada sem restrições.',
        chooseLicenseButton: 'Escolha Uma Licença',
        LicenseTerminatedDialog: {
            title: 'Your license is terminated',
            message: 'Obtenha uma licença caso queiria continuar usando a funcionalidade avançada do WP Guardian.',
            chooseLicenseButton: 'Escolha Uma licença',
            contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
        },
        contactResellerToUpgrade: 'To upgrade your license contact your service provider.',
    },
    LabelsList: {
        UpdatesLabel: {
            notSet: 'Atualizações definidas pelo website',
            autoupdate: 'Atualização automática',
            manual: 'Atualizações manuais',
            custom: 'Atualizações personalizadas',
        },
        unmanaged: {
            title: 'Não gerenciado',
            tooltip: 'Websites não gerenciados são ignorados pelo WP Guardian porque excedem os limites da sua licença. Os websites marcados como não gerenciados devido a limites de licença continuarão a ter informações atualizadas sobre suas vulnerabilidades e só poderão ser atualizados manualmente. Esses websites serão alterados para gerenciados se um limite de licença for aumentado ou se um espaço livre na licença atual for disponibilizado.',
        },
        menu: {
            remove: 'Remover',
            filter: 'Filtrar por este marcador',
        },
    },
    Cuttable: {
        showMore: 'Mostrar mais',
        showLess: 'Mostrar menos',
    },
};