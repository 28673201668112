// Copyright 2024. WebPros International GmbH. All rights reserved.

import { Icon } from '@plesk/ui-library';
import { useSecurityDashboardAnalyticsEvents } from '@platform360/security-dashboard/web/helpers/analytics';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { ReactElement } from 'react';
import styles from './AddServer.module.less';

export type AddServerDrawerProps = {
    installationSnippet: ReactElement;
};

const AddServer = ({ installationSnippet }: AddServerDrawerProps) => {
    const analyticsEvents = useSecurityDashboardAnalyticsEvents();
    const translate = useTranslate('security-dashboard.Servers.AddServer');

    return (
        <>
            <ol>
                <li>
                    <p>{translate('paragraph1')}</p>
                </li>
                <li>
                    <p>{translate('paragraph2')}</p>
                </li>
            </ol>
            {installationSnippet}
            <a
                href="https://docs.wpguardian.io/#connecting-servers-to-wp-guardian"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => analyticsEvents.wpAddServerDrawerDocumentationClick()}
                className={styles.documentationLink}
            >
                <Icon
                    name="question-mark-circle"
                    size="16"
                    intent="inactive"
                    className={styles.documentationIcon}
                />
                {translate('documentationLink')}
            </a>
        </>
    );
};

export default AddServer;
